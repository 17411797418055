import { loadTasksAsync } from '@features/Tasks/actions';
import { tasksLoadFailedSelector, tasksSelector } from '@features/Tasks/selectors';
import { TaskDtoStatusEnum } from '@root/services/api';
import { useEffect } from 'react';
import { List } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, ItemText, MenuItem } from './Common';
import SkeletonMenuItem from './SkeletonMenuItem';

interface Props {
    onItemSelected: () => void;
}

function TasksMenuItem({ onItemSelected }: Props) {
    const dispatch = useDispatch();
    const tasks = useSelector(tasksSelector);
    const loadFailed = useSelector(tasksLoadFailedSelector);
    const todoCount = tasks?.filter(t => !t.isEvent && t.status !== TaskDtoStatusEnum.Complete)?.length;

    useEffect(() => {
        dispatch(loadTasksAsync.request());
    }, [dispatch]);

    return (
        <>
            {(tasks || loadFailed) && (
                <MenuItem to="/tasks" onClick={() => onItemSelected()}>
                    <>
                        <List />
                        <ItemText>Tasks</ItemText>
                        {!!todoCount && <Badge>{todoCount}</Badge>}
                    </>
                </MenuItem>
            )}
            {!tasks && !loadFailed && <SkeletonMenuItem width="6.0rem" />}
        </>
    );
}

export default TasksMenuItem;
