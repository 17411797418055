import { createElement } from 'react';
import { Icon } from 'react-feather';
import tw, { styled } from 'twin.macro';
import ButtonStyleBase from '@components/button';

const Button = styled.button(() => [
    ...ButtonStyleBase,
    tw`w-full flex-row items-center justify-between space-x-4 p-4`,
]);
const TextCol = tw.div`flex flex-col space-y-2 justify-center`;
const IconCol = tw.div`flex flex-col justify-center items-center text-brand-primary space-y-1 w-16`;
const IconSubtext = tw.span`text-brand-black text-xs text-center`;
const ButtonText = tw.p`text-brand-black text-xs leading-relaxed`;

interface Props {
    disabled?: boolean | undefined;
    text: string;
    icon: Icon;
    iconText: string;
    onPress: () => void;
}

function VerificationOptionButton({ disabled, text, icon, iconText, onPress }: Props) {
    return (
        <Button disabled={disabled} onClick={() => onPress()}>
            <TextCol>
                <ButtonText>{text}</ButtonText>
            </TextCol>
            <IconCol>
                {createElement(icon)}
                <IconSubtext>{iconText}</IconSubtext>
            </IconCol>
        </Button>
    );
}

VerificationOptionButton.defaultProps = {
    disabled: undefined,
};

export default VerificationOptionButton;
