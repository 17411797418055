import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadAppSchematicAsync } from './actions';

export const step = createReducer<Api.AppSchematicDtoStepEnum | null>(null)
    .handleAction(loadAppSchematicAsync.success, (_state, action) => action.payload.step)
    .handleAction(resetContext, () => null);

export const widgets = createReducer<Api.AppSchematicDtoWidgetsEnum[]>([])
    .handleAction(loadAppSchematicAsync.success, (_state, action) => action.payload.widgets)
    .handleAction(resetContext, () => []);

export const schematicLoadFailed = createReducer(false)
    .handleAction(loadAppSchematicAsync.request, () => false)
    .handleAction(loadAppSchematicAsync.failure, () => true);

interface Summary {
    taskCount: number;
    documentCount: number;
    installedProductsCount: number;
}

const initialSummary = {
    taskCount: 0,
    documentCount: 0,
    installedProductsCount: 0,
};

export const summary = createReducer<Summary>(initialSummary)
    .handleAction(loadAppSchematicAsync.success, (state, action) => {
        const { taskCount, documentCount, installedProductsCount } = action.payload;

        return {
            ...state,
            taskCount,
            documentCount,
            installedProductsCount,
        };
    })
    .handleAction(resetContext, () => initialSummary);

const portalReducer = combineReducers({
    step,
    widgets,
    summary,
    schematicLoadFailed,
});

export default portalReducer;
export type PortalState = ReturnType<typeof portalReducer>;
