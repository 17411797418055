import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import tw, { GlobalStyles, styled } from 'twin.macro';

import StylesBase from '@components/stylesBase';
import Portal from '@features/Portal/components/Portal';
import { ReactComponent as Logo } from '@images/logo.svg';
import { accessTokenService } from '@services/index';
import store from '@store/index';
import AppTheme from './AppTheme';

const AppWrapper = tw.div`h-full w-full max-w-full font-sans select-none`;
const AppSplash = styled.div(() => [
    tw`h-full w-full bg-cover`,
    tw`flex flex-row items-center justify-center`,
    `background-image: url(${AppTheme.abstractBG});`,
]);

const AppLogo = tw(Logo)`text-brand-white w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6`;

function App() {
    const [stateLoaded, setStateLoaded] = useState(false);

    // Load the auth state from the storage provider asynchronously (prioritizing sessionState) if needed
    useEffect(() => {
        const retrieveToken = async () => {
            await accessTokenService.restore();
            setStateLoaded(true);
        };

        retrieveToken();
    }, []);

    return (
        <Provider store={store}>
            <AppWrapper>
                <GlobalStyles />
                <StylesBase />
                {stateLoaded && <Portal />}
                {!stateLoaded && (
                    <AppSplash>
                        <AppLogo />
                    </AppSplash>
                )}
            </AppWrapper>
        </Provider>
    );
}

export default App;
