import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { Helmet } from 'react-helmet';
import getTokenData from '@util/jwt';
import ButtonStyleBase from '@components/button';
import { accessTokenService } from '@services/index';
import LoginFooter from './LoginFooter';
import Logo from './Logo';
import { selectContext } from '../actions';

const Wrapper = tw.div`flex-grow flex items-center justify-center select-none overflow-y-auto`;
const FormContainer = tw.div`bg-brand-white p-8 flex flex-col items-center justify-center`;
const SelectionContainer = tw.div`w-full p-4 mt-4 rounded-md`;
const ProjectsContainer = tw.div`max-h-96 overflow-y-auto flex flex-col items-stretch`;
const ContextButton = styled.button(() => [...ButtonStyleBase, tw`mx-2`]);
const Title = tw.h1`text-brand-dark text-lg font-bold`;
const NoContexts = tw.p`max-w-sm my-4`;
const SignOutButton = styled(Link)(() => [...ButtonStyleBase]);

interface ContextOption {
    parentId: string;
    contextId: string;
    name: string;
    order: number;
}

function SelectContext() {
    const [contextOptions, setContextOptions] = useState<ContextOption[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadGroups = async () => {
            const token = await accessTokenService.getToken();
            const tokenData = getTokenData(token);

            let options: ContextOption[] = [];
            options = options.concat(
                ...tokenData.scopes.map(g => {
                    return g.contexts.map(c => {
                        return { parentId: g.id, contextId: c.id, name: c.name, order: c.o || 4 };
                    });
                }),
            );
            options.sort((a, b) => {
                if (a.order === b.order) {
                    return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
                }

                return a.order - b.order;
            });

            setContextOptions(options);
        };

        loadGroups();

        if (window.location.pathname !== '/') {
            window.history.pushState({}, '', '/');
        }
    }, []);

    const setContext = (parentId: string, contextId: string) => {
        dispatch(selectContext.request({ parentId, contextId }));
    };

    const showSelection = contextOptions.length > 0;

    return (
        <Wrapper>
            <Helmet>
                <title>Select Project | Compass by Swell Energy</title>
            </Helmet>
            <FormContainer>
                <Logo />
                {showSelection && (
                    <SelectionContainer>
                        <Title>Pick a Project</Title>
                        <ProjectsContainer>
                            {contextOptions.map(o => (
                                <ContextButton
                                    key={`${o.contextId}`}
                                    onClick={() => setContext(o.parentId, o.contextId)}
                                >
                                    {o.name}
                                </ContextButton>
                            ))}
                        </ProjectsContainer>
                    </SelectionContainer>
                )}
                {!showSelection && (
                    <>
                        <NoContexts>
                            We couldn&apos;t find any active projects associated with your account. Please contact
                            support if this is not expected.
                        </NoContexts>
                        <SignOutButton to="/log-out">Sign Out</SignOutButton>
                    </>
                )}
                <LoginFooter />
            </FormContainer>
        </Wrapper>
    );
}

export default SelectContext;
