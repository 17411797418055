import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const sentryEnvironment = process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production';

Sentry.init({
    dsn: 'https://a4fb3a315a3d418fa08d3ac2b05f80f6@o458648.ingest.sentry.io/5821757',
    integrations: [new Integrations.BrowserTracing()],
    environment: sentryEnvironment,
    tracesSampleRate: sentryEnvironment === 'production' ? 0.2 : 1.0,
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
