import LinkCard from '@components/LinkCard';

function ContactCard() {
    return (
        <LinkCard size="md" to="/contact" title="Contact Us" navigateText="Go to contact details">
            Have a question or just need to get in touch with someone?
        </LinkCard>
    );
}

export default ContactCard;
