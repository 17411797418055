import tw from 'twin.macro';

import { Helmet } from 'react-helmet';
import LoginFooter from './LoginFooter';
import SendResetForm from './SendResetForm';
import CompassLogo from './Logo';

const Wrapper = tw.div`flex-grow flex items-center justify-center select-none`;
const Container = tw.div`bg-brand-white max-w-[348px] p-8 flex flex-col items-center justify-center`;

function ForgotPassword() {
    return (
        <Wrapper>
            <Helmet>
                <title>Forgot Password | Compass by Swell Energy</title>
            </Helmet>
            <Container>
                <CompassLogo />
                <SendResetForm />
                <LoginFooter />
            </Container>
        </Wrapper>
    );
}

export default ForgotPassword;
