import React from 'react';
import tw, { styled } from 'twin.macro';
import ButtonStyleBase from './button';

interface ContainerProps {
    pad: boolean;
}

const Container = styled.div((props: ContainerProps) => [
    tw`flex flex-col items-center justify-center absolute bg-brand-white z-50 text-center top-0 left-0`,
    props.pad ? 'width: calc(100% - 32px);' : tw`w-full`,
    props.pad ? 'height: calc(100% - 32px);' : tw`h-full`,
]);

const RetryButton = styled.button(() => [...ButtonStyleBase, tw`mt-4`]);

interface Props {
    onRetry?: () => void;
    isInPaddedContainer?: boolean;
}

function LoadFailedOverlay({ onRetry, isInPaddedContainer }: Props) {
    const handleRetry = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onRetry) {
            evt.preventDefault();
            onRetry();
        }
    };

    return (
        <Container pad={!!isInPaddedContainer}>
            We had a problem retrieving this information.
            {onRetry && (
                <RetryButton type="button" onClick={handleRetry}>
                    Retry
                </RetryButton>
            )}
        </Container>
    );
}

LoadFailedOverlay.defaultProps = {
    onRetry: undefined,
    isInPaddedContainer: undefined,
};

export default LoadFailedOverlay;
