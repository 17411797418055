import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadAsync } from './actions';

const contacts = createReducer<Api.ContactDto[] | null>(null)
    .handleAction(loadAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => null);

const contactsLoadFailed = createReducer(false)
    .handleAction(loadAsync.request, () => false)
    .handleAction(loadAsync.failure, () => true);

const contactsReducer = combineReducers({
    contacts,
    contactsLoadFailed,
});

export default contactsReducer;
export type ContactsState = ReturnType<typeof contactsReducer>;
