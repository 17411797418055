import FormikInputBox from '@components/FormikInputBox';
import Api from '@services/index';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import * as Yup from 'yup';
import { Button as BaseButton, ErrorMessage, InstructionMessage, SuccessMessage } from './StyledComponents';

const FormContainer = tw.form`flex flex-col items-center justify-center`;
const ReturnToLoginLink = tw(Link)`mx-auto hover:(underline text-brand-primary)`;
const LoginLink = tw.div`flex px-2 my-4 text-brand-muted`;
const Button = tw(BaseButton)`w-[18rem] mt-[24px]`;

const validationSchema = Yup.object({
    emailAddress: Yup.string().required().email(),
});

const initialValues = {
    emailAddress: '',
};

type FormData = typeof initialValues;

const DefaultErrorMessage = 'There was an error submitting your request. Please try again later.';

interface Props {
    isExpiredLink?: boolean;
}

function SendResetForm({ isExpiredLink }: Props) {
    const [status, setStatus] = useState<'none' | 'failed' | 'success'>('none');
    const [errorMessage, setErrorMessage] = useState('');
    const formik = useFormik({
        validateOnMount: true,
        initialValues,
        validationSchema,
        onSubmit: async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
            formikHelpers.setSubmitting(true);

            setErrorMessage('');
            setStatus('none');

            try {
                const result = await Api.authApi.authControllerForgotPassword({ emailAddress: values.emailAddress });
                const success = result.status >= 200 && result.status < 300;

                if (!success) {
                    setErrorMessage(DefaultErrorMessage);
                    setStatus('failed');
                } else {
                    setStatus('success');
                }
            } catch (err) {
                setErrorMessage(DefaultErrorMessage);
                setStatus('failed');
            }

            formikHelpers.setSubmitting(false);
        },
    });

    const buttonDisabled = !formik.values.emailAddress || formik.isSubmitting || !formik.isValid;

    return (
        <FormContainer onSubmit={formik.handleSubmit}>
            {status === 'failed' && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {status === 'success' && (
                <>
                    <SuccessMessage>
                        We&apos;ve sent you an email with instructions for setting your password.
                    </SuccessMessage>
                    <LoginLink>
                        <ReturnToLoginLink to="/">Return to Login</ReturnToLoginLink>
                    </LoginLink>
                </>
            )}

            {status !== 'success' && (
                <>
                    {isExpiredLink && <InstructionMessage>Sorry, this link has expired.</InstructionMessage>}
                    <InstructionMessage>
                        Please enter the email address associated with your account. An email will be sent with further
                        information to help you regain access.
                    </InstructionMessage>

                    <FormikInputBox
                        name="emailAddress"
                        type="email"
                        placeholder="Email address"
                        hasError={!!formik.touched.emailAddress && !!formik.errors.emailAddress}
                        formik={formik}
                    />
                    <Button type="submit" disabled={buttonDisabled}>
                        Submit
                    </Button>
                    <LoginLink>
                        <ReturnToLoginLink to="/">Return to Login</ReturnToLoginLink>
                    </LoginLink>
                </>
            )}
        </FormContainer>
    );
}

SendResetForm.defaultProps = {
    isExpiredLink: undefined,
};

export default SendResetForm;
