/* eslint-disable import/prefer-default-export */
import { compose } from 'redux';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

export const composeEnhancers =
    (process.env.NODE_ENV === 'development' && window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
