import { ReactNode, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import { AppSchematicDtoStepEnum, AppSchematicDtoWidgetsEnum } from '@services/api';
import { stepSelector, widgetsSelector } from '@features/Portal/selectors';
import { esaStatusLoadFailedSelector, esaStatusSelector, financialsSelector } from '@features/Financials/selectors';
import { loadESAStatusAsync, loadFinancialsAsync } from '@features/Financials/actions';
import ESAStatusCard from '@features/Financials/components/ESAStatusCard';
import UtilityCard from '@features/Utility/components/UtilityCard';
import AppTheme from '@root/AppTheme';
import ContactCard from './ContactCard';
import DemographicsCard from './DemographicsCard';
import ESAOnboardingCard from './ESAOnboardingCard';
import ESARotatorCard from './ESARotatorCard';
import ESASkeletonCard from './ESASkeletonCard';
import MyProjectCard from './MyProjectCard';
import Stages from './Stages';
import TimelineCard from './TimelineCard';
import { stagesSelector } from '../selectors';
import LGThinQCard from './LGThinQCard';

const Page = tw.div`flex flex-wrap`;

function Profile() {
    const dispatch = useDispatch();
    const onboardingStep = useSelector(stepSelector);
    const financingInfo = useSelector(financialsSelector);
    const esaStatus = useSelector(esaStatusSelector);
    const esaStatusLoadFailed = useSelector(esaStatusLoadFailedSelector);
    const stages = useSelector(stagesSelector);
    const widgets = useSelector(widgetsSelector);

    const isCompleted =
        onboardingStep === AppSchematicDtoStepEnum.Active || !!stages.find(s => s.stage === 'complete' && s.isActive);
    const isEnrolled =
        onboardingStep === AppSchematicDtoStepEnum.Active ||
        !!stages.find(s => s.stage === 'enrollment_complete' && s.isActive);

    // Enrolled
    const isESA = financingInfo?.type === 'lease' && financingInfo?.source.indexOf('ESA') > -1;
    const isESALoading = isESA && !esaStatus && !esaStatusLoadFailed;
    const isESAOnboarding = isESA && (esaStatusLoadFailed || !isCompleted);
    const isESACompleted = isESA && isCompleted;
    const showStages =
        onboardingStep === AppSchematicDtoStepEnum.Presales ||
        onboardingStep === AppSchematicDtoStepEnum.Install ||
        onboardingStep === AppSchematicDtoStepEnum.CapacityExchangeOnly;

    const showUtilityCTA = widgets.includes(AppSchematicDtoWidgetsEnum.EnergyExchange);

    useEffect(() => {
        if (onboardingStep && onboardingStep !== AppSchematicDtoStepEnum.CapacityExchangeOnly) {
            dispatch(loadFinancialsAsync.request({}));
            dispatch(loadESAStatusAsync.request());
        }
    }, [dispatch, onboardingStep]);

    let firstCard: ReactNode = <MyProjectCard />;
    let secondCard = <TimelineCard />;

    if (isEnrolled && AppTheme.showThinQCardOnComplete) {
        secondCard = <LGThinQCard />;
    }

    if (onboardingStep === AppSchematicDtoStepEnum.Install) {
        if (isESALoading) {
            firstCard = <ESASkeletonCard />;
            secondCard = <ESASkeletonCard />;
        } else if (isESAOnboarding) {
            firstCard = <ESAOnboardingCard />;
        } else if (isESACompleted && esaStatus) {
            firstCard = <ESAStatusCard cardTitle="My Account" />;
            secondCard = <ESARotatorCard />;
        }

        if (isCompleted && AppTheme.showThinQCardOnComplete) {
            secondCard = <LGThinQCard />;
        }
    }

    // IGE-453 Hide "My Project" for Opportunities that are ESA/Lease
    // IGE-573 Hide for Capacity Exchange Only
    if (
        (isESA && onboardingStep === AppSchematicDtoStepEnum.Presales) ||
        onboardingStep === AppSchematicDtoStepEnum.CapacityExchangeOnly
    ) {
        firstCard = null;
    }

    return (
        <Page>
            <Helmet>
                <title>Project Dashboard | Compass by Swell Energy</title>
            </Helmet>
            {showStages && <Stages />}
            <DemographicsCard />
            {firstCard}
            {secondCard}
            <UtilityCard showCTA={showUtilityCTA} />
            <ContactCard />
        </Page>
    );
}

export default Profile;
