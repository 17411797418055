import { useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DownloadCloud } from 'react-feather';
import { Helmet } from 'react-helmet';
import ButtonStyleBase from '@components/button';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import DocumentViewerSkeleton from './DocumentViewerSkeleton';
import { documentLinkFailureSelector, documentLinkSelector } from '../selectors';
import { fetchUrlAsync } from '../actions';

const Wrapper = tw.div`mt-2 flex flex-col max-w-full relative`;
const ViewerFrame = styled.iframe(() => [tw`w-full bg-brand-white`, 'height: calc(100vh - 12rem);']);
const DocumentActions = tw.div`m-4 flex flex-row justify-around`;
const DownloadButton = styled.a(() => [...ButtonStyleBase]);
const ButtonText = tw.span`ml-2`;

interface MatchParams {
    documentId: string;
}

type Props = RouteComponentProps<MatchParams>;

function DocumentView({ match }: Props) {
    const dispatch = useDispatch();
    const { documentId } = match.params;
    const documentLink = useSelector(documentLinkSelector(documentId));
    const documentLinkFailed = useSelector(documentLinkFailureSelector(documentId));

    useEffect(() => {
        dispatch(fetchUrlAsync.request({ documentId }));
    }, [dispatch, documentId]);

    return (
        <Wrapper>
            <Helmet>
                <title>View Document | Compass by Swell Energy</title>
            </Helmet>
            {!documentLink && (
                <>
                    <DocumentViewerSkeleton />
                    {documentLinkFailed && (
                        <LoadFailedOverlay onRetry={() => dispatch(fetchUrlAsync.request({ documentId }))} />
                    )}
                </>
            )}
            {documentLink && (
                <>
                    <ViewerFrame src={documentLink.previewUrl} />
                    <DocumentActions>
                        <DownloadButton href={documentLink.downloadUrl} download={documentLink.fileName}>
                            <DownloadCloud />
                            <ButtonText>Download</ButtonText>
                        </DownloadButton>
                    </DocumentActions>
                </>
            )}
        </Wrapper>
    );
}

export default DocumentView;
