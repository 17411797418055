import SkeletonLoader from 'tiny-skeleton-loader-react';
import LinkCard from '@components/LinkCard';

function ESASkeletonCard() {
    return (
        <LinkCard size="md">
            <SkeletonLoader width="100%" height="8rem" />
        </LinkCard>
    );
}

export default ESASkeletonCard;
