import { PropsWithChildren } from 'react';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import { ArrowRightCircle } from 'react-feather';
import Card from '@components/Card';

const LinkButton = tw(Link)`flex flex-col h-full text-brand-primary hover:text-brand-primary`;
const ContentContainer = tw.div`flex flex-col h-full`;
const Title = tw.span`text-lg text-brand-black`;
const Content = tw.div`flex-grow mt-2 text-brand-black`;
const NavigateCTA = tw.div`flex justify-between mt-4`;
const ArrowRightCircleIcon = tw(ArrowRightCircle)`self-end`;

interface Props {
    size: 'md' | 'lg' | 'full';
    title: string;
    to?: string;
    navigateText?: string;
}

function BaseCard({ size, title, to, navigateText, children }: PropsWithChildren<Props>) {
    const cardContent = (
        <>
            <Title>{title}</Title>
            <Content>{children}</Content>
        </>
    );

    if (to && navigateText) {
        return (
            <Card size={size}>
                <LinkButton to={to}>
                    {cardContent}
                    <NavigateCTA>
                        <span>{navigateText}</span>
                        <ArrowRightCircleIcon />
                    </NavigateCTA>
                </LinkButton>
            </Card>
        );
    }
    return (
        <Card size={size}>
            <ContentContainer>{cardContent}</ContentContainer>
        </Card>
    );
}

BaseCard.defaultProps = {
    to: undefined,
    navigateText: undefined,
};

export default BaseCard;
