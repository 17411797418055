import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { demographicsSelector } from '../selectors';
import { toTitleCase } from '../../../util/strings';

const SkeletonRow = tw.div`flex flex-row`;
const SkeletonSpacer = tw.div`mx-1`;
const Container = tw.div`mb-2`;
const NameText = tw.span`text-xl`;

function Name() {
    const demographics = useSelector(demographicsSelector);

    return (
        <Container>
            {!demographics && (
                <SkeletonRow>
                    <SkeletonLoader width="5.0rem" height="1.75rem" />
                    <SkeletonSpacer />
                    <SkeletonLoader width="8.0rem" height="1.75rem" />
                </SkeletonRow>
            )}
            {demographics && (
                <NameText>
                    {toTitleCase(demographics.firstName || '')} {toTitleCase(demographics.lastName || '')}
                </NameText>
            )}
        </Container>
    );
}

export default Name;
