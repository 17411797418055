/* eslint-disable no-await-in-loop */
import { jsPDF } from 'jspdf';

const getImage = (source: File): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            const [maxWidth, maxHeight] = [1200, 2000];

            const canvas = document.createElement('canvas');
            const widthRatio = maxWidth / image.width;
            const heightRatio = maxHeight / image.height;
            const ratio = Math.min(widthRatio, heightRatio, Math.max(1, widthRatio, heightRatio));

            canvas.width = image.width * ratio;
            canvas.height = image.height * ratio;

            const context = canvas.getContext('2d');
            context?.drawImage(image, 0, 0, canvas.width, canvas.height);

            resolve(canvas);
        };
        image.onerror = () => {
            reject(new Error('Failed to convert File to Image'));
        };

        image.src = URL.createObjectURL(source);
    });
};

const mergeIntoPDF = async (files: File[]) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF({ unit: 'px' });
    doc.deletePage(1);

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
        const canvas = await getImage(file);

        doc.addPage('px');
        const widthRatio = doc.internal.pageSize.getWidth() / canvas.width;
        const heightRatio = doc.internal.pageSize.getHeight() / canvas.height;
        const ratio = Math.min(widthRatio, heightRatio, 1);
        const width = canvas.width * ratio;
        const height = canvas.height * ratio;

        const left = (doc.internal.pageSize.getWidth() - width) / 2;
        const top = (doc.internal.pageSize.getHeight() - height) / 2;

        const blob = await new Promise<Blob>(resolve => {
            canvas.toBlob(b => {
                resolve(b!);
            });
        });
        const imageData = new Uint8Array(await blob.arrayBuffer());
        doc.addImage(imageData, 'JPEG', left, top, width, height);
    }

    return doc.output('blob');
};

export default mergeIntoPDF;
