import { useEffect } from 'react';
import tw from 'twin.macro';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import ESARecentPaymentsCard from './ESARecentPaymentsCard';
import ESAStatusCard from './ESAStatusCard';
import ESABillingSupportCard from './ESABillingSupportCard';
import { loadESAStatusAsync } from '../actions';

const Page = tw.div`flex flex-wrap`;

function FinanceAgreementPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadESAStatusAsync.request());
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>My Account | Compass by Swell Energy</title>
            </Helmet>

            <ESAStatusCard cardTitle="Next Payment" />
            <ESARecentPaymentsCard />
            <ESABillingSupportCard />
        </Page>
    );
}

export default FinanceAgreementPage;
