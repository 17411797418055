import tw, { styled } from 'twin.macro';
import PasswordComplexityRules from '../util/password-complexity';
import HelperLabel from './HelperLabel';

type SegmentVariant = 'red' | 'yellow' | 'green' | 'none';

const Control = tw.div`flex flex-col space-y-1`;
const Container = tw.div`border border-brand-muted rounded overflow-hidden h-2 flex flex-row mx-1 my-2`;

interface SegmentProps {
    width: number;
    variant: SegmentVariant;
}

const Segment = styled.div(({ width, variant }: SegmentProps) => [
    `flex-grow: ${width};`,
    tw`transition-all`,
    variant === 'red' ? tw`bg-input-error` : '',
    variant === 'yellow' ? tw`bg-brand-warning` : '',
    variant === 'green' ? tw`bg-brand-success` : '',
    variant === 'none' ? tw`bg-brand-white` : '',
]);

interface Props {
    currentValue: string;
}

function PasswordStrengthIndicator({ currentValue }: Props) {
    const complexityResults = PasswordComplexityRules.map(rule => ({
        id: rule.id,
        result: rule.test(currentValue),
        help: rule.help,
        weight: rule.weight,
    }));

    const totalScorePossible = complexityResults.reduce((prev, curr) => prev + curr.weight, 0);
    const score = complexityResults.reduce((prev, curr) => prev + curr.result, 0);
    const progress = score / totalScorePossible;
    const getSegmentColor = (): SegmentVariant => {
        if (progress < 0.75) return 'red';
        if (progress === 1) return 'green';
        return 'yellow';
    };

    return (
        <Control>
            <Container>
                <Segment width={progress} variant={getSegmentColor()} />
                <Segment width={1 - progress} variant="none" />
            </Container>
            {progress < 1 &&
                complexityResults.map(result => (
                    <HelperLabel key={`rule-${result.id}`} success={result.result === result.weight}>
                        {result.help}
                    </HelperLabel>
                ))}
        </Control>
    );
}

export default PasswordStrengthIndicator;
