import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { loadESAStatusAsync, loadFinancialsAsync, unlinkAccountAsync } from './actions';

export const loadFinancialsEpic: Epic = (action$, state$, { financeApi }) =>
    action$.pipe(
        filter(isActionOf(loadFinancialsAsync.request)),
        debounceTime(500),
        switchMap(() =>
            from(financeApi.financeControllerGetFinancing()).pipe(
                switchMap(result => of(loadFinancialsAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadFinancialsAsync.failure());
                }),
            ),
        ),
    );

export const loadESAStatusEpic: Epic = (action$, state$, { financeApi }) =>
    action$.pipe(
        filter(isActionOf(loadESAStatusAsync.request)),
        debounceTime(500),
        switchMap(() =>
            from(financeApi.financeControllerGetESAStatus()).pipe(
                switchMap(result => of(loadESAStatusAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadESAStatusAsync.failure());
                }),
            ),
        ),
    );

export const unlinkAccountEpic: Epic = (action$, state$, { financeApi }) =>
    action$.pipe(
        filter(isActionOf(unlinkAccountAsync.request)),
        debounceTime(500),
        switchMap(() =>
            from(financeApi.financeControllerDeleteStoredACHInfo()).pipe(
                switchMap(result => of(unlinkAccountAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(unlinkAccountAsync.failure());
                }),
            ),
        ),
    );
