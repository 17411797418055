import { TaskDto, TaskDtoPriorityEnum, TaskDtoStatusEnum } from '@root/services/api';
import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

const tasksStateSelector = (state: RootState) => state.tasks;

export const tasksSelector = createSelector(tasksStateSelector, v => v.tasks);
export const tasksLoadFailedSelector = createSelector(tasksStateSelector, v => v.tasksLoadFailed);
const IsEvent = (task: TaskDto) => task.isEvent;
const IsValidUpNextStatus = (task: TaskDto) =>
    ![TaskDtoStatusEnum.Complete, TaskDtoStatusEnum.Archived].includes(task.status);
export const NextUpSort = (a: TaskDto, b: TaskDto) => {
    // Prioritize homeowner-assigned events higher in the result
    if (a.isHomeownerAssigned && !b.isHomeownerAssigned) return -1;
    if (!a.isHomeownerAssigned && b.isHomeownerAssigned) return 1;
    const dueDate = new Date(a.endTime).getTime() - new Date(b.endTime).getTime();
    const startDate = new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    const priorityVals = [TaskDtoPriorityEnum.High, TaskDtoPriorityEnum.Medium, TaskDtoPriorityEnum.Low];

    // First sort by due date (end time). Soonest first
    if (dueDate !== 0) return dueDate;

    // Then sort by start date. Soonest first.
    if (startDate !== 0) return startDate;

    // Finally, sort by priority (High > Med > Low).
    return priorityVals.indexOf(a.priority) - priorityVals.indexOf(b.priority);
};
export const nextUpSelector = createSelector(
    tasksSelector,
    v => v?.filter(IsEvent).filter(IsValidUpNextStatus).sort(NextUpSort)[0],
);
export const todoItemSelector = (taskId: string) => createSelector(tasksSelector, v => v?.find(t => t.id === taskId));
