import { FormikProps } from 'formik';
import { FocusEvent, useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import tw, { styled } from 'twin.macro';

interface InputBoxProps {
    disabled?: boolean;
    hasError: boolean;
    isFocused?: boolean;
}

const HideShowPasswordButton = tw.button`text-brand-muted rounded-full p-1 hocus:outline-none hover:(bg-app-surface text-brand-dark) active:(bg-brand-dark text-brand-white) mr-2`;

const InputWrapper = styled.div((props: InputBoxProps) => [
    tw`mt-4 pl-4 py-2 max-w-full w-72 border border-brand-muted outline-none bg-brand-white bg-opacity-50 rounded flex flex-row`,
    props.disabled ? tw`disabled:bg-brand-muted disabled:border-brand-muted` : '',
    props.isFocused ? tw`border-input-focus ring ring-input-focus ring-opacity-25` : '',
    props.hasError ? tw`border-input-error ring-input-error ring-opacity-25` : '',
]);

export const InputBox = styled.input(() => [
    tw`border-none outline-none hocus:outline-none flex-grow w-full mr-2`,
    `::placeholder {
        color: black;
        opacity: 0.7;
    }`,
]);

type SupportedInputType = 'text' | 'email' | 'password';

interface Props<T> {
    name: keyof T;
    type: SupportedInputType;
    placeholder: string;
    hasError: boolean;
    formik: FormikProps<T>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputBox?: any;
}

function FormikInputBox<T>({ name, type, hasError, formik, inputBox, ...rest }: Props<T>) {
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const Comp = inputBox ?? InputBox;
    const inferredType = type === 'password' && showPassword ? 'text' : type;

    return (
        <InputWrapper hasError={hasError} disabled={formik.isSubmitting} isFocused={isFocused}>
            <Comp
                disabled={formik.isSubmitting}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onBlur={(e: FocusEvent<any, Element>) => {
                    setIsFocused(false);
                    formik.handleBlur(e);
                }}
                onFocus={() => {
                    setIsFocused(true);
                }}
                onChange={formik.handleChange}
                value={formik.values[name]}
                name={name}
                {...rest}
                type={inferredType}
            />
            {type === 'password' && !showPassword && (
                <HideShowPasswordButton onClick={() => setShowPassword(true)} tabIndex={-1} type="button">
                    <Eye size="1rem" />
                </HideShowPasswordButton>
            )}
            {type === 'password' && showPassword && (
                <HideShowPasswordButton onClick={() => setShowPassword(false)} tabIndex={-1} type="button">
                    <EyeOff size="1rem" />
                </HideShowPasswordButton>
            )}
        </InputWrapper>
    );
}

FormikInputBox.defaultProps = {
    inputBox: undefined,
};

export default FormikInputBox;
