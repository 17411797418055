import { useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { MilestonePaymentDto } from '@services/api';
import NoPaymentsDue from './NoPaymentsDue';
import NextPayment from './NextPayment';
import { financialsSelector } from '../selectors';

function MakePayment() {
    const financingInfo = useSelector(financialsSelector);

    const firstMilestonePayment = financingInfo?.milestonePayments?.find(mp => mp.paymentNumber === 1);
    const secondMilestonePayment = financingInfo?.milestonePayments?.find(mp => mp.paymentNumber === 2);

    let nextPayment: MilestonePaymentDto | null = null;

    if (secondMilestonePayment?.canMakePayment) {
        nextPayment = secondMilestonePayment;
    } else if (firstMilestonePayment?.canMakePayment) {
        nextPayment = firstMilestonePayment;
    }

    return (
        <>
            {!financingInfo && <SkeletonLoader height="16rem" />}
            {financingInfo && (
                <>
                    {!nextPayment && <NoPaymentsDue />}
                    {nextPayment && <NextPayment payment={nextPayment} />}
                </>
            )}
        </>
    );
}

export default MakePayment;
