import { useLocation } from 'react-router';
import { accessTokenService } from '@services/index';

function LoginWithToken() {
    const query = new URLSearchParams(useLocation().search);
    const token = query.get('token');

    if (token) {
        accessTokenService.saveToken(token, false);
    }

    window.location.replace('/');

    return null;
}

export default LoginWithToken;
