import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers, useFormik } from 'formik';
import tw from 'twin.macro';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import FormikInputBox from '@components/FormikInputBox';
import { accessTokenService } from '@services/index';
import LoginFooter from './LoginFooter';
import CompassLogo from './Logo';
import { loginAsync } from '../actions';
import { errorSelector } from '../selectors';
import { Button as BaseButton } from './StyledComponents';

const Wrapper = tw.div`flex-grow flex items-center justify-center select-none overflow-y-auto`;
const FormContainer = tw.form`bg-brand-white max-w-[348px] p-8 flex flex-col items-center justify-center`;
const ErrorMessage = tw.div`p-2 text-center text-input-error lg:w-96`;
const ForgotPwContainer = tw.div`flex px-2 my-4 text-brand-muted`;
const ForgotPwLink = tw(Link)`mx-auto hover:(underline text-brand-primary)`;
const Button = tw(BaseButton)` w-[18rem] mt-[24px]`;

const validationSchema = Yup.object({
    emailAddress: Yup.string().required().email(),
    password: Yup.string().required(),
});

const initialValues = {
    emailAddress: '',
    password: '',
};

type FormData = typeof initialValues;

function LoginForm() {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
            dispatch(loginAsync.request({ ...values, formik: formikHelpers }));
        },
    });
    const errorMessage = useSelector(errorSelector);
    const buttonDisabled = formik.isSubmitting || !formik.isValid;

    useEffect(() => {
        accessTokenService.savePasswordChangeRequired(false, false);
    }, []);

    return (
        <Wrapper>
            <FormContainer onSubmit={formik.handleSubmit}>
                <CompassLogo />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <FormikInputBox
                    name="emailAddress"
                    type="email"
                    placeholder="Email address"
                    hasError={!!formik.touched.emailAddress && !!formik.errors.emailAddress}
                    formik={formik}
                />
                <FormikInputBox
                    name="password"
                    type="password"
                    placeholder="Password"
                    hasError={!!formik.touched.password && !!formik.errors.password}
                    formik={formik}
                />

                <Button type="submit" disabled={buttonDisabled}>
                    Sign In
                </Button>
                <ForgotPwContainer>
                    <ForgotPwLink to="/forgot-password">Forgot password?</ForgotPwLink>
                </ForgotPwContainer>
                <LoginFooter />
            </FormContainer>
        </Wrapper>
    );
}

export default LoginForm;
