import tw, { styled } from 'twin.macro';
import ButtonStyleBase from '@components/button';

const ModalOverlay = tw.div`bg-brand-black bg-opacity-70 z-40 fixed top-0 left-0 bottom-0 right-0 flex`;
const ModalContent = tw.div`m-auto bg-brand-white rounded shadow p-4 w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3`;
const Title = tw.h1`font-heading text-lg`;
const MessageBlock = tw.p`mb-2`;
const Buttons = tw.div`flex flex-row justify-center space-x-4 mx-auto mt-8`;
const Button = styled.button(() => [...ButtonStyleBase]);

interface Props {
    amount: number;
    confirmMode: 'ach' | 'cc';
    onConfirm: () => void;
    onCancel: () => void;
}

function PaymentConfirmModal({ amount, confirmMode, onConfirm, onCancel }: Props) {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });

    return (
        <ModalOverlay>
            <ModalContent>
                <Title>Confirm Payment</Title>
                <MessageBlock>
                    You are about to make a payment for {formatter.format(amount)}
                    {confirmMode === 'ach' && ' using the bank account we already have on file.'}
                    {confirmMode === 'cc' && ' using the credit card you provided.'}
                </MessageBlock>
                {confirmMode === 'ach' && (
                    <MessageBlock>
                        This payment will be started immediately and could take up to 3-5 business days to complete.
                    </MessageBlock>
                )}
                {confirmMode === 'cc' && <MessageBlock>Your card will be charged immediately.</MessageBlock>}
                <Buttons>
                    <Button onClick={() => onConfirm()}>Make Payment</Button>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </Buttons>
            </ModalContent>
        </ModalOverlay>
    );
}

export default PaymentConfirmModal;
