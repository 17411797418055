import tw from 'twin.macro';

const ButtonStyleBase = [
    tw`flex flex-row items-center`,
    tw`p-2 my-1 rounded shadow border text-left cursor-pointer`,
    tw`outline-none hocus:outline-none`,
    tw`border-brand-light bg-brand-white`,
    tw`hover:(bg-brand-light)`,
    tw`active:(ring bg-brand-white border-brand-primary ring-brand-primary)`,
    tw`disabled:(border-brand-light! bg-brand-light! text-brand-muted! cursor-not-allowed! ring-0!)`,
];

export default ButtonStyleBase;
