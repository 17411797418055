import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import Card from '@components/Card';
import { proposalsSelector, proposalsStatusSelector } from '../selectors';
import { loadProposalsAsync } from '../actions';
import ProposalCard from './ProposalCard';
import ProposalSkeletonCard from './ProposalSkeletonCard';

const Page = tw.div`flex flex-wrap`;

function Proposals() {
    const dispatch = useDispatch();
    const proposals = useSelector(proposalsSelector);
    const proposalsStatus = useSelector(proposalsStatusSelector);

    useEffect(() => {
        dispatch(loadProposalsAsync.request());
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>Proposals | Compass by Swell Energy</title>
            </Helmet>
            {proposalsStatus === 'error' && (
                <Card size="lg">
                    <LoadFailedOverlay onRetry={() => dispatch(loadProposalsAsync.request())} />
                </Card>
            )}
            {proposalsStatus === 'loading' && (
                <>
                    <ProposalSkeletonCard />
                    <ProposalSkeletonCard />
                </>
            )}
            {proposalsStatus === 'done' &&
                proposals
                    .sort((a, b) => new Date(a.createDate).getTime() - new Date(b.createDate).getTime())
                    .map(p => <ProposalCard key={p.id} proposal={p} />)}
        </Page>
    );
}

export default Proposals;
