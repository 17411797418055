import { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { MilestonePaymentDto } from '@services/api';
import PayViaACH from './PayViaACH';
import PayViaCC from './PayViaCC';

const Text = tw.span`text-brand-muted block pb-4`;
const PaymentContainer = tw.div`flex flex-col border border-brand-light`;
const PaymentMethods = tw.div`flex flex-row w-full`;
const MakePaymentContainer = tw.div`border-brand-primary border-2 w-full p-2`;

interface PaymentMethodProps {
    selected: boolean;
}

const PaymentMethod = styled.button((props: PaymentMethodProps) => [
    tw`w-full cursor-pointer text-center px-1 py-2 md:p-2 text-xs md:text-sm`,
    tw`outline-none hocus:outline-none active:outline-none`,
    tw`hover:bg-brand-light`,
    props.selected ? tw`bg-brand-primary text-brand-white font-bold hover:bg-brand-primary` : '',
]);

interface Props {
    payment: MilestonePaymentDto;
}

function NextPayment({ payment }: Props) {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<'ach' | 'cc'>('ach');

    return (
        <>
            <Text>
                Your next payment is <strong>{formatter.format(payment.amountDue)}</strong>. You can make a payment via
                ACH bank draft or with a credit card.
            </Text>
            <PaymentContainer>
                <PaymentMethods>
                    <PaymentMethod
                        selected={selectedPaymentMethod === 'ach'}
                        onClick={() => setSelectedPaymentMethod('ach')}
                    >
                        Pay via ACH
                    </PaymentMethod>
                    <PaymentMethod
                        selected={selectedPaymentMethod === 'cc'}
                        onClick={() => setSelectedPaymentMethod('cc')}
                    >
                        Pay via Credit Card
                    </PaymentMethod>
                </PaymentMethods>
                <MakePaymentContainer>
                    {selectedPaymentMethod === 'ach' && <PayViaACH payment={payment} />}
                    {selectedPaymentMethod === 'cc' && <PayViaCC payment={payment} />}
                </MakePaymentContainer>
            </PaymentContainer>
        </>
    );
}

export default NextPayment;
