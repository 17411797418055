import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadDemographicsAsync, loadStagesAsync, resetDemographics } from './actions';

const demographics = createReducer<Api.DemographicsDto | null>(null)
    .handleAction(loadDemographicsAsync.success, (_state, action) => action.payload)
    .handleAction([resetDemographics, resetContext], () => null);

const demographicsLoadFailed = createReducer(false)
    .handleAction([resetDemographics, loadDemographicsAsync.request, resetContext], () => false)
    .handleAction(loadDemographicsAsync.failure, () => true);

const stages = createReducer<Api.StageDto[]>([])
    .handleAction(loadStagesAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => []);

const stagesLoadFailed = createReducer(false)
    .handleAction(loadStagesAsync.request, () => false)
    .handleAction(loadStagesAsync.failure, () => true);

const profileReducer = combineReducers({
    demographics,
    demographicsLoadFailed,
    stages,
    stagesLoadFailed,
});

export default profileReducer;
export type ProfileState = ReturnType<typeof profileReducer>;
