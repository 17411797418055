import tw, { styled } from 'twin.macro';
import FooterLogo from '@components/FooterLogo';

const FormFooter = tw.div`text-brand-muted fill-current mt-8 text-xs flex flex-col space-y-2 text-center`;
const FooterLink = styled.a(() => [tw`underline hover:text-brand-primary`, `text-decoration-style: dotted;`]);

function LoginFooter() {
    return (
        <FormFooter>
            <FooterLogo />
            <span>&copy; 2022 Swell Energy | CSLB Lic # 1021183</span>
            <span>
                <FooterLink href="https://swellenergy.com/privacy-policy" target="_blank" rel="noreferrer">
                    Privacy &amp; Legal
                </FooterLink>
            </span>
            <span>
                Get Support: <FooterLink href="tel:+13103400493">(310) 340-0493</FooterLink>
            </span>
        </FormFooter>
    );
}

export default LoginFooter;
