import { createAsyncAction } from 'typesafe-actions';
import { Api } from '@services/index';
import { FetchUrlFailed, FetchUrlRequest } from './models';

export const loadAsync = createAsyncAction('documents/load', 'documents/load/success', 'documents/load/failure')<
    undefined,
    Api.DocumentDto[],
    undefined
>();

export const fetchUrlAsync = createAsyncAction(
    'documents/fetchUrl',
    'documents/fetchUrl/success',
    'documents/fetchUrl/failure',
)<FetchUrlRequest, Api.DocumentLinkDto, FetchUrlFailed>();
