import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const DocumentCard = tw.div`flex flex-row p-4 border select-none items-center justify-between bg-brand-white border-brand-light`;
const DocumentMetadata = tw.div`flex flex-col items-start`;
const Title = tw.div`my-1`;
const Modified = tw.div`text-sm text-brand-muted my-1`;

function DocumentsSkeleton() {
    const [titleWidth, setTitleWidth] = useState(0);
    const [metadataWidth, setMetadataWidth] = useState(0);

    useEffect(() => {
        setTitleWidth(12 + Math.random() * 12);
        setMetadataWidth(12 + Math.random() * 5);
    }, []);

    return (
        <DocumentCard>
            <DocumentMetadata>
                <Title>
                    <SkeletonLoader height="1.5rem" width={`${titleWidth}rem`} />
                </Title>
                <Modified>
                    <SkeletonLoader height="1.25rem" width={`${metadataWidth}rem`} />
                </Modified>
            </DocumentMetadata>
        </DocumentCard>
    );
}

export default DocumentsSkeleton;
