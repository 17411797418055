import { resetContext } from '@features/Auth/actions';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { fetchUrlAsync, loadAsync } from './actions';

const documents = createReducer<Api.DocumentDto[] | null>(null)
    .handleAction(loadAsync.success, (_state, action) =>
        [...action.payload].sort((a, b) => a.name.localeCompare(b.name)),
    )
    .handleAction(resetContext, () => null);

const documentsLoadFailed = createReducer(false)
    .handleAction(loadAsync.request, () => false)
    .handleAction(loadAsync.failure, () => true);

const initialDocumentLinks = {};

const documentLinks = createReducer<Record<string, Api.DocumentLinkDto | null>>(initialDocumentLinks)
    .handleAction(fetchUrlAsync.request, (state, action) => {
        return {
            ...state,
            [action.payload.documentId]: null,
        };
    })
    .handleAction(fetchUrlAsync.success, (state, action) => {
        return {
            ...state,
            [action.payload.id]: action.payload,
        };
    })
    .handleAction(resetContext, () => initialDocumentLinks);

const documentLinksFailures = createReducer<string[]>([])
    .handleAction(fetchUrlAsync.request, (state, action) => state.filter(v => v !== action.payload.documentId))
    .handleAction(fetchUrlAsync.failure, (state, action) =>
        state.includes(action.payload.documentId) ? state : [...state, action.payload.documentId],
    );

const documentsReducer = combineReducers({
    documents,
    documentsLoadFailed,
    documentLinks,
    documentLinksFailures,
});

export default documentsReducer;
export type ContactsState = ReturnType<typeof documentsReducer>;
