import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import Services from '@services/index';
import SetPasswordForm from './SetPasswordForm';
import SendResetForm from './SendResetForm';
import LoginFooter from './LoginFooter';
import Logo from './Logo';

const Wrapper = tw.div`flex-grow flex items-center justify-center select-none`;
const FormContainer = tw.div`bg-brand-white p-8 flex flex-col items-center justify-center`;

interface UrlParams {
    nonce: string;
}

function SetPassword() {
    const [status, setStatus] = useState<'loading' | 'ready' | 'expired'>('loading');
    const { nonce } = useParams<UrlParams>();

    useEffect(() => {
        const verifyNonce = async () => {
            try {
                const result = await Services.authApi.authControllerVerifySetPasswordNonce({ nonce });
                setStatus(result.status >= 200 && result.status < 300 && result.data?.valid ? 'ready' : 'expired');
            } catch {
                setStatus('expired');
            }
        };

        verifyNonce();
    }, [nonce]);

    return (
        <Wrapper>
            <Helmet>
                <title>Set Password | Compass by Swell Energy</title>
            </Helmet>
            <FormContainer>
                <Logo />

                {status === 'loading' && <SkeletonLoader width="24rem" height="20rem" />}
                {status === 'ready' && <SetPasswordForm nonce={nonce} />}
                {status === 'expired' && <SendResetForm isExpiredLink />}

                <LoginFooter />
            </FormContainer>
        </Wrapper>
    );
}

export default SetPassword;
