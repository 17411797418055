import { createAsyncAction } from 'typesafe-actions';
import { Api } from '@services/index';
import { LoadFinancialsRequest } from './models';

export const loadFinancialsAsync = createAsyncAction(
    'finance/loadFinancials',
    'finance/loadFinancials/success',
    'finance/loadFinancials/failure',
)<LoadFinancialsRequest, Api.FinancingInfoDto, undefined>();

export const loadESAStatusAsync = createAsyncAction(
    'finance/loadESAStatus',
    'finance/loadESAStatus/success',
    'finance/loadESAStatus/failure',
)<undefined, Api.ESAStatusDto, undefined>();

export const unlinkAccountAsync = createAsyncAction(
    'finance/unlinkAccount',
    'finance/unlinkAccount/success',
    'finance/unlinkAccount/failure',
)<undefined, Api.FinancingInfoDto, undefined>();
