import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import LinkCard from '@components/LinkCard';
import { AppSchematicDtoWidgetsEnum } from '@services/api';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import CardValue from '@components/CardValue';
import { widgetsSelector } from '@features/Portal/selectors';
import { financialsLoadFailedSelector, financialsSelector } from '@features/Financials/selectors';
import { loadFinancialsAsync } from '@features/Financials/actions';

const Stats = tw.div`flex flex-col justify-around items-start flex-grow`;

function MyProjectCard() {
    const dispatch = useDispatch();
    const widgets = useSelector(widgetsSelector);
    const financingInfo = useSelector(financialsSelector);
    const financingLoadFailed = useSelector(financialsLoadFailedSelector);
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });

    return (
        <LinkCard
            size="md"
            to={widgets.some(w => w === AppSchematicDtoWidgetsEnum.Financials) && financingInfo ? '/finance' : ''}
            title="My Project"
            navigateText="My financial dashboard"
        >
            <Stats>
                {/* If we don't have the financing info or we do _and_ it has an amount, show this value */}
                {(!financingInfo || financingInfo.amount) && (
                    <CardValue
                        label="Amount"
                        value={financingInfo?.amount ? formatter.format(financingInfo.amount) : undefined}
                        loaderWidth="6.0rem"
                    />
                )}
                <CardValue label="Funding Source" value={financingInfo?.source} loaderWidth="12.0rem" />
            </Stats>
            {financingLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadFinancialsAsync.request({}))} />}
        </LinkCard>
    );
}

export default MyProjectCard;
