import tw from 'twin.macro';
import ButtonStyleBase from './button';

const ButtonStylePrimary = [
    ...ButtonStyleBase,
    tw`bg-brand-dark text-brand-white`,
    tw`border-none`,
    tw`hover:enabled:(border-none bg-brand-dark ring ring-brand-light brightness-125)`,
    tw`active:enabled:(border-none bg-brand-dark ring-brand-primary brightness-100 opacity-80)`,
    tw`disabled:(text-brand-muted border-none bg-brand-light )`,
];
export default ButtonStylePrimary;
