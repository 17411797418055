/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { loadAsync } from './actions';

export const loadEpic: Epic = (action$, _state$, { onboardingApi }) =>
    action$.pipe(
        filter(isActionOf(loadAsync.request)),
        switchMap(() =>
            from(onboardingApi.onboardingControllerGetContacts()).pipe(
                switchMap(result => of(loadAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadAsync.failure());
                }),
            ),
        ),
    );
