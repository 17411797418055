import { useCallback, useState, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { Mail, Phone, Save } from 'react-feather';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import Services from '@services/index';
import { demographicsSelector } from '../selectors';
import { loadDemographicsAsync } from '../actions';

const Container = tw.div`mb-4 md:mb-0`;
const SkeletonRow = tw.div`h-7`;
const ContactLine = tw.div`flex flex-row items-center my-1`;
const EditableValue = tw.span`border-b border-brand-transparent`;

const Input = styled(Field)(() => [
    'max-width: 80%;',
    tw`flex-1`,
    tw`border-b`,
    tw`border-brand-muted text-brand-muted`,
    tw`focus:border-brand-primary focus:text-brand-primary`,
    tw`hocus:outline-none`,
]);

const ActionButtons = styled.div(() => ['max-width: 85%;', tw`flex flex-row justify-end`]);

const Title = tw.h2`font-heading text-sm text-brand-muted`;
const ErrorMessage = tw.span`text-xs text-input-error ml-0 mr-auto mt-2`;
const MailIcon = tw(Mail)`mr-1`;
const PhoneIcon = tw(Phone)`mr-1`;
const ActionText = tw.span`text-xs ml-1`;

const ActionButton = tw.button`
    p-2 m-2
    flex flex-row items-center
    rounded-full shadow-md hocus:outline-none
    text-brand-muted bg-brand-light ring-2 ring-brand-light
    hover:ring-brand-light
    active:text-brand-white active:bg-brand-primary active:ring-brand-light
`;

interface ContactInfoProps {
    isInEditMode: boolean;
    onExitEditMode: () => void;
}

const validationSchema = Yup.object({
    email: Yup.string().email('Please enter your e-mail address.').required('Please enter your e-mail address.'),
    phone: Yup.string().required('Please enter your phone number.'),
});

function ContactInfo({ isInEditMode, onExitEditMode }: ContactInfoProps) {
    const dispatch = useDispatch();
    const demographics = useSelector(demographicsSelector);
    const [editSaveFailed, setEditSaveFailed] = useState(false);
    const initialValues = {
        email: demographics?.email || '',
        phone: demographics?.phone || '',
    };

    const emailRef = useCallback(
        (inputNode: HTMLInputElement) => {
            if (isInEditMode && inputNode) {
                inputNode.focus();
            }
        },
        [isInEditMode],
    );

    useEffect(() => {
        setEditSaveFailed(false);
    }, [isInEditMode]);

    // Note: the span with transparent border bottom is to prevent layout shift when entering edit mode.
    return (
        <Container>
            {!demographics && (
                <>
                    <SkeletonRow>
                        <SkeletonLoader width="5.0rem" height="1.0rem" />
                    </SkeletonRow>
                    <SkeletonRow>
                        <SkeletonLoader width="12.0rem" height="1.0rem" />
                    </SkeletonRow>
                    <SkeletonRow>
                        <SkeletonLoader width="8.0rem" height="1.0rem" />
                    </SkeletonRow>
                </>
            )}
            {demographics && !isInEditMode && (
                <>
                    <div>
                        <Title>Contact Info</Title>
                    </div>
                    <ContactLine>
                        <MailIcon size="1.0rem" />
                        <EditableValue>{demographics.email}</EditableValue>
                    </ContactLine>
                    <ContactLine>
                        <PhoneIcon size="1.0rem" />
                        <EditableValue>{demographics.phone}</EditableValue>
                    </ContactLine>
                </>
            )}
            {demographics && isInEditMode && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async values => {
                        setEditSaveFailed(false);

                        try {
                            await Services.demographicsApi.demographicsControllerUpdateDemographics({ ...values });
                            dispatch(loadDemographicsAsync.request());
                            onExitEditMode();
                        } catch (err) {
                            Sentry.captureException(err);
                            setEditSaveFailed(true);
                        }
                    }}
                    onReset={() => {
                        onExitEditMode();
                    }}
                >
                    {formik => (
                        <Form>
                            <div>
                                <Title>Contact Info</Title>
                            </div>

                            <ContactLine>
                                <MailIcon size="1.0rem" />
                                <Input type="email" name="email" placeholder="Email address" innerRef={emailRef} />
                            </ContactLine>
                            <ContactLine>
                                <PhoneIcon size="1.0rem" />
                                <Input type="tel" name="phone" placeholder="Phone number" />
                            </ContactLine>
                            <ActionButtons>
                                {editSaveFailed && (
                                    <ErrorMessage>
                                        There was a problem saving your changes.
                                        <br />
                                        Please try again.
                                    </ErrorMessage>
                                )}
                                <ActionButton type="reset">
                                    <ActionText>Cancel</ActionText>
                                </ActionButton>
                                <ActionButton type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                    <Save size="0.9rem" />
                                    <ActionText>Save</ActionText>
                                </ActionButton>
                            </ActionButtons>
                        </Form>
                    )}
                </Formik>
            )}
        </Container>
    );
}

export default ContactInfo;
