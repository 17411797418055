import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { demographicsSelector } from '../selectors';
import { toTitleCase } from '../../../util/strings';

const Title = tw.h2`font-heading text-sm text-brand-muted`;
const LoaderRow = tw.div`h-7 flex flex-row`;
const HorizontalSpacer = tw.div`mx-1`;

function Address() {
    const demographics = useSelector(demographicsSelector);

    return (
        <>
            {!demographics && (
                <>
                    <LoaderRow>
                        <SkeletonLoader width="5.0rem" height="1.0rem" />
                    </LoaderRow>
                    <LoaderRow>
                        <SkeletonLoader width="12.0rem" height="1.0rem" />
                    </LoaderRow>
                    <LoaderRow>
                        <SkeletonLoader width="6.0rem" height="1.0rem" />
                        <HorizontalSpacer />
                        <SkeletonLoader width="2.0rem" height="1.0rem" />
                        <HorizontalSpacer />
                        <SkeletonLoader width="3.0rem" height="1.0rem" />
                    </LoaderRow>
                </>
            )}
            {demographics && (
                <>
                    <div>
                        <Title>Address</Title>
                    </div>
                    <div>{toTitleCase(demographics.address1 || '')}</div>
                    <div>{demographics.address2}</div>
                    <div>
                        {toTitleCase(demographics.city || '')}, {demographics.state} {demographics.zip}
                    </div>
                </>
            )}
        </>
    );
}

export default Address;
