import ButtonStylePrimary from '@components/button-primary';
import FormikInputBox, { InputBox } from '@components/FormikInputBox';
import HelperLabel from '@components/HelperLabel';
import PasswordStrengthIndicator from '@components/PasswordStrengthIndicator';
import Services from '@services/index';
import { FormikHelpers, useFormik } from 'formik';
import { useState } from 'react';
import tw, { styled } from 'twin.macro';
import * as Yup from 'yup';
import BaseCard from './BaseCard';

const Row = tw.div`flex flex-col py-2`;
const Label = tw.span`font-heading text-sm leading-none text-brand-muted mb-1`;
const PasswordStrengthIndicatorContainer = tw.div`w-[18rem] max-w-full`;
const ConfirmInputBox = tw(FormikInputBox)`mx-0`;
const InfoInputBox = tw(InputBox)`m-0`;
const StatusMessage = tw.p`text-xs mr-auto p-2 leading-relaxed text-brand-success`;
const StatusMessageError = tw.p`text-xs mr-auto p-2 leading-relaxed text-input-error`;

const Button = styled.button(() => [...ButtonStylePrimary, tw`w-[18rem] max-w-full justify-center`]);

const DefaultErrorMessage = 'There was an error changing your password. Please check your old password and try again.';

const validationSchema = Yup.object({
    oldPassword: Yup.string().min(1).required(),
    newPassword: Yup.string().min(8).required().matches(/[a-z]/).matches(/[A-Z]/).matches(/\d/).matches(/\W/),
    verifiedNewPassword: Yup.string().min(8).required(),
});

const initialValues = {
    oldPassword: '',
    newPassword: '',
    verifiedNewPassword: '',
};

type FormData = typeof initialValues;

function ChangePasswordCard() {
    const [statusMessage, setStatusMessage] = useState({ error: false, text: '' });

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnMount: true,
        onSubmit: async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
            try {
                setStatusMessage({ error: false, text: '' });

                if (values.newPassword !== values.verifiedNewPassword) {
                    return;
                }

                const result = await Services.authApi.authControllerChangePassword({
                    ...values,
                });

                setStatusMessage({
                    error: false,
                    text:
                        result.status >= 200 && result.status < 300
                            ? 'Your password has been updated.'
                            : DefaultErrorMessage,
                });
            } catch {
                setStatusMessage({ error: true, text: DefaultErrorMessage });
            }

            formikHelpers.resetForm();
        },
    });

    const buttonDisabled =
        formik.isSubmitting || !formik.isValid || formik.values.newPassword !== formik.values.verifiedNewPassword;

    return (
        <BaseCard size="md" title="Change Account Password">
            <form onSubmit={formik.handleSubmit}>
                {statusMessage.text && (
                    <Row>
                        {statusMessage.error ? (
                            <StatusMessageError>{statusMessage.text}</StatusMessageError>
                        ) : (
                            <StatusMessage>{statusMessage.text}</StatusMessage>
                        )}
                    </Row>
                )}
                <Row>
                    <Label>Current Password</Label>
                    <FormikInputBox
                        inputBox={InfoInputBox}
                        hasError={false}
                        placeholder=""
                        type="password"
                        name="oldPassword"
                        formik={formik}
                    />
                </Row>
                <Row>
                    <Label>New Password</Label>
                    <FormikInputBox
                        inputBox={InfoInputBox}
                        hasError={!!formik.touched.newPassword && formik.values.newPassword.length < 8}
                        placeholder=""
                        type="password"
                        name="newPassword"
                        formik={formik}
                    />
                    <PasswordStrengthIndicatorContainer>
                        <PasswordStrengthIndicator currentValue={formik.values.newPassword} />
                    </PasswordStrengthIndicatorContainer>
                </Row>
                <Row>
                    <Label>Confirm Password</Label>
                    <ConfirmInputBox
                        inputBox={InfoInputBox}
                        hasError={
                            !!formik.touched.verifiedNewPassword &&
                            formik.values.newPassword !== formik.values.verifiedNewPassword
                        }
                        placeholder=""
                        type="password"
                        name="verifiedNewPassword"
                        formik={formik}
                    />
                    <HelperLabel
                        success={
                            !!formik.values.newPassword &&
                            formik.values.newPassword === formik.values.verifiedNewPassword
                        }
                    >
                        Both passwords must match.
                    </HelperLabel>
                </Row>
                <Row>
                    <Button type="submit" disabled={buttonDisabled}>
                        Submit
                    </Button>
                </Row>
            </form>
        </BaseCard>
    );
}

export default ChangePasswordCard;
