/* eslint-disable no-param-reassign */
import AppTheme from '@root/AppTheme';
import {
    Configuration,
    AppSchematicApi,
    AuthApi,
    DemographicsApi,
    FinanceApi,
    OnboardingApi,
    ProductsApi,
    DocumentsApi,
    UtilityApi,
    ProposalsApi,
} from './api';
import * as accessTokenService from './access-token-service';

const apiBaseUrl = process.env.REACT_APP_API_BASE_PATH;
const apiConfig = new Configuration();
apiConfig.basePath = apiBaseUrl;
apiConfig.accessToken = () => window.sessionStorage.getItem('access_token') || '';
apiConfig.baseOptions = {
    transformRequest: [
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any, headers: any) => {
            headers['X-Compass-Parent'] = window.sessionStorage.getItem('parent_id');
            headers['X-Compass-Context'] = window.sessionStorage.getItem('context_id');
            headers['X-Compass-Theme'] = AppTheme.themeKey;
            return data;
        },
    ],
};

const appSchematicApi = new AppSchematicApi(apiConfig);
const authApi = new AuthApi(apiConfig);
const demographicsApi = new DemographicsApi(apiConfig);
const documentsApi = new DocumentsApi(apiConfig);
const financeApi = new FinanceApi(apiConfig);
const onboardingApi = new OnboardingApi(apiConfig);
const utilityApi = new UtilityApi(apiConfig);
const productsApi = new ProductsApi(apiConfig);
const proposalsApi = new ProposalsApi(apiConfig);

export * as Api from './api';
export { accessTokenService };

const services = {
    appSchematicApi,
    authApi,
    accessTokenService,
    demographicsApi,
    documentsApi,
    financeApi,
    onboardingApi,
    utilityApi,
    productsApi,
    proposalsApi,
};

export default services;
