import { createAction, createAsyncAction } from 'typesafe-actions';
import { Api } from '@services/index';

export const loadDemographicsAsync = createAsyncAction(
    'profile/loadDemographics',
    'profile/loadDemographics/success',
    'profile/loadDemographics/failure',
)<undefined, Api.DemographicsDto, undefined>();

export const resetDemographics = createAction('profile/resetDemographics')();

export const loadStagesAsync = createAsyncAction(
    'profile/loadStages',
    'profile/loadStages/success',
    'profile/loadStages/failure',
)<undefined, Api.StageDto[], undefined>();
