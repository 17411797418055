import tw, { styled } from 'twin.macro';
import { PhoneCall, Mail } from 'react-feather';
import Card from '@components/Card';
import Logo from '@images/contact-logo.png';

const ContactCardContent = tw(Card)`flex flex-col items-center text-center`;
const AvatarContainer = tw.div`flex items-center justify-center w-28 h-28 mt-8`;
const Avatar = tw.img`w-28`;
const Title = tw.span`text-lg mt-2`;
const Subtitle = tw.span`text-sm text-brand-muted`;
const ContactMethods = tw.div`flex flex-col mb-8 mt-auto border rounded`;
const MailIcon = tw(Mail)`mr-2`;
const PhoneCallIcon = tw(PhoneCall)`mr-2`;
const Hours = tw.span`text-xs`;

const ContactMethod = styled.a(() => [
    tw`px-4 py-2 flex flex-row items-center`,
    tw`border-b`,
    tw`text-brand-muted hover:text-brand-black`,
    tw`bg-brand-white hover:bg-brand-light`,
    tw`hocus:outline-none`,
]);

function ESABillingSupportCard() {
    const phoneRaw = '8445889522';
    const phoneFormatted = '(844) 588-9522';
    const email = 'solarcustomersupport@accountservicing.com';

    return (
        <ContactCardContent size="md">
            <AvatarContainer>
                <Avatar src={Logo} />
            </AvatarContainer>
            <Title>Billing Support</Title>
            <Subtitle>Operating hours</Subtitle>
            <Hours>Mon-Fri: 5AM to 5PM Pacific</Hours>
            <ContactMethods>
                <ContactMethod href={`mailto:${email}`}>
                    <MailIcon size="1.1rem" /> Send email
                </ContactMethod>
                <ContactMethod href={`tel:${phoneRaw}`}>
                    <PhoneCallIcon size="1.1rem" /> {phoneFormatted}
                </ContactMethod>
            </ContactMethods>
        </ContactCardContent>
    );
}

export default ESABillingSupportCard;
