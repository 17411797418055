import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import tw, { styled } from 'twin.macro';
import { FinancingInfoDtoAchStatusEnum, MilestonePaymentDto } from '@services/api';
import ButtonStyleBase from '@components/button';
import Services from '@services/index';
import LinkBankAccount from './LinkBankAccount';
import StripeBadge from '../assets/stripe.png';
import PaymentConfirmModal from './PaymentConfirmModal';
import { financialsSelector } from '../selectors';
import { loadFinancialsAsync, unlinkAccountAsync } from '../actions';
import UnlinkConfirmModal from './UnlinkConfirmModal';

const Header = tw.div`mt-2 mb-6 flex flex-row items-center justify-between`;
const PaymentTitle = tw.h2`text-brand-muted`;
const StripeLogo = tw.img`h-8`;
const PaymentTable = tw.div`flex flex-col border border-brand-light`;
const Line = tw.div`flex flex-row justify-between p-2 items-center`;
const Name = tw.span`text-sm text-brand-muted`;
const Value = tw.span`text-right text-brand-black`;
interface HelpTextProps {
    centered?: boolean | undefined;
}
const HelpText = styled.p(({ centered }: HelpTextProps) => [
    tw`text-xs my-2 text-brand-muted m-2`,
    centered ? tw`mx-auto` : '',
]);
const ErrorText = tw.p`text-xs m-2 p-2 bg-brand-light leading-relaxed text-input-error`;
const PayButton = styled.button(() => [...ButtonStyleBase, tw`justify-center font-bold m-2`]);
const UnlinkButton = styled.button(() => [...ButtonStyleBase, tw`justify-center m-2 text-sm`]);

const DefaultErrorMessage = 'We had a problem processing your payment. Please try again.';

interface Props {
    payment: MilestonePaymentDto;
}

function PayViaACH({ payment }: Props) {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const [isConfirming, setIsConfirming] = useState(false);
    const [isUnlinking, setIsUnlinking] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const financingInfo = useSelector(financialsSelector);

    const submitPayment = async () => {
        setErrorMessage('');
        setIsConfirming(false);
        setIsProcessing(true);

        if (!financingInfo || !financingInfo.customerPaymentId) {
            return;
        }

        try {
            const result = await Services.financeApi.financeControllerPostPaymentACH({
                id: financingInfo.customerPaymentId,
                paymentNumber: payment.paymentNumber,
            });

            if (!result.data.processed) {
                setErrorMessage(result.data.extendedError || DefaultErrorMessage);
            } else {
                dispatch(loadFinancialsAsync.request({ forceReset: true }));
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            Sentry.captureException(err);
            setErrorMessage(err.response?.data?.extendedError || DefaultErrorMessage);
        }

        setIsProcessing(false);
    };

    const unlinkAccount = () => {
        dispatch(unlinkAccountAsync.request());
        setIsUnlinking(false);
    };

    return (
        <>
            {!financingInfo && <SkeletonLoader />}
            {financingInfo && (
                <>
                    {financingInfo.achStatus !== FinancingInfoDtoAchStatusEnum.AccountVerified && <LinkBankAccount />}
                    {financingInfo.achStatus === FinancingInfoDtoAchStatusEnum.AccountVerified && (
                        <>
                            <Header>
                                <PaymentTitle>Payment #{payment.paymentNumber}</PaymentTitle>
                                <StripeLogo src={StripeBadge} />
                            </Header>
                            <PaymentTable>
                                <Line>
                                    <Name>Payment Amount</Name>
                                    <Value>{formatter.format(payment.amountDue)}</Value>
                                </Line>
                                <HelpText>Your payment will be charged to the bank account already on file.</HelpText>
                                {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                                <PayButton type="button" disabled={isProcessing} onClick={() => setIsConfirming(true)}>
                                    Pay {formatter.format(payment.amountDue)} Now
                                </PayButton>
                                <HelpText centered>- or -</HelpText>
                                <UnlinkButton type="button" onClick={() => setIsUnlinking(true)}>
                                    Pay using a different account
                                </UnlinkButton>
                            </PaymentTable>
                            {isConfirming && (
                                <PaymentConfirmModal
                                    amount={payment.amountDue}
                                    confirmMode="ach"
                                    onConfirm={() => submitPayment()}
                                    onCancel={() => setIsConfirming(false)}
                                />
                            )}
                            {isUnlinking && (
                                <UnlinkConfirmModal
                                    onConfirm={() => unlinkAccount()}
                                    onCancel={() => setIsUnlinking(false)}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default PayViaACH;
