import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { loadGSStagesAsync, loadInfoAsync } from './actions';

export const loadInfoEpic: Epic = (action$, _state$, { utilityApi }) =>
    action$.pipe(
        filter(isActionOf(loadInfoAsync.request)),
        switchMap(() =>
            from(utilityApi.utilityControllerGet()).pipe(
                switchMap(result => of(loadInfoAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadInfoAsync.failure());
                }),
            ),
        ),
    );

export const loadGSStagesEpic: Epic = (action$, _state$, { onboardingApi }) =>
    action$.pipe(
        filter(isActionOf(loadGSStagesAsync.request)),
        switchMap(() =>
            from(onboardingApi.onboardingControllerGetGridServicesStages()).pipe(
                switchMap(result => of(loadGSStagesAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadGSStagesAsync.failure());
                }),
            ),
        ),
    );
