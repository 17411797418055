import tw, { styled } from 'twin.macro';
import { PhoneCall, Mail } from 'react-feather';
import Card from '@components/Card';
import { ContactDto } from '@services/api';
import ButtonStyleBase from '@components/button';

const ContactCardContent = tw(Card)`flex flex-col items-center text-center`;
const Avatar = tw.img`rounded-full w-28 h-28 mt-8`;
const Title = tw.span`text-lg mt-2`;
const Subtitle = tw.span`text-sm text-brand-muted`;
const OrgName = tw.span`mt-2`;
const Address = tw.span`text-xs`;
const ContactMethods = tw.div`flex flex-col mb-8 mt-auto`;
const Spacer = tw.div`h-4`;
const MailIcon = tw(Mail)`mr-2`;
const PhoneCallIcon = tw(PhoneCall)`mr-2`;

const ContactLink = styled.a(() => [...ButtonStyleBase]);
interface Props {
    contact: ContactDto;
}

function ContactCard({ contact }: Props) {
    const phoneRaw = contact.phone?.replaceAll(/\D/g, '');
    const phoneSplit = phoneRaw?.match(/(\d{3})(\d{3})(\d{4})/);

    let phoneFormatted = contact.phone;

    if (phoneSplit && phoneSplit.length === 4) {
        phoneFormatted = `(${phoneSplit[1]}) ${phoneSplit[2]}-${phoneSplit[3]}`;
    }

    return (
        <ContactCardContent size="md">
            <Avatar src={contact.avatarUrl} />
            <Title>
                {contact.firstName} {contact.lastName}
            </Title>
            <Subtitle>{contact.role}</Subtitle>
            <OrgName>{contact.orgName}</OrgName>
            <Address>{contact.address1}</Address>
            <Address>{contact.address2}</Address>
            <Address>
                {contact.city}, {contact.state} {contact.zip}
            </Address>
            <Spacer />
            <ContactMethods>
                {contact.email && (
                    <ContactLink href={`mailto:${contact.email}`}>
                        <MailIcon size="1.1rem" /> Send email
                    </ContactLink>
                )}
                {contact.phone && (
                    <ContactLink href={`tel:${phoneRaw}`}>
                        <PhoneCallIcon size="1.1rem" /> {phoneFormatted}
                    </ContactLink>
                )}
            </ContactMethods>
        </ContactCardContent>
    );
}

export default ContactCard;
