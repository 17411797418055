import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import Card from '@components/Card';

const TodoCard = tw(Card)`flex flex-col lg:flex-row`;
const Half = tw.div`w-full lg:w-1/2 flex flex-col mb-12 last:mb-0 lg:mb-0`;
const Group = tw.div`mb-8 last:mb-0`;
const Description = tw.span`mt-8 flex flex-col w-4/5`;
const DescriptionLine = tw.div`flex flex-row mb-1`;

function SkeletonTodoPage() {
    return (
        <TodoCard size="full">
            <Half>
                <SkeletonLoader height="1.75rem" width="14rem" />
                <Description>
                    <DescriptionLine>
                        <SkeletonLoader height="1.25rem" width="100%" />
                        <div tw="w-2" />
                        <SkeletonLoader height="1.25rem" tw="flex-grow" />
                    </DescriptionLine>
                    <DescriptionLine>
                        <SkeletonLoader height="1.25rem" width="30%" />
                        <div tw="w-2" />
                        <SkeletonLoader height="1.25rem" width="50%" />
                    </DescriptionLine>
                </Description>
            </Half>
            <Half>
                <Group>
                    <SkeletonLoader height="1.75rem" width="14rem" />
                    <Description>
                        <DescriptionLine>
                            <SkeletonLoader height="1.25rem" width="100%" />
                            <div tw="w-2" />
                            <SkeletonLoader height="1.25rem" tw="flex-grow" />
                        </DescriptionLine>
                        <DescriptionLine>
                            <SkeletonLoader height="1.25rem" width="30%" />
                            <div tw="w-2" />
                            <SkeletonLoader height="1.25rem" width="50%" />
                        </DescriptionLine>
                    </Description>
                </Group>
                <Group>
                    <SkeletonLoader height="1.75rem" width="14rem" />
                    <Description>
                        <DescriptionLine>
                            <SkeletonLoader height="1.25rem" width="100%" />
                            <div tw="w-2" />
                            <SkeletonLoader height="1.25rem" tw="flex-grow" />
                        </DescriptionLine>
                        <DescriptionLine>
                            <SkeletonLoader height="1.25rem" width="30%" />
                            <div tw="w-2" />
                            <SkeletonLoader height="1.25rem" width="50%" />
                        </DescriptionLine>
                    </Description>
                </Group>
            </Half>
        </TodoCard>
    );
}

export default SkeletonTodoPage;
