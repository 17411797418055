import tw, { styled } from 'twin.macro';
import { NavLink } from 'react-router-dom';
import AppTheme from '@root/AppTheme';

const MenuStyles = [
    tw`flex mx-2 p-2`,
    tw`hocus:outline-none`,
    tw`select-none`,
    tw`text-brand-muted hover:text-brand-black active:text-brand-white`,
    tw`bg-brand-white hover:bg-brand-light active:bg-brand-primary`,
    `&.active { color: ${AppTheme.brandColor}; background-color: #f8f8f8; }`,
];

export const MenuItem = styled(NavLink)(() => [...MenuStyles]);
export const ButtonMenuItem = styled.button(() => [...MenuStyles, tw`w-full`]);
export const ExternalMenuItem = styled.a(() => [...MenuStyles]);

export const MenuSpacer = styled.div(() => [tw`h-3`]);

export const ItemText = styled.span(() => [tw`ml-2`]);

export const Badge = tw.span`bg-app-surface rounded p-1 ml-2 text-xs text-brand-dark`;
