import tw, { styled } from 'twin.macro';
import { useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { MilestonePaymentDtoStatusEnum } from '@services/api';
import { financialsSelector } from '../selectors';

const Stats = tw.div`flex flex-col border border-brand-light`;

interface StatProps {
    highlight?: boolean;
    indent?: boolean;
    snug?: boolean;
}

const Stat = styled.div((props: StatProps) => [
    tw`flex flex-row justify-between p-2`,
    props.highlight ? tw`bg-brand-light` : '',
    props.indent ? tw`pl-8` : '',
    props.snug ? tw`pt-0` : '',
]);

interface StatTextProps {
    isSmall?: boolean;
}

const Name = styled.span(({ isSmall }: StatTextProps) => [tw`text-sm text-brand-muted`, isSmall ? tw`text-xs` : '']);
const Description = tw.span`text-xs text-brand-muted`;
const NameContainer = tw.div`flex flex-col flex-grow mr-4`;

interface ValueProps {
    paid?: boolean;
    muted?: boolean;
}

const ValueContainer = styled.div(() => [tw`flex flex-row items-start`]);

const Value = styled.span((props: ValueProps & StatTextProps) => [
    tw`w-28 text-right text-brand-muted`,
    props.paid ? tw`text-brand-success` : '',
    props.muted ? tw`text-brand-muted` : '',
    props.isSmall ? tw`text-sm` : '',
]);

interface BadgeProps {
    paid?: boolean;
    due?: boolean;
    muted?: boolean;
}

const Badge = styled.div((props: BadgeProps) => [
    tw`py-1 px-2 mr-2 h-auto`,
    tw`text-xs text-brand-white font-bold`,
    props.paid ? tw`bg-brand-success` : '',
    props.due && !props.muted ? tw`bg-brand-dark` : '',
    props.due && props.muted ? tw`bg-brand-muted` : '',
    !props.due && props.muted ? tw`bg-brand-muted` : '',
]);

function PaidBadge() {
    return <Badge paid>Paid</Badge>;
}

function ProcessingBadge() {
    return <Badge muted>Processing</Badge>;
}

function BillingInformation() {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const financingInfo = useSelector(financialsSelector);

    const firstMilestonePayment = financingInfo?.milestonePayments?.find(mp => mp.paymentNumber === 1);
    const secondMilestonePayment = financingInfo?.milestonePayments?.find(mp => mp.paymentNumber === 2);

    return (
        <Stats>
            {financingInfo?.amount && (
                <Stat>
                    <Name>Contract Price</Name>
                    {financingInfo && <Value muted>{formatter.format(financingInfo.amount)}</Value>}
                    {!financingInfo && <SkeletonLoader width="10.0rem" height="1.5rem" />}
                </Stat>
            )}
            {financingInfo && (financingInfo.programIncentive > 0 || financingInfo.programIncentive < 0) && (
                <Stat>
                    <Name>Program Incentives</Name>
                    <Value muted>{formatter.format(financingInfo.programIncentive * -1)}</Value>
                </Stat>
            )}
            {financingInfo && (financingInfo.adjustment > 0 || financingInfo.adjustment < 0) && (
                <Stat>
                    <Name>Adjustments</Name>
                    {financingInfo && <Value muted>{formatter.format(financingInfo.adjustment)}</Value>}
                    {!financingInfo && <SkeletonLoader width="8.0rem" height="1.5rem" />}
                </Stat>
            )}
            {financingInfo && (financingInfo.discount > 0 || financingInfo.discount < 0) && (
                <Stat>
                    <Name>Discounts</Name>
                    {financingInfo && <Value muted>{formatter.format(financingInfo.discount * -1)}</Value>}
                    {!financingInfo && <SkeletonLoader width="8.0rem" height="1.5rem" />}
                </Stat>
            )}
            {financingInfo &&
                financingInfo.netIncludesRebate &&
                (financingInfo.rebate > 0 || financingInfo.rebate < 0) && (
                    <Stat>
                        <NameContainer>
                            <Name>Rebate</Name>
                            <Description>
                                Rebates are deducted from your amount are included in the Net Amount below.
                            </Description>
                        </NameContainer>
                        <Value muted>{formatter.format(financingInfo.rebate * -1)}</Value>
                    </Stat>
                )}
            <Stat highlight>
                <NameContainer>
                    <Name>Net Amount</Name>
                    <Description>Net Amount is the total amount you must pay.</Description>
                </NameContainer>
                {financingInfo && (
                    <Value>
                        {formatter.format(
                            financingInfo.netAmount - (financingInfo.netIncludesRebate ? financingInfo.rebate : 0),
                        )}
                    </Value>
                )}
                {!financingInfo && <SkeletonLoader width="10.0rem" height="1.5rem" />}
            </Stat>
            <Stat highlight indent>
                <Name>Deposit</Name>
                {financingInfo && (
                    <ValueContainer>
                        {financingInfo.actualDepositMade > 0 && <PaidBadge />}
                        <Value paid={financingInfo.actualDepositMade > 0}>
                            {formatter.format(financingInfo.deposit)}
                        </Value>
                    </ValueContainer>
                )}
                {!financingInfo && <SkeletonLoader width="9.0rem" height="1.5rem" />}
            </Stat>
            {firstMilestonePayment && (
                <>
                    <Stat highlight indent>
                        <Name>Payment #1</Name>
                        <ValueContainer>
                            {firstMilestonePayment.amountPaid > 0 && <PaidBadge />}
                            {firstMilestonePayment.status === MilestonePaymentDtoStatusEnum.Waiting && (
                                <ProcessingBadge />
                            )}
                            <Value paid={!!firstMilestonePayment.amountPaid}>
                                {formatter.format(
                                    firstMilestonePayment.subtotalPaid > 0
                                        ? firstMilestonePayment.subtotalPaid
                                        : firstMilestonePayment.amountDue,
                                )}
                            </Value>
                        </ValueContainer>
                    </Stat>
                    {firstMilestonePayment.feePaid > 0 && (
                        <Stat highlight indent snug>
                            <Name isSmall>+ processing fee</Name>
                            <ValueContainer>
                                <Value paid isSmall>
                                    {formatter.format(firstMilestonePayment.feePaid)}
                                </Value>
                            </ValueContainer>
                        </Stat>
                    )}
                </>
            )}
            {secondMilestonePayment && (
                <>
                    <Stat highlight indent>
                        <Name>Payment #2</Name>
                        <ValueContainer>
                            {secondMilestonePayment.amountPaid > 0 && <PaidBadge />}
                            {secondMilestonePayment.status === MilestonePaymentDtoStatusEnum.Waiting && (
                                <ProcessingBadge />
                            )}
                            <Value paid={!!secondMilestonePayment.amountPaid}>
                                {formatter.format(
                                    secondMilestonePayment.subtotalPaid > 0
                                        ? secondMilestonePayment.subtotalPaid
                                        : secondMilestonePayment.amountDue,
                                )}
                            </Value>
                        </ValueContainer>
                    </Stat>
                    {secondMilestonePayment.feePaid > 0 && (
                        <Stat highlight indent snug>
                            <Name isSmall>+ processing fee</Name>
                            <ValueContainer>
                                <Value paid isSmall>
                                    {formatter.format(secondMilestonePayment.feePaid)}
                                </Value>
                            </ValueContainer>
                        </Stat>
                    )}
                </>
            )}
            {!financingInfo && (
                <>
                    <Stat highlight indent>
                        <Name>Payment #1</Name>
                        <SkeletonLoader width="7.0rem" height="1.5rem" />
                    </Stat>
                    <Stat highlight indent>
                        <Name>Payment #2</Name>
                        <SkeletonLoader width="7.0rem" height="1.5rem" />
                    </Stat>
                </>
            )}
            {financingInfo &&
                !financingInfo.netIncludesRebate &&
                (financingInfo.rebate > 0 || financingInfo.rebate < 0) && (
                    <Stat>
                        <NameContainer>
                            <Name>Rebate</Name>
                            {financingInfo?.netIncludesRebate && (
                                <Description>
                                    Rebates are deducted from your amount are included in the Net Amount below.
                                </Description>
                            )}
                            <Description>
                                Rebates are paid directly to you and are not included in the Net Amount.
                            </Description>
                        </NameContainer>
                        <Value>{formatter.format(financingInfo.rebate)}</Value>
                    </Stat>
                )}
        </Stats>
    );
}

export default BillingInformation;
