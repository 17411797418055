import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import tw from 'twin.macro';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { MilestonePaymentDto } from '@services/api';
import Services from '@services/index';
import CCPaymentForm from './CCPaymentForm';
import StripeBadge from '../assets/stripe.png';
import PaymentConfirmModal from './PaymentConfirmModal';
import { financialsSelector } from '../selectors';
import { loadFinancialsAsync } from '../actions';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
const DefaultErrorMessage = 'We had a problem processing your payment. Please try again.';

const Header = tw.div`mt-2 mb-6 flex flex-row items-center justify-between`;
const PaymentTitle = tw.h2`text-brand-muted`;
const StripeLogo = tw.img`h-8`;
const PaymentTable = tw.div`flex flex-col border border-brand-light`;
const Line = tw.div`flex flex-row justify-between p-2 items-center`;
const Name = tw.span`text-sm text-brand-muted`;
const Value = tw.span`text-right  text-brand-black`;
const HelpText = tw.p`text-xs my-2 text-brand-muted`;
const ErrorText = tw.p`text-xs m-2 p-2 bg-brand-light leading-relaxed text-input-error`;

interface Props {
    payment: MilestonePaymentDto;
}

function PayViaCC({ payment }: Props) {
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const [stripeToken, setStripeToken] = useState('');
    const [isConfirming, setIsConfirming] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();
    const { customerPaymentId, chargeStripeFee } = useSelector(financialsSelector) || {};

    const total = payment.amountDue + payment.feesDue;

    const onGotToken = (token: string) => {
        setStripeToken(token);
        setIsConfirming(true);
    };

    const processPayment = async () => {
        if (!customerPaymentId) return;

        setErrorMessage('');
        setIsConfirming(false);
        setIsProcessing(true);

        try {
            const result = await Services.financeApi.financeControllerPostPaymentCC({
                id: customerPaymentId,
                paymentNumber: payment.paymentNumber,
                token: stripeToken,
            });

            if (!result.data.processed) {
                setErrorMessage(result.data.extendedError || DefaultErrorMessage);
            } else {
                dispatch(loadFinancialsAsync.request({ forceReset: true }));
            }
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            Sentry.captureException(err);
            setErrorMessage(err.response?.data?.extendedError || DefaultErrorMessage);
        }

        setIsProcessing(false);
    };

    return (
        <>
            <Header>
                <PaymentTitle>Payment #{payment.paymentNumber}</PaymentTitle>
                <StripeLogo src={StripeBadge} />
            </Header>
            <PaymentTable>
                <Line>
                    <Name>Payment Amount</Name>
                    <Value>{formatter.format(payment.amountDue)}</Value>
                </Line>
                {chargeStripeFee && (
                    <>
                        <Line>
                            <Name>Processing Fee *</Name>
                            <Value>{formatter.format(payment.feesDue)}</Value>
                        </Line>
                        <Line>
                            <Name>Total</Name>
                            <Value>{formatter.format(total)}</Value>
                        </Line>
                    </>
                )}
                {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                <Elements stripe={stripePromise}>
                    <CCPaymentForm
                        total={total}
                        disableSubmit={isProcessing}
                        onTokenReceived={token => onGotToken(token)}
                    />
                </Elements>
            </PaymentTable>
            {chargeStripeFee && (
                <HelpText>
                    * When paying by credit card, there will be an approximate 3% fee added to the amount paid. This is
                    a service fee imposed by our credit card processor Stripe.
                </HelpText>
            )}
            {isConfirming && (
                <PaymentConfirmModal
                    amount={total}
                    confirmMode="cc"
                    onConfirm={() => processPayment()}
                    onCancel={() => setIsConfirming(false)}
                />
            )}
        </>
    );
}

export default PayViaCC;
