import AppTheme from '@root/AppTheme';
import tw from 'twin.macro';

const FooterLogoImg = tw.img`h-7 text-brand-muted`;

function FooterLogo() {
    return <FooterLogoImg src={AppTheme.footerLogo} />;
}

export default FooterLogo;
