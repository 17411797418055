import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Container = tw.div`h-7 m-2 px-2 py-4 flex items-center`;
const Spacer = tw.div`w-2`;
interface SkeletonMenuItemProps {
    width: string;
}

function SkeletonMenuItem({ width }: SkeletonMenuItemProps) {
    return (
        <Container>
            <SkeletonLoader width="1.5rem" height="1.5rem" />
            <Spacer />
            <SkeletonLoader width={width} height="1.25rem" />
        </Container>
    );
}

export default SkeletonMenuItem;
