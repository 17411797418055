import { Helmet } from 'react-helmet';
import tw from 'twin.macro';
import GSStages from './GSStages';
import UtilityCard from './UtilityCard';

const Page = tw.div`flex flex-wrap`;

function EnergyExchangePage() {
    return (
        <Page>
            <Helmet>
                <title>My Energy Exchange | Compass by Swell Energy</title>
            </Helmet>
            <GSStages />
            <UtilityCard />
        </Page>
    );
}

export default EnergyExchangePage;
