/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import * as Sentry from '@sentry/react';
import tw, { styled } from 'twin.macro';
import { UploadCloud } from 'react-feather';
import { useDispatch } from 'react-redux';
import { TaskDocumentDto } from '@services/api';
import ButtonStyleBase from '@components/button';
import api from '@services/index';
import s3UploadService from '@services/s3-upload-service';
import mergeIntoPDF from '@root/services/pdf-merge';
import { PendingUpload } from './view-models';
import MissingDocument from './MissingDocument';
import { loadTasksAsync } from '../actions';

const Section = tw.div`mb-12 last:mb-0`;
const Header = tw.div`flex flex-col md:flex-row lg:flex-col xl:flex-row justify-between md:items-center lg:items-stretch xl:items-center mb-4 md:mb-0 lg:mb-4 xl:mb-0`;
const SectionTitle = tw.h2`text-lg`;
const UploadButton = styled.button(() => [...ButtonStyleBase]);
const UploadIcon = tw(UploadCloud)`mr-2`;

interface Props {
    documents: TaskDocumentDto[];
}

function DocumentsRequired({ documents }: Props) {
    const dispatch = useDispatch();
    const [pendingUploads, setPendingUploads] = useState<PendingUpload[]>([]);
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const onFileSelected = (documentId: string, selectedFile?: File) => {
        const newState = [...pendingUploads.filter(d => d.documentId !== documentId)];
        const existingDoc = pendingUploads.find(d => d.documentId === documentId);

        if (selectedFile) {
            if (existingDoc && existingDoc.supportsMerging) {
                existingDoc.files.push(selectedFile);
                existingDoc.previews.push(window.URL.createObjectURL(selectedFile));
                newState.push(existingDoc);
            } else {
                const supportsMerging = selectedFile.type.startsWith('image/');
                const preview = supportsMerging ? window.URL.createObjectURL(selectedFile) : undefined;
                newState.push({
                    documentId,
                    files: [selectedFile],
                    previews: [preview],
                    supportsMerging,
                    progress: 0,
                    state: 'ready',
                });
            }
        }

        setPendingUploads(newState);
    };

    const onFileRemoved = (documentId: string, index: number) => {
        const newState = [...pendingUploads.filter(d => d.documentId !== documentId)];
        const existingDoc = pendingUploads.find(d => d.documentId === documentId);

        if (existingDoc) {
            existingDoc?.files.splice(index, 1);
            existingDoc?.previews.splice(index, 1);

            if (existingDoc.files.length > 0) {
                newState.push(existingDoc);
            }
        }

        setPendingUploads(newState);
    };

    const uploadFiles = async () => {
        setUploadInProgress(true);

        let tempState = [...pendingUploads];
        const docIds = tempState.filter(d => !!d.files[0]).map(d => d.documentId);

        docIds.forEach(id => {
            const doc = tempState.find(d => d.documentId === id);
            if (doc) doc.state = 'waiting';
        });
        setPendingUploads(tempState);

        const updateCurrentState = (currentState: PendingUpload) => {
            tempState = [...tempState.filter(d => d.documentId !== currentState.documentId), currentState];
            setPendingUploads(tempState);
        };

        const uploadFile = async (pendingUpload: PendingUpload, targetFile: File) => {
            try {
                updateCurrentState({ ...pendingUpload, state: 'preparing' });
                const uploadUrlResult = await api.documentsApi.documentsControllerGetDocumentUploadUrl(
                    pendingUpload.documentId,
                    targetFile.type,
                );

                await s3UploadService.uploadToS3(uploadUrlResult.data.url, targetFile, progress => {
                    updateCurrentState({ ...pendingUpload, state: 'uploading', progress });
                });

                updateCurrentState({ ...pendingUpload, state: 'finalizing' });
                await api.documentsApi.documentsControllerPutDocumentUrl(pendingUpload.documentId, {
                    bucket: uploadUrlResult.data.bucket,
                    objectKey: uploadUrlResult.data.objectKey,
                    originalFileName: targetFile.name,
                    fileSize: targetFile.size,
                    fileType: targetFile.type,
                });

                updateCurrentState({ ...pendingUpload, state: 'complete' });
            } catch (err) {
                Sentry.captureException(err);
                updateCurrentState({ ...pendingUpload, state: 'error' });
            }
        };

        for (let i = 0; i < docIds.length; i += 1) {
            const currentUpload = tempState.find(d => d.documentId === docIds[i]);

            if (currentUpload) {
                if (currentUpload.files.length === 1) {
                    await uploadFile(currentUpload, currentUpload.files[0]);
                } else {
                    updateCurrentState({ ...currentUpload, state: 'merging' });
                    const mergedPdf = await mergeIntoPDF(currentUpload.files);
                    const pdfFile = new File([mergedPdf], 'Merged.pdf', { type: 'application/pdf' });
                    await uploadFile(currentUpload, pdfFile);
                }
            }
        }

        setUploadInProgress(false);
        dispatch(loadTasksAsync.request());
    };

    return (
        <Section>
            <Header>
                <SectionTitle>Upload Documents</SectionTitle>
                <UploadButton disabled={pendingUploads.length === 0 || uploadInProgress} onClick={() => uploadFiles()}>
                    <UploadIcon />
                    <span>Upload Selected</span>
                </UploadButton>
            </Header>
            <div>
                {documents
                    .filter(d => !d.isUploaded)
                    .map(d => {
                        const pendingUpload = pendingUploads.find(p => p.documentId === d.id);
                        return (
                            <MissingDocument
                                key={`cmpdoc-${d.id}`}
                                document={d}
                                pendingUpload={pendingUpload}
                                onFileSelected={onFileSelected}
                                onFileRemoved={onFileRemoved}
                            />
                        );
                    })}
            </div>
        </Section>
    );
}

export default DocumentsRequired;
