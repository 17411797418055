import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { accessTokenService } from '@services/index';
import { logout, resetContext } from '../actions';

function LogOut() {
    const dispatch = useDispatch();
    const history = useHistory();

    const doLogout = async () => {
        await accessTokenService.clearToken();
        dispatch(resetContext());
        dispatch(logout());
        history.replace('/');
    };

    doLogout();

    return null;
}

export default LogOut;
