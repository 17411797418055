import { useEffect } from 'react';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { esaStatusSelector } from '@features/Financials/selectors';
import { loadESAStatusAsync } from '@features/Financials/actions';
import ESABillingSupportCard from '@features/Financials/components/ESABillingSupportCard';
import ContactCard from './ContactCard';
import SwellContactCard from './SwellContactCard';
import SkeletonContactCard from './SkeletonContactCard';
import { contactsLoadFailedSelector, contactsSelector } from '../selectors';
import { loadAsync } from '../actions';

const Page = tw.div`flex flex-wrap`;

function Contacts() {
    const dispatch = useDispatch();
    const contacts = useSelector(contactsSelector);
    const contactsLoadFailed = useSelector(contactsLoadFailedSelector);
    const esaStatus = useSelector(esaStatusSelector);

    useEffect(() => {
        dispatch(loadAsync.request());
        dispatch(loadESAStatusAsync.request());
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>Contacts | Compass by Swell Energy</title>
            </Helmet>
            {contacts && contacts.map(c => <ContactCard contact={c} key={`contact-${c.firstName}-${c.lastName}`} />)}
            {!contacts && <SkeletonContactCard loadFailed={contactsLoadFailed} />}
            <SwellContactCard />
            {!!esaStatus && <ESABillingSupportCard />}
        </Page>
    );
}

export default Contacts;
