import { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';
import { Link } from 'react-router-dom';
import { ArrowRightCircle, ExternalLink } from 'react-feather';
import Card from './Card';

const LinkButtonStyles = [tw`flex flex-col h-full`, tw`text-brand-primary hover:text-brand-dark`];

const AnchorButton = styled.a(() => LinkButtonStyles);
const LinkButton = styled(Link)(() => LinkButtonStyles);
const HtmlButton = styled.button(() => [...LinkButtonStyles, tw`hocus:outline-none block w-full text-left`]);

const ContentContainer = tw.div`flex flex-col h-full relative`;
const Title = tw.span`text-lg text-brand-black text-left`;
const Content = tw.div`flex-grow mt-2 text-brand-black`;
const NavigateCTA = tw.div`flex items-center space-x-2 mt-4`;

interface Props {
    size: 'md' | 'lg' | 'xl' | 'full';
    title?: string;
    to?: string;
    href?: string;
    onClick?: () => void;
    navigateText?: string;
}

function LinkCard({ size, title, to, href, onClick, navigateText, children }: PropsWithChildren<Props>) {
    const cardContent = (
        <>
            {title && <Title>{title}</Title>}
            <Content>{children}</Content>
        </>
    );

    if (to && navigateText) {
        return (
            <Card size={size}>
                <LinkButton to={to}>
                    {cardContent}
                    <NavigateCTA>
                        <span>{navigateText}</span>
                        <ArrowRightCircle />
                    </NavigateCTA>
                </LinkButton>
            </Card>
        );
    }
    if (href && navigateText) {
        return (
            <Card size={size}>
                <AnchorButton href={href} rel="nofollow" target="_blank">
                    {cardContent}
                    <NavigateCTA>
                        <span>{navigateText}</span>
                        <ExternalLink />
                    </NavigateCTA>
                </AnchorButton>
            </Card>
        );
    }
    if (onClick && navigateText) {
        return (
            <Card size={size}>
                <HtmlButton type="button" onClick={() => onClick()}>
                    {cardContent}
                    <NavigateCTA>
                        <span>{navigateText}</span>
                        <ArrowRightCircle />
                    </NavigateCTA>
                </HtmlButton>
            </Card>
        );
    }
    return (
        <Card size={size}>
            <ContentContainer>{cardContent}</ContentContainer>
        </Card>
    );
}

LinkCard.defaultProps = {
    title: undefined,
    to: undefined,
    href: undefined,
    onClick: undefined,
    navigateText: undefined,
};

export default LinkCard;
