import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import LinkCard from '@components/LinkCard';
import { TaskDtoStatusEnum } from '@root/services/api';
import SkeletonTimelineEntry from './SkeletonTimelineEntry';
import 'react-vertical-timeline-component/style.min.css';
import '../styles/timeline.css';
import { tasksLoadFailedSelector, tasksSelector } from '../selectors';
import { loadTasksAsync } from '../actions';
import CompletedTask from './CompletedTask';

const TimelineWrapper = tw.ul`flex flex-col`;
const Empty = tw.div`flex flex-col py-8 space-y-4 items-center`;
const EmptyText = tw.span`text-sm inline text-center text-brand-muted leading-loose`;
const ContactNav = tw(
    Link,
)`my-2 px-4 py-2 flex flex-row items-center border-b text-brand-muted hover:text-brand-black bg-brand-white hover:bg-brand-light hocus:outline-none`;

function CompletedTasks() {
    const dispatch = useDispatch();
    const tasks = useSelector(tasksSelector);
    const loadFailed = useSelector(tasksLoadFailedSelector);
    const reload = useCallback(() => dispatch(loadTasksAsync.request()), [dispatch]);

    const completed = tasks?.filter(e => e.status === TaskDtoStatusEnum.Complete);
    completed?.sort((a, b) => new Date(b.completedAt).getTime() - new Date(a.completedAt).getTime());

    return (
        <LinkCard size="lg" title="Completed Tasks">
            <TimelineWrapper>
                <>
                    {!completed && (
                        <>
                            <SkeletonTimelineEntry />
                            <SkeletonTimelineEntry />
                            <SkeletonTimelineEntry />
                            {loadFailed && <LoadFailedOverlay onRetry={reload} />}
                        </>
                    )}
                    {completed && completed.length === 0 && (
                        <Empty>
                            <EmptyText>
                                We are working hard to enable your system, check back here occasionally to see completed
                                tasks.
                            </EmptyText>
                            <ContactNav to="/contact">Have a question for us?</ContactNav>
                        </Empty>
                    )}
                    {completed && completed.length > 0 && (
                        <VerticalTimeline layout="1-column" className="vertical-timeline-custom-line">
                            {completed.map(te => (
                                <CompletedTask key={`timeline-${te.name}`} event={te} />
                            ))}
                        </VerticalTimeline>
                    )}
                </>
            </TimelineWrapper>
        </LinkCard>
    );
}

export default CompletedTasks;
