import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import getTokenData from '@util/jwt';
import PasswordComplexityRules from '@util/password-complexity';
import { checkTokenAsync, loginAsync, logout, selectContext, resetContext, didUpdatePassword } from './actions';

export const isLoading = createReducer(true as boolean)
    .handleAction(loginAsync.request, () => true)
    .handleAction([loginAsync.success, loginAsync.failure], () => false);

export const isLoggedIn = createReducer(false as boolean)
    .handleAction(loginAsync.success, (_state, action) => action.payload.success)
    .handleAction(checkTokenAsync.success, () => true)
    .handleAction([loginAsync.failure, checkTokenAsync.failure, logout], () => false);

export const passwordUpdateRequired = createReducer(false as boolean)
    .handleAction(
        loginAsync.request,
        (_state, action) => !PasswordComplexityRules.every(r => r.test(action.payload.password) === r.weight),
    )
    .handleAction(checkTokenAsync.request, (_state, action) => action.payload.isPasswordChangeRequired)
    .handleAction(didUpdatePassword, () => false);

export const contextSelectionRequired = createReducer(true as boolean)
    .handleAction(selectContext.success, () => false)
    .handleAction(resetContext, () => true);

export const hasMultipleContexts = createReducer(false as boolean).handleAction(
    [loginAsync.success, checkTokenAsync.success],
    (state, action) => {
        if (action.payload.success) {
            const tokenData = getTokenData(action.payload.authToken);
            return tokenData.scopes?.length > 1 || tokenData.scopes?.some(s => s.contexts?.length > 1);
        }
        return state;
    },
);

export const loginError = createReducer('' as string)
    .handleAction(loginAsync.request, () => '')
    .handleAction(loginAsync.failure, () => 'We ran into a temporary problem while signing in. Please try again.')
    .handleAction(loginAsync.success, (_state, action) =>
        action.payload.success ? '' : 'Invalid email address or password. Please check your credentials and try again.',
    )
    .handleAction(checkTokenAsync.failure, () => 'Your session has expired. Please sign in again.');

const loginReducer = combineReducers({
    isLoading,
    isLoggedIn,
    loginError,
    contextSelectionRequired,
    hasMultipleContexts,
    passwordUpdateRequired,
});

export default loginReducer;
export type LoginState = ReturnType<typeof loginReducer>;
