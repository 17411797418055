/* tslint:disable */
/* eslint-disable */
/**
 * Compass
 * The RESTful API used by the Swell Compass application
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessTokenDto
 */
export interface AccessTokenDto {
    /**
     * 
     * @type {string}
     * @memberof AccessTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface AppSchematicDto
 */
export interface AppSchematicDto {
    /**
     * 
     * @type {string}
     * @memberof AppSchematicDto
     */
    step: AppSchematicDtoStepEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSchematicDto
     */
    widgets: Array<AppSchematicDtoWidgetsEnum>;
    /**
     * 
     * @type {number}
     * @memberof AppSchematicDto
     */
    taskCount: number;
    /**
     * 
     * @type {number}
     * @memberof AppSchematicDto
     */
    documentCount: number;
    /**
     * 
     * @type {number}
     * @memberof AppSchematicDto
     */
    installedProductsCount: number;
}

/**
    * @export
    * @enum {string}
    */
export enum AppSchematicDtoStepEnum {
    Presales = 'Presales',
    Install = 'Install',
    Active = 'Active',
    CapacityExchangeOnly = 'CapacityExchangeOnly'
}
/**
    * @export
    * @enum {string}
    */
export enum AppSchematicDtoWidgetsEnum {
    MyHome = 'MyHome',
    Quotes = 'Quotes',
    Proposal = 'Proposal',
    Tasks = 'Tasks',
    Documents = 'Documents',
    Products = 'Products',
    ElectricInfo = 'ElectricInfo',
    ContactSalesRep = 'ContactSalesRep',
    ContactProjectCoordinator = 'ContactProjectCoordinator',
    ContactBuilder = 'ContactBuilder',
    InstalledProducts = 'InstalledProducts',
    Financials = 'Financials',
    FinanceAgreementDetails = 'FinanceAgreementDetails',
    RequestPaidInFullReceipt = 'RequestPaidInFullReceipt',
    EnergyExchange = 'EnergyExchange'
}

/**
 * 
 * @export
 * @interface ChangePasswordDto
 */
export interface ChangePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    oldPassword: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDto
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface ContactDto
 */
export interface ContactDto {
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    avatarUrl: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    orgName: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    address2: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDto
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof ContactDto
     */
    onlineNow: boolean;
}
/**
 * 
 * @export
 * @interface DemographicsDto
 */
export interface DemographicsDto {
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    address2: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    zip: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface DocumentDto
 */
export interface DocumentDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentDto
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface DocumentLinkDto
 */
export interface DocumentLinkDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentLinkDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLinkDto
     */
    fileName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLinkDto
     */
    downloadUrl: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentLinkDto
     */
    previewUrl: string;
}
/**
 * 
 * @export
 * @interface DocumentUploadDto
 */
export interface DocumentUploadDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadDto
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadDto
     */
    objectKey: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadDto
     */
    originalFileName: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadDto
     */
    fileType: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentUploadDto
     */
    fileSize: number;
}
/**
 * 
 * @export
 * @interface DocumentUploadLinkDto
 */
export interface DocumentUploadLinkDto {
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadLinkDto
     */
    bucket: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadLinkDto
     */
    objectKey: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentUploadLinkDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface ESAPaymentHistoryItemDto
 */
export interface ESAPaymentHistoryItemDto {
    /**
     * 
     * @type {number}
     * @memberof ESAPaymentHistoryItemDto
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof ESAPaymentHistoryItemDto
     */
    date: string;
}
/**
 * 
 * @export
 * @interface ESAStatusDto
 */
export interface ESAStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    nextPaymentAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    pastDueAmount: number;
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    daysDelinquent: number;
    /**
     * 
     * @type {string}
     * @memberof ESAStatusDto
     */
    nextPaymentDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof ESAStatusDto
     */
    isAccountCurrent: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESAStatusDto
     */
    isInDelinquencyOnboardingGracePeriod: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ESAStatusDto
     */
    isACHEnrolled: boolean;
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    termLengthYears: number;
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    paymentsMade: number;
    /**
     * 
     * @type {number}
     * @memberof ESAStatusDto
     */
    paymentsTotal: number;
    /**
     * 
     * @type {Array<ESAPaymentHistoryItemDto>}
     * @memberof ESAStatusDto
     */
    recentPayments: Array<ESAPaymentHistoryItemDto>;
}
/**
 * 
 * @export
 * @interface FinancingInfoDto
 */
export interface FinancingInfoDto {
    /**
     * 
     * @type {string}
     * @memberof FinancingInfoDto
     */
    source: string;
    /**
     * 
     * @type {string}
     * @memberof FinancingInfoDto
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    discount: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    adjustment: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    rebate: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    programIncentive: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    netAmount: number;
    /**
     * 
     * @type {boolean}
     * @memberof FinancingInfoDto
     */
    netIncludesRebate: boolean;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    deposit: number;
    /**
     * 
     * @type {number}
     * @memberof FinancingInfoDto
     */
    actualDepositMade: number;
    /**
     * 
     * @type {boolean}
     * @memberof FinancingInfoDto
     */
    chargeStripeFee: boolean;
    /**
     * 
     * @type {string}
     * @memberof FinancingInfoDto
     */
    customerPaymentId: string;
    /**
     * 
     * @type {Array<MilestonePaymentDto>}
     * @memberof FinancingInfoDto
     */
    milestonePayments: Array<MilestonePaymentDto>;
    /**
     * 
     * @type {string}
     * @memberof FinancingInfoDto
     */
    achStatus: FinancingInfoDtoAchStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FinancingInfoDtoAchStatusEnum {
    None = 'none',
    DepositPendingVerification = 'deposit_pending_verification',
    AccountVerified = 'account_verified'
}

/**
 * 
 * @export
 * @interface ForgotPasswordDto
 */
export interface ForgotPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordDto
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface ForgotPasswordResponseDto
 */
export interface ForgotPasswordResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ForgotPasswordResponseDto
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface InstalledProductDto
 */
export interface InstalledProductDto {
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    projectId: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    gsOpportunityId: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    productId: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    productType: string;
    /**
     * 
     * @type {number}
     * @memberof InstalledProductDto
     */
    quantity: number;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    partNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    productNotes: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof InstalledProductDto
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface InviteUserSetPasswordFromNonceDto
 */
export interface InviteUserSetPasswordFromNonceDto {
    /**
     * 
     * @type {string}
     * @memberof InviteUserSetPasswordFromNonceDto
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof InviteUserSetPasswordFromNonceDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface InviteUserSetPasswordFromNonceResponseDto
 */
export interface InviteUserSetPasswordFromNonceResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof InviteUserSetPasswordFromNonceResponseDto
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface ManualVerifyAmountsDto
 */
export interface ManualVerifyAmountsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ManualVerifyAmountsDto
     */
    amounts: Array<number>;
}
/**
 * 
 * @export
 * @interface ManualVerifyAmountsResultDto
 */
export interface ManualVerifyAmountsResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof ManualVerifyAmountsResultDto
     */
    success: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualVerifyAmountsResultDto
     */
    error?: string;
}
/**
 * 
 * @export
 * @interface ManualVerifyTokenDto
 */
export interface ManualVerifyTokenDto {
    /**
     * 
     * @type {string}
     * @memberof ManualVerifyTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface MilestonePaymentDto
 */
export interface MilestonePaymentDto {
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    amountDue: number;
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    feesDue: number;
    /**
     * 
     * @type {string}
     * @memberof MilestonePaymentDto
     */
    dueDate: string;
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    amountPaid: number;
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    feePaid: number;
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    subtotalPaid: number;
    /**
     * 
     * @type {string}
     * @memberof MilestonePaymentDto
     */
    paidDate: string;
    /**
     * 
     * @type {number}
     * @memberof MilestonePaymentDto
     */
    paymentNumber: number;
    /**
     * 
     * @type {boolean}
     * @memberof MilestonePaymentDto
     */
    canMakePayment: boolean;
    /**
     * 
     * @type {string}
     * @memberof MilestonePaymentDto
     */
    status?: MilestonePaymentDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum MilestonePaymentDtoStatusEnum {
    None = 'NONE',
    Waiting = 'WAITING',
    WaitingDone = 'WAITING_DONE'
}

/**
 * 
 * @export
 * @interface PlaidLinkPublicTokenDto
 */
export interface PlaidLinkPublicTokenDto {
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkPublicTokenDto
     */
    publicToken: string;
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkPublicTokenDto
     */
    accountId: string;
}
/**
 * 
 * @export
 * @interface PlaidLinkTokenDto
 */
export interface PlaidLinkTokenDto {
    /**
     * 
     * @type {string}
     * @memberof PlaidLinkTokenDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface PostPaymentACHDto
 */
export interface PostPaymentACHDto {
    /**
     * 
     * @type {string}
     * @memberof PostPaymentACHDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PostPaymentACHDto
     */
    paymentNumber: number;
}
/**
 * 
 * @export
 * @interface PostPaymentCCDto
 */
export interface PostPaymentCCDto {
    /**
     * 
     * @type {string}
     * @memberof PostPaymentCCDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PostPaymentCCDto
     */
    paymentNumber: number;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentCCDto
     */
    token: string;
}
/**
 * 
 * @export
 * @interface PreferencesDto
 */
export interface PreferencesDto {
    /**
     * 
     * @type {boolean}
     * @memberof PreferencesDto
     */
    allowCustomerReference: boolean;
}
/**
 * 
 * @export
 * @interface ProcessPaymentResultDto
 */
export interface ProcessPaymentResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof ProcessPaymentResultDto
     */
    processed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProcessPaymentResultDto
     */
    extendedError?: string;
}
/**
 * 
 * @export
 * @interface ProposalDto
 */
export interface ProposalDto {
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    createDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    expiry: string;
    /**
     * 
     * @type {string}
     * @memberof ProposalDto
     */
    paymentType: string;
}
/**
 * 
 * @export
 * @interface ProposalUrlDto
 */
export interface ProposalUrlDto {
    /**
     * 
     * @type {string}
     * @memberof ProposalUrlDto
     */
    url: string;
}
/**
 * 
 * @export
 * @interface SendPaidInFullStatementDto
 */
export interface SendPaidInFullStatementDto {
    /**
     * 
     * @type {string}
     * @memberof SendPaidInFullStatementDto
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @interface SetPasswordFromNonceDto
 */
export interface SetPasswordFromNonceDto {
    /**
     * 
     * @type {string}
     * @memberof SetPasswordFromNonceDto
     */
    nonce: string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordFromNonceDto
     */
    password: string;
}
/**
 * 
 * @export
 * @interface SetPasswordFromNonceResponseDto
 */
export interface SetPasswordFromNonceResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof SetPasswordFromNonceResponseDto
     */
    success: boolean;
}
/**
 * 
 * @export
 * @interface StageDto
 */
export interface StageDto {
    /**
     * 
     * @type {string}
     * @memberof StageDto
     */
    stage: string;
    /**
     * 
     * @type {string}
     * @memberof StageDto
     */
    displayName: string;
    /**
     * 
     * @type {number}
     * @memberof StageDto
     */
    order: number;
    /**
     * 
     * @type {boolean}
     * @memberof StageDto
     */
    isVisible: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StageDto
     */
    doesIndicateAbort: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StageDto
     */
    isActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StageDto
     */
    isComplete: boolean;
}
/**
 * 
 * @export
 * @interface TaskDocumentDto
 */
export interface TaskDocumentDto {
    /**
     * 
     * @type {string}
     * @memberof TaskDocumentDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDocumentDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDocumentDto
     */
    isUploaded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TaskDocumentDto
     */
    isRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof TaskDocumentDto
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface TaskDto
 */
export interface TaskDto {
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    priority: TaskDtoPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    endTime: string;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    completedAt: string;
    /**
     * 
     * @type {object}
     * @memberof TaskDto
     */
    isEvent: object;
    /**
     * 
     * @type {object}
     * @memberof TaskDto
     */
    isHomeownerAssigned: object;
    /**
     * 
     * @type {string}
     * @memberof TaskDto
     */
    status: TaskDtoStatusEnum;
    /**
     * 
     * @type {Array<TaskDocumentDto>}
     * @memberof TaskDto
     */
    documents: Array<TaskDocumentDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum TaskDtoPriorityEnum {
    Low = 'low',
    Medium = 'medium',
    High = 'high'
}
/**
    * @export
    * @enum {string}
    */
export enum TaskDtoStatusEnum {
    NotStarted = 'not_started',
    InProgress = 'in_progress',
    Delayed = 'delayed',
    Complete = 'complete',
    Archived = 'archived'
}

/**
 * 
 * @export
 * @interface UpdateDemographicsDto
 */
export interface UpdateDemographicsDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDemographicsDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDemographicsDto
     */
    phone: string;
}
/**
 * 
 * @export
 * @interface UpdatePreferencesDto
 */
export interface UpdatePreferencesDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePreferencesDto
     */
    allowCustomerReference: boolean;
}
/**
 * 
 * @export
 * @interface UtilityInfoDto
 */
export interface UtilityInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UtilityInfoDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof UtilityInfoDto
     */
    meter: number;
    /**
     * 
     * @type {number}
     * @memberof UtilityInfoDto
     */
    accountNumber: number;
    /**
     * 
     * @type {string}
     * @memberof UtilityInfoDto
     */
    programName: string;
}
/**
 * 
 * @export
 * @interface VerifySetPasswordNonceDto
 */
export interface VerifySetPasswordNonceDto {
    /**
     * 
     * @type {string}
     * @memberof VerifySetPasswordNonceDto
     */
    nonce: string;
}
/**
 * 
 * @export
 * @interface VerifySetPasswordNonceResponseDto
 */
export interface VerifySetPasswordNonceResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof VerifySetPasswordNonceResponseDto
     */
    valid: boolean;
}

/**
 * AppSchematicApi - axios parameter creator
 * @export
 */
export const AppSchematicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSchematicControllerGetAppSchematic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/appSchematic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppSchematicApi - functional programming interface
 * @export
 */
export const AppSchematicApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appSchematicControllerGetAppSchematic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppSchematicDto>> {
            const localVarAxiosArgs = await AppSchematicApiAxiosParamCreator(configuration).appSchematicControllerGetAppSchematic(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AppSchematicApi - factory interface
 * @export
 */
export const AppSchematicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appSchematicControllerGetAppSchematic(options?: any): AxiosPromise<AppSchematicDto> {
            return AppSchematicApiFp(configuration).appSchematicControllerGetAppSchematic(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppSchematicApi - object-oriented interface
 * @export
 * @class AppSchematicApi
 * @extends {BaseAPI}
 */
export class AppSchematicApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppSchematicApi
     */
    public appSchematicControllerGetAppSchematic(options?: any) {
        return AppSchematicApiFp(this.configuration).appSchematicControllerGetAppSchematic(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword: async (changePasswordDto: ChangePasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'changePasswordDto' is not null or undefined
            if (changePasswordDto === null || changePasswordDto === undefined) {
                throw new RequiredError('changePasswordDto','Required parameter changePasswordDto was null or undefined when calling authControllerChangePassword.');
            }
            const localVarPath = `/auth/changePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof changePasswordDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(changePasswordDto !== undefined ? changePasswordDto : {})
                : (changePasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/checkToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword: async (forgotPasswordDto: ForgotPasswordDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordDto' is not null or undefined
            if (forgotPasswordDto === null || forgotPasswordDto === undefined) {
                throw new RequiredError('forgotPasswordDto','Required parameter forgotPasswordDto was null or undefined when calling authControllerForgotPassword.');
            }
            const localVarPath = `/auth/forgotPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof forgotPasswordDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(forgotPasswordDto !== undefined ? forgotPasswordDto : {})
                : (forgotPasswordDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateLoginURLForUserId: async (body: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authControllerGenerateLoginURLForUserId.');
            }
            const localVarPath = `/auth/generateLoginURLForUserId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InviteUserSetPasswordFromNonceDto} inviteUserSetPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInviteUserSetPasswordFromNonce: async (inviteUserSetPasswordFromNonceDto: InviteUserSetPasswordFromNonceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inviteUserSetPasswordFromNonceDto' is not null or undefined
            if (inviteUserSetPasswordFromNonceDto === null || inviteUserSetPasswordFromNonceDto === undefined) {
                throw new RequiredError('inviteUserSetPasswordFromNonceDto','Required parameter inviteUserSetPasswordFromNonceDto was null or undefined when calling authControllerInviteUserSetPasswordFromNonce.');
            }
            const localVarPath = `/auth/inviteUserSetPasswordFromNonce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof inviteUserSetPasswordFromNonceDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(inviteUserSetPasswordFromNonceDto !== undefined ? inviteUserSetPasswordFromNonceDto : {})
                : (inviteUserSetPasswordFromNonceDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            if (loginDto === null || loginDto === undefined) {
                throw new RequiredError('loginDto','Required parameter loginDto was null or undefined when calling authControllerLogin.');
            }
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginDto !== undefined ? loginDto : {})
                : (loginDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetPasswordFromNonceDto} setPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSetPasswordFromNonce: async (setPasswordFromNonceDto: SetPasswordFromNonceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'setPasswordFromNonceDto' is not null or undefined
            if (setPasswordFromNonceDto === null || setPasswordFromNonceDto === undefined) {
                throw new RequiredError('setPasswordFromNonceDto','Required parameter setPasswordFromNonceDto was null or undefined when calling authControllerSetPasswordFromNonce.');
            }
            const localVarPath = `/auth/setPasswordFromNonce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof setPasswordFromNonceDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(setPasswordFromNonceDto !== undefined ? setPasswordFromNonceDto : {})
                : (setPasswordFromNonceDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifySetPasswordNonceDto} verifySetPasswordNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifySetPasswordNonce: async (verifySetPasswordNonceDto: VerifySetPasswordNonceDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifySetPasswordNonceDto' is not null or undefined
            if (verifySetPasswordNonceDto === null || verifySetPasswordNonceDto === undefined) {
                throw new RequiredError('verifySetPasswordNonceDto','Required parameter verifySetPasswordNonceDto was null or undefined when calling authControllerVerifySetPasswordNonce.');
            }
            const localVarPath = `/auth/verifySetPasswordNonce`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof verifySetPasswordNonceDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(verifySetPasswordNonceDto !== undefined ? verifySetPasswordNonceDto : {})
                : (verifySetPasswordNonceDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerChangePassword(changePasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerCheckToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerCheckToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgotPasswordResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerForgotPassword(forgotPasswordDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerGenerateLoginURLForUserId(body: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerGenerateLoginURLForUserId(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InviteUserSetPasswordFromNonceDto} inviteUserSetPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto: InviteUserSetPasswordFromNonceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteUserSetPasswordFromNonceResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccessTokenDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerLogin(loginDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SetPasswordFromNonceDto} setPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerSetPasswordFromNonce(setPasswordFromNonceDto: SetPasswordFromNonceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetPasswordFromNonceResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerSetPasswordFromNonce(setPasswordFromNonceDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {VerifySetPasswordNonceDto} verifySetPasswordNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto: VerifySetPasswordNonceDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerifySetPasswordNonceResponseDto>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} changePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).authControllerChangePassword(changePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerCheckToken(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).authControllerCheckToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForgotPasswordDto} forgotPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any): AxiosPromise<ForgotPasswordResponseDto> {
            return AuthApiFp(configuration).authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerGenerateLoginURLForUserId(body: object, options?: any): AxiosPromise<object> {
            return AuthApiFp(configuration).authControllerGenerateLoginURLForUserId(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InviteUserSetPasswordFromNonceDto} inviteUserSetPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto: InviteUserSetPasswordFromNonceDto, options?: any): AxiosPromise<InviteUserSetPasswordFromNonceResponseDto> {
            return AuthApiFp(configuration).authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<AccessTokenDto> {
            return AuthApiFp(configuration).authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetPasswordFromNonceDto} setPasswordFromNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerSetPasswordFromNonce(setPasswordFromNonceDto: SetPasswordFromNonceDto, options?: any): AxiosPromise<SetPasswordFromNonceResponseDto> {
            return AuthApiFp(configuration).authControllerSetPasswordFromNonce(setPasswordFromNonceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifySetPasswordNonceDto} verifySetPasswordNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto: VerifySetPasswordNonceDto, options?: any): AxiosPromise<VerifySetPasswordNonceResponseDto> {
            return AuthApiFp(configuration).authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordDto} changePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(changePasswordDto: ChangePasswordDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerChangePassword(changePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerCheckToken(options?: any) {
        return AuthApiFp(this.configuration).authControllerCheckToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForgotPasswordDto} forgotPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerForgotPassword(forgotPasswordDto: ForgotPasswordDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerForgotPassword(forgotPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerGenerateLoginURLForUserId(body: object, options?: any) {
        return AuthApiFp(this.configuration).authControllerGenerateLoginURLForUserId(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InviteUserSetPasswordFromNonceDto} inviteUserSetPasswordFromNonceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto: InviteUserSetPasswordFromNonceDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerInviteUserSetPasswordFromNonce(inviteUserSetPasswordFromNonceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginDto: LoginDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetPasswordFromNonceDto} setPasswordFromNonceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerSetPasswordFromNonce(setPasswordFromNonceDto: SetPasswordFromNonceDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerSetPasswordFromNonce(setPasswordFromNonceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifySetPasswordNonceDto} verifySetPasswordNonceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto: VerifySetPasswordNonceDto, options?: any) {
        return AuthApiFp(this.configuration).authControllerVerifySetPasswordNonce(verifySetPasswordNonceDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthControllerGetHealth(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).healthControllerGetHealth(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthControllerGetHealth(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).healthControllerGetHealth(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthControllerGetHealth(options?: any) {
        return DefaultApiFp(this.configuration).healthControllerGetHealth(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemographicsApi - axios parameter creator
 * @export
 */
export const DemographicsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerGetDemographics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/demographics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerGetPreferences: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/demographics/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDemographicsDto} updateDemographicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerUpdateDemographics: async (updateDemographicsDto: UpdateDemographicsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDemographicsDto' is not null or undefined
            if (updateDemographicsDto === null || updateDemographicsDto === undefined) {
                throw new RequiredError('updateDemographicsDto','Required parameter updateDemographicsDto was null or undefined when calling demographicsControllerUpdateDemographics.');
            }
            const localVarPath = `/demographics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updateDemographicsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updateDemographicsDto !== undefined ? updateDemographicsDto : {})
                : (updateDemographicsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePreferencesDto} updatePreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerUpdatePreferences: async (updatePreferencesDto: UpdatePreferencesDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePreferencesDto' is not null or undefined
            if (updatePreferencesDto === null || updatePreferencesDto === undefined) {
                throw new RequiredError('updatePreferencesDto','Required parameter updatePreferencesDto was null or undefined when calling demographicsControllerUpdatePreferences.');
            }
            const localVarPath = `/demographics/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof updatePreferencesDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(updatePreferencesDto !== undefined ? updatePreferencesDto : {})
                : (updatePreferencesDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemographicsApi - functional programming interface
 * @export
 */
export const DemographicsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographicsControllerGetDemographics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsDto>> {
            const localVarAxiosArgs = await DemographicsApiAxiosParamCreator(configuration).demographicsControllerGetDemographics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographicsControllerGetPreferences(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesDto>> {
            const localVarAxiosArgs = await DemographicsApiAxiosParamCreator(configuration).demographicsControllerGetPreferences(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdateDemographicsDto} updateDemographicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographicsControllerUpdateDemographics(updateDemographicsDto: UpdateDemographicsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemographicsDto>> {
            const localVarAxiosArgs = await DemographicsApiAxiosParamCreator(configuration).demographicsControllerUpdateDemographics(updateDemographicsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {UpdatePreferencesDto} updatePreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographicsControllerUpdatePreferences(updatePreferencesDto: UpdatePreferencesDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreferencesDto>> {
            const localVarAxiosArgs = await DemographicsApiAxiosParamCreator(configuration).demographicsControllerUpdatePreferences(updatePreferencesDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DemographicsApi - factory interface
 * @export
 */
export const DemographicsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerGetDemographics(options?: any): AxiosPromise<DemographicsDto> {
            return DemographicsApiFp(configuration).demographicsControllerGetDemographics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerGetPreferences(options?: any): AxiosPromise<PreferencesDto> {
            return DemographicsApiFp(configuration).demographicsControllerGetPreferences(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDemographicsDto} updateDemographicsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerUpdateDemographics(updateDemographicsDto: UpdateDemographicsDto, options?: any): AxiosPromise<DemographicsDto> {
            return DemographicsApiFp(configuration).demographicsControllerUpdateDemographics(updateDemographicsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePreferencesDto} updatePreferencesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographicsControllerUpdatePreferences(updatePreferencesDto: UpdatePreferencesDto, options?: any): AxiosPromise<PreferencesDto> {
            return DemographicsApiFp(configuration).demographicsControllerUpdatePreferences(updatePreferencesDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemographicsApi - object-oriented interface
 * @export
 * @class DemographicsApi
 * @extends {BaseAPI}
 */
export class DemographicsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public demographicsControllerGetDemographics(options?: any) {
        return DemographicsApiFp(this.configuration).demographicsControllerGetDemographics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public demographicsControllerGetPreferences(options?: any) {
        return DemographicsApiFp(this.configuration).demographicsControllerGetPreferences(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDemographicsDto} updateDemographicsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public demographicsControllerUpdateDemographics(updateDemographicsDto: UpdateDemographicsDto, options?: any) {
        return DemographicsApiFp(this.configuration).demographicsControllerUpdateDemographics(updateDemographicsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePreferencesDto} updatePreferencesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemographicsApi
     */
    public demographicsControllerUpdatePreferences(updatePreferencesDto: UpdatePreferencesDto, options?: any) {
        return DemographicsApiFp(this.configuration).demographicsControllerUpdatePreferences(updatePreferencesDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentLink: async (documentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling documentsControllerGetDocumentLink.');
            }
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentUploadUrl: async (documentId: string, fileType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling documentsControllerGetDocumentUploadUrl.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling documentsControllerGetDocumentUploadUrl.');
            }
            const localVarPath = `/documents/{documentId}/uploadUrl`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['fileType'] = fileType;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocuments: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentUploadDto} documentUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerPutDocumentUrl: async (documentId: string, documentUploadDto: DocumentUploadDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentId' is not null or undefined
            if (documentId === null || documentId === undefined) {
                throw new RequiredError('documentId','Required parameter documentId was null or undefined when calling documentsControllerPutDocumentUrl.');
            }
            // verify required parameter 'documentUploadDto' is not null or undefined
            if (documentUploadDto === null || documentUploadDto === undefined) {
                throw new RequiredError('documentUploadDto','Required parameter documentUploadDto was null or undefined when calling documentsControllerPutDocumentUrl.');
            }
            const localVarPath = `/documents/{documentId}`
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof documentUploadDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(documentUploadDto !== undefined ? documentUploadDto : {})
                : (documentUploadDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetDocumentLink(documentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentLinkDto>> {
            const localVarAxiosArgs = await DocumentsApiAxiosParamCreator(configuration).documentsControllerGetDocumentLink(documentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetDocumentUploadUrl(documentId: string, fileType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentUploadLinkDto>> {
            const localVarAxiosArgs = await DocumentsApiAxiosParamCreator(configuration).documentsControllerGetDocumentUploadUrl(documentId, fileType, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerGetDocuments(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDto>>> {
            const localVarAxiosArgs = await DocumentsApiAxiosParamCreator(configuration).documentsControllerGetDocuments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentUploadDto} documentUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentsControllerPutDocumentUrl(documentId: string, documentUploadDto: DocumentUploadDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DocumentsApiAxiosParamCreator(configuration).documentsControllerPutDocumentUrl(documentId, documentUploadDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentLink(documentId: string, options?: any): AxiosPromise<DocumentLinkDto> {
            return DocumentsApiFp(configuration).documentsControllerGetDocumentLink(documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {string} fileType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocumentUploadUrl(documentId: string, fileType: string, options?: any): AxiosPromise<DocumentUploadLinkDto> {
            return DocumentsApiFp(configuration).documentsControllerGetDocumentUploadUrl(documentId, fileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerGetDocuments(options?: any): AxiosPromise<Array<DocumentDto>> {
            return DocumentsApiFp(configuration).documentsControllerGetDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} documentId 
         * @param {DocumentUploadDto} documentUploadDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentsControllerPutDocumentUrl(documentId: string, documentUploadDto: DocumentUploadDto, options?: any): AxiosPromise<void> {
            return DocumentsApiFp(configuration).documentsControllerPutDocumentUrl(documentId, documentUploadDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {string} documentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsControllerGetDocumentLink(documentId: string, options?: any) {
        return DocumentsApiFp(this.configuration).documentsControllerGetDocumentLink(documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {string} fileType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsControllerGetDocumentUploadUrl(documentId: string, fileType: string, options?: any) {
        return DocumentsApiFp(this.configuration).documentsControllerGetDocumentUploadUrl(documentId, fileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsControllerGetDocuments(options?: any) {
        return DocumentsApiFp(this.configuration).documentsControllerGetDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} documentId 
     * @param {DocumentUploadDto} documentUploadDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public documentsControllerPutDocumentUrl(documentId: string, documentUploadDto: DocumentUploadDto, options?: any) {
        return DocumentsApiFp(this.configuration).documentsControllerPutDocumentUrl(documentId, documentUploadDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FinanceApi - axios parameter creator
 * @export
 */
export const FinanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerDeleteStoredACHInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/finance/storedACH`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetESAStatus: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/finance/esaStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetFinancing: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/finance/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetPlaidLinkToken: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/finance/linkToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualVerifyAmountsDto} manualVerifyAmountsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostManualVerifyAmounts: async (manualVerifyAmountsDto: ManualVerifyAmountsDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualVerifyAmountsDto' is not null or undefined
            if (manualVerifyAmountsDto === null || manualVerifyAmountsDto === undefined) {
                throw new RequiredError('manualVerifyAmountsDto','Required parameter manualVerifyAmountsDto was null or undefined when calling financeControllerPostManualVerifyAmounts.');
            }
            const localVarPath = `/finance/manualVerifyAmounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof manualVerifyAmountsDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(manualVerifyAmountsDto !== undefined ? manualVerifyAmountsDto : {})
                : (manualVerifyAmountsDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualVerifyTokenDto} manualVerifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostManualVerifyToken: async (manualVerifyTokenDto: ManualVerifyTokenDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualVerifyTokenDto' is not null or undefined
            if (manualVerifyTokenDto === null || manualVerifyTokenDto === undefined) {
                throw new RequiredError('manualVerifyTokenDto','Required parameter manualVerifyTokenDto was null or undefined when calling financeControllerPostManualVerifyToken.');
            }
            const localVarPath = `/finance/manualVerifyToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof manualVerifyTokenDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(manualVerifyTokenDto !== undefined ? manualVerifyTokenDto : {})
                : (manualVerifyTokenDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPaymentACHDto} postPaymentACHDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPaymentACH: async (postPaymentACHDto: PostPaymentACHDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPaymentACHDto' is not null or undefined
            if (postPaymentACHDto === null || postPaymentACHDto === undefined) {
                throw new RequiredError('postPaymentACHDto','Required parameter postPaymentACHDto was null or undefined when calling financeControllerPostPaymentACH.');
            }
            const localVarPath = `/finance/paymentACH`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof postPaymentACHDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(postPaymentACHDto !== undefined ? postPaymentACHDto : {})
                : (postPaymentACHDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPaymentCCDto} postPaymentCCDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPaymentCC: async (postPaymentCCDto: PostPaymentCCDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPaymentCCDto' is not null or undefined
            if (postPaymentCCDto === null || postPaymentCCDto === undefined) {
                throw new RequiredError('postPaymentCCDto','Required parameter postPaymentCCDto was null or undefined when calling financeControllerPostPaymentCC.');
            }
            const localVarPath = `/finance/paymentCC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof postPaymentCCDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(postPaymentCCDto !== undefined ? postPaymentCCDto : {})
                : (postPaymentCCDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PlaidLinkPublicTokenDto} plaidLinkPublicTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPlaidLinkPublicToken: async (plaidLinkPublicTokenDto: PlaidLinkPublicTokenDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'plaidLinkPublicTokenDto' is not null or undefined
            if (plaidLinkPublicTokenDto === null || plaidLinkPublicTokenDto === undefined) {
                throw new RequiredError('plaidLinkPublicTokenDto','Required parameter plaidLinkPublicTokenDto was null or undefined when calling financeControllerPostPlaidLinkPublicToken.');
            }
            const localVarPath = `/finance/publicToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof plaidLinkPublicTokenDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(plaidLinkPublicTokenDto !== undefined ? plaidLinkPublicTokenDto : {})
                : (plaidLinkPublicTokenDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendPaidInFullStatementDto} sendPaidInFullStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostSendPaidInFullStatement: async (sendPaidInFullStatementDto: SendPaidInFullStatementDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendPaidInFullStatementDto' is not null or undefined
            if (sendPaidInFullStatementDto === null || sendPaidInFullStatementDto === undefined) {
                throw new RequiredError('sendPaidInFullStatementDto','Required parameter sendPaidInFullStatementDto was null or undefined when calling financeControllerPostSendPaidInFullStatement.');
            }
            const localVarPath = `/finance/sendPIFStatement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof sendPaidInFullStatementDto !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(sendPaidInFullStatementDto !== undefined ? sendPaidInFullStatementDto : {})
                : (sendPaidInFullStatementDto || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinanceApi - functional programming interface
 * @export
 */
export const FinanceApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerDeleteStoredACHInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancingInfoDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerDeleteStoredACHInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerGetESAStatus(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ESAStatusDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerGetESAStatus(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerGetFinancing(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinancingInfoDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerGetFinancing(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerGetPlaidLinkToken(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PlaidLinkTokenDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerGetPlaidLinkToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ManualVerifyAmountsDto} manualVerifyAmountsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto: ManualVerifyAmountsDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualVerifyAmountsResultDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ManualVerifyTokenDto} manualVerifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostManualVerifyToken(manualVerifyTokenDto: ManualVerifyTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostManualVerifyToken(manualVerifyTokenDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PostPaymentACHDto} postPaymentACHDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostPaymentACH(postPaymentACHDto: PostPaymentACHDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessPaymentResultDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostPaymentACH(postPaymentACHDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PostPaymentCCDto} postPaymentCCDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostPaymentCC(postPaymentCCDto: PostPaymentCCDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProcessPaymentResultDto>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostPaymentCC(postPaymentCCDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {PlaidLinkPublicTokenDto} plaidLinkPublicTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto: PlaidLinkPublicTokenDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SendPaidInFullStatementDto} sendPaidInFullStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto: SendPaidInFullStatementDto, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await FinanceApiAxiosParamCreator(configuration).financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FinanceApi - factory interface
 * @export
 */
export const FinanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerDeleteStoredACHInfo(options?: any): AxiosPromise<FinancingInfoDto> {
            return FinanceApiFp(configuration).financeControllerDeleteStoredACHInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetESAStatus(options?: any): AxiosPromise<ESAStatusDto> {
            return FinanceApiFp(configuration).financeControllerGetESAStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetFinancing(options?: any): AxiosPromise<FinancingInfoDto> {
            return FinanceApiFp(configuration).financeControllerGetFinancing(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerGetPlaidLinkToken(options?: any): AxiosPromise<PlaidLinkTokenDto> {
            return FinanceApiFp(configuration).financeControllerGetPlaidLinkToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualVerifyAmountsDto} manualVerifyAmountsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto: ManualVerifyAmountsDto, options?: any): AxiosPromise<ManualVerifyAmountsResultDto> {
            return FinanceApiFp(configuration).financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualVerifyTokenDto} manualVerifyTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostManualVerifyToken(manualVerifyTokenDto: ManualVerifyTokenDto, options?: any): AxiosPromise<void> {
            return FinanceApiFp(configuration).financeControllerPostManualVerifyToken(manualVerifyTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostPaymentACHDto} postPaymentACHDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPaymentACH(postPaymentACHDto: PostPaymentACHDto, options?: any): AxiosPromise<ProcessPaymentResultDto> {
            return FinanceApiFp(configuration).financeControllerPostPaymentACH(postPaymentACHDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostPaymentCCDto} postPaymentCCDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPaymentCC(postPaymentCCDto: PostPaymentCCDto, options?: any): AxiosPromise<ProcessPaymentResultDto> {
            return FinanceApiFp(configuration).financeControllerPostPaymentCC(postPaymentCCDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PlaidLinkPublicTokenDto} plaidLinkPublicTokenDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto: PlaidLinkPublicTokenDto, options?: any): AxiosPromise<void> {
            return FinanceApiFp(configuration).financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SendPaidInFullStatementDto} sendPaidInFullStatementDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto: SendPaidInFullStatementDto, options?: any): AxiosPromise<void> {
            return FinanceApiFp(configuration).financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinanceApi - object-oriented interface
 * @export
 * @class FinanceApi
 * @extends {BaseAPI}
 */
export class FinanceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerDeleteStoredACHInfo(options?: any) {
        return FinanceApiFp(this.configuration).financeControllerDeleteStoredACHInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerGetESAStatus(options?: any) {
        return FinanceApiFp(this.configuration).financeControllerGetESAStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerGetFinancing(options?: any) {
        return FinanceApiFp(this.configuration).financeControllerGetFinancing(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerGetPlaidLinkToken(options?: any) {
        return FinanceApiFp(this.configuration).financeControllerGetPlaidLinkToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualVerifyAmountsDto} manualVerifyAmountsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto: ManualVerifyAmountsDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostManualVerifyAmounts(manualVerifyAmountsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualVerifyTokenDto} manualVerifyTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostManualVerifyToken(manualVerifyTokenDto: ManualVerifyTokenDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostManualVerifyToken(manualVerifyTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostPaymentACHDto} postPaymentACHDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostPaymentACH(postPaymentACHDto: PostPaymentACHDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostPaymentACH(postPaymentACHDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostPaymentCCDto} postPaymentCCDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostPaymentCC(postPaymentCCDto: PostPaymentCCDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostPaymentCC(postPaymentCCDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PlaidLinkPublicTokenDto} plaidLinkPublicTokenDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto: PlaidLinkPublicTokenDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostPlaidLinkPublicToken(plaidLinkPublicTokenDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SendPaidInFullStatementDto} sendPaidInFullStatementDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceApi
     */
    public financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto: SendPaidInFullStatementDto, options?: any) {
        return FinanceApiFp(this.configuration).financeControllerPostSendPaidInFullStatement(sendPaidInFullStatementDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OnboardingApi - axios parameter creator
 * @export
 */
export const OnboardingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetContacts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/onboarding/contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetGridServicesStages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/onboarding/gs-stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetStages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/onboarding/stages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetTasks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/onboarding/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingApi - functional programming interface
 * @export
 */
export const OnboardingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingControllerGetContacts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContactDto>>> {
            const localVarAxiosArgs = await OnboardingApiAxiosParamCreator(configuration).onboardingControllerGetContacts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingControllerGetGridServicesStages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StageDto>>> {
            const localVarAxiosArgs = await OnboardingApiAxiosParamCreator(configuration).onboardingControllerGetGridServicesStages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingControllerGetStages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StageDto>>> {
            const localVarAxiosArgs = await OnboardingApiAxiosParamCreator(configuration).onboardingControllerGetStages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async onboardingControllerGetTasks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskDto>>> {
            const localVarAxiosArgs = await OnboardingApiAxiosParamCreator(configuration).onboardingControllerGetTasks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OnboardingApi - factory interface
 * @export
 */
export const OnboardingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetContacts(options?: any): AxiosPromise<Array<ContactDto>> {
            return OnboardingApiFp(configuration).onboardingControllerGetContacts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetGridServicesStages(options?: any): AxiosPromise<Array<StageDto>> {
            return OnboardingApiFp(configuration).onboardingControllerGetGridServicesStages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetStages(options?: any): AxiosPromise<Array<StageDto>> {
            return OnboardingApiFp(configuration).onboardingControllerGetStages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        onboardingControllerGetTasks(options?: any): AxiosPromise<Array<TaskDto>> {
            return OnboardingApiFp(configuration).onboardingControllerGetTasks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OnboardingApi - object-oriented interface
 * @export
 * @class OnboardingApi
 * @extends {BaseAPI}
 */
export class OnboardingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingControllerGetContacts(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingControllerGetContacts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingControllerGetGridServicesStages(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingControllerGetGridServicesStages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingControllerGetStages(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingControllerGetStages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingApi
     */
    public onboardingControllerGetTasks(options?: any) {
        return OnboardingApiFp(this.configuration).onboardingControllerGetTasks(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetInstalledProducts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsControllerGetInstalledProducts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstalledProductDto>>> {
            const localVarAxiosArgs = await ProductsApiAxiosParamCreator(configuration).productsControllerGetInstalledProducts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsControllerGetInstalledProducts(options?: any): AxiosPromise<Array<InstalledProductDto>> {
            return ProductsApiFp(configuration).productsControllerGetInstalledProducts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsControllerGetInstalledProducts(options?: any) {
        return ProductsApiFp(this.configuration).productsControllerGetInstalledProducts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProposalsApi - axios parameter creator
 * @export
 */
export const ProposalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalsControllerGetProposalUrl: async (proposalId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'proposalId' is not null or undefined
            if (proposalId === null || proposalId === undefined) {
                throw new RequiredError('proposalId','Required parameter proposalId was null or undefined when calling proposalsControllerGetProposalUrl.');
            }
            const localVarPath = `/proposals/{proposalId}`
                .replace(`{${"proposalId"}}`, encodeURIComponent(String(proposalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalsControllerGetProposals: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/proposals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProposalsApi - functional programming interface
 * @export
 */
export const ProposalsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalsControllerGetProposalUrl(proposalId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProposalUrlDto>> {
            const localVarAxiosArgs = await ProposalsApiAxiosParamCreator(configuration).proposalsControllerGetProposalUrl(proposalId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposalsControllerGetProposals(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProposalDto>>> {
            const localVarAxiosArgs = await ProposalsApiAxiosParamCreator(configuration).proposalsControllerGetProposals(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ProposalsApi - factory interface
 * @export
 */
export const ProposalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} proposalId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalsControllerGetProposalUrl(proposalId: string, options?: any): AxiosPromise<ProposalUrlDto> {
            return ProposalsApiFp(configuration).proposalsControllerGetProposalUrl(proposalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposalsControllerGetProposals(options?: any): AxiosPromise<Array<ProposalDto>> {
            return ProposalsApiFp(configuration).proposalsControllerGetProposals(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProposalsApi - object-oriented interface
 * @export
 * @class ProposalsApi
 * @extends {BaseAPI}
 */
export class ProposalsApi extends BaseAPI {
    /**
     * 
     * @param {string} proposalId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalsApi
     */
    public proposalsControllerGetProposalUrl(proposalId: string, options?: any) {
        return ProposalsApiFp(this.configuration).proposalsControllerGetProposalUrl(proposalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProposalsApi
     */
    public proposalsControllerGetProposals(options?: any) {
        return ProposalsApiFp(this.configuration).proposalsControllerGetProposals(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UtilityApi - axios parameter creator
 * @export
 */
export const UtilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/utility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? await configuration.accessToken()
                    : await configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtilityApi - functional programming interface
 * @export
 */
export const UtilityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async utilityControllerGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UtilityInfoDto>> {
            const localVarAxiosArgs = await UtilityApiAxiosParamCreator(configuration).utilityControllerGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UtilityApi - factory interface
 * @export
 */
export const UtilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        utilityControllerGet(options?: any): AxiosPromise<UtilityInfoDto> {
            return UtilityApiFp(configuration).utilityControllerGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UtilityApi - object-oriented interface
 * @export
 * @class UtilityApi
 * @extends {BaseAPI}
 */
export class UtilityApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtilityApi
     */
    public utilityControllerGet(options?: any) {
        return UtilityApiFp(this.configuration).utilityControllerGet(options).then((request) => request(this.axios, this.basePath));
    }
}


