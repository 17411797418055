import { useCallback } from 'react';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import LinkCard from '@components/LinkCard';
import { TaskDtoStatusEnum } from '@root/services/api';
import SkeletonTodoEntry from './SkeletonTodoEntry';
import { NextUpSort, tasksLoadFailedSelector, tasksSelector } from '../selectors';
import { loadTasksAsync } from '../actions';
import IncompleteTask from './IncompleteTask';

const TodosWrapper = tw.ul`flex flex-col space-y-8`;
const TodoEmpty = tw.div`flex flex-col py-8 space-y-4 items-center`;
const EmptyText = tw.span`text-sm text-center text-brand-muted leading-loose`;
const ContactNav = tw(
    Link,
)`my-2 px-4 py-2 flex flex-row items-center border-b text-brand-muted hover:text-brand-black bg-brand-white hover:bg-brand-light hocus:outline-none`;

function IncompleteTasks() {
    const dispatch = useDispatch();
    const tasks = useSelector(tasksSelector);
    const loadFailed = useSelector(tasksLoadFailedSelector);
    const reload = useCallback(() => dispatch(loadTasksAsync.request()), [dispatch]);

    const incomplete = tasks?.filter(e => e.status !== TaskDtoStatusEnum.Complete).sort(NextUpSort);

    return (
        <LinkCard size="lg" title="Open Tasks">
            <>
                {incomplete && incomplete.length === 0 && (
                    <TodoEmpty>
                        <EmptyText>Right now, we have everything we need from you.</EmptyText>
                        <ContactNav to="/contact">Have a question for us?</ContactNav>
                    </TodoEmpty>
                )}
                <TodosWrapper>
                    {!incomplete && (
                        <>
                            <SkeletonTodoEntry />
                            <SkeletonTodoEntry />
                            <SkeletonTodoEntry />
                            {loadFailed && <LoadFailedOverlay onRetry={reload} />}
                        </>
                    )}
                    {incomplete?.map(v => (
                        <IncompleteTask event={v} key={`todo-${v.id}`} />
                    ))}
                </TodosWrapper>
            </>
        </LinkCard>
    );
}

export default IncompleteTasks;
