import { useEffect } from 'react';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import LinkCard from '@components/LinkCard';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import { tasksSelector, tasksLoadFailedSelector, nextUpSelector } from '@features/Tasks/selectors';
import { loadTasksAsync } from '@features/Tasks/actions';

const TimelineEvent = tw.div`flex flex-col border-l-4 pl-1 border-brand-primary`;
const Name = tw.span`text-brand-black`;
const SkeletonRow = tw.div`my-1`;

function TimelineCard() {
    const dispatch = useDispatch();
    const tasks = useSelector(tasksSelector);
    const tasksLoadFailed = useSelector(tasksLoadFailedSelector);
    const upcomingEvent = useSelector(nextUpSelector);

    useEffect(() => {
        dispatch(loadTasksAsync.request());
    }, [dispatch]);

    return (
        <LinkCard size="md" to="/tasks" title="Next Up" navigateText="View project tasks">
            {upcomingEvent && (
                <TimelineEvent>
                    <Name>{upcomingEvent.name}</Name>
                </TimelineEvent>
            )}
            {!upcomingEvent && !!tasks && (
                <span tw="text-brand-black">Good news! You have no upcoming events at this time.</span>
            )}
            {!upcomingEvent && !tasks && (
                <>
                    <SkeletonRow>
                        <SkeletonLoader width="16.0rem" height="1.2rem" />
                    </SkeletonRow>
                    <SkeletonRow>
                        <SkeletonLoader width="10.0rem" height="1.2rem" />
                    </SkeletonRow>
                    <SkeletonRow>
                        <SkeletonLoader width="12.0rem" height="1.2rem" />
                    </SkeletonRow>
                    <SkeletonRow>
                        <SkeletonLoader width="7.0rem" height="1.2rem" />
                    </SkeletonRow>
                    {tasksLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadTasksAsync.request())} />}
                </>
            )}
        </LinkCard>
    );
}

export default TimelineCard;
