import * as Feather from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from 'twin.macro';
import { Api } from '@services/index';
import { hasMultipleContextsSelector } from '@features/Auth/selectors';
import { esaStatusSelector } from '@features/Financials/selectors';
import { resetContext } from '@features/Auth/actions';
import { Badge, ButtonMenuItem, ItemText, MenuItem, MenuSpacer } from './Common';
import TasksMenuItem from './TasksMenuItem';
import SkeletonMenuItem from './SkeletonMenuItem';
import { summarySelector, widgetsSelector } from '../selectors';
import ProposalMenuItem from './ProposalMenuItem';

interface Props {
    onItemSelected: () => void;
}

function DynamicMenu({ onItemSelected }: Props) {
    const dispatch = useDispatch();
    const widgets = useSelector(widgetsSelector);
    const summary = useSelector(summarySelector);
    const hasMultipleContexts = useSelector(hasMultipleContextsSelector);
    const esaStatus = useSelector(esaStatusSelector);
    const isESAInDelinquency =
        esaStatus && !esaStatus.isAccountCurrent && !esaStatus.isInDelinquencyOnboardingGracePeriod;

    const exitContext = () => {
        dispatch(resetContext());
    };

    return (
        <>
            <MenuItem to="/" exact onClick={() => onItemSelected()}>
                <Feather.Home /> <ItemText>Home</ItemText>
            </MenuItem>
            {widgets.length === 0 && (
                <>
                    <SkeletonMenuItem width="10.0rem" />
                    <SkeletonMenuItem width="7.5rem" />
                    <SkeletonMenuItem width="6.0rem" />
                    <SkeletonMenuItem width="9.0rem" />
                </>
            )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.Proposal) && (
                <ProposalMenuItem onItemSelected={onItemSelected} />
            )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.Tasks) && (
                <TasksMenuItem onItemSelected={() => onItemSelected()} />
            )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.Documents) &&
                typeof summary?.documentCount !== 'undefined' && (
                    <MenuItem to="/documents" onClick={() => onItemSelected()}>
                        <Feather.Folder /> <ItemText>Documents</ItemText> <Badge>{summary.documentCount}</Badge>
                    </MenuItem>
                )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.Financials) && (
                <MenuItem to="/finance" onClick={() => onItemSelected()}>
                    <Feather.DollarSign /> <ItemText>Financials</ItemText>
                </MenuItem>
            )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.FinanceAgreementDetails) && (
                <MenuItem to="/finance-agreement" onClick={() => onItemSelected()}>
                    {isESAInDelinquency ? (
                        <Feather.AlertTriangle color={theme`colors.input-error`} />
                    ) : (
                        <Feather.DollarSign />
                    )}{' '}
                    <ItemText>My Account</ItemText>
                </MenuItem>
            )}
            {widgets.includes(Api.AppSchematicDtoWidgetsEnum.EnergyExchange) && (
                <MenuItem to="/energy-exchange" onClick={() => onItemSelected()}>
                    <Feather.BatteryCharging /> <ItemText>Energy Exchange</ItemText>
                </MenuItem>
            )}
            <MenuItem to="/my-profile" onClick={() => onItemSelected()}>
                <Feather.User /> <ItemText>My Profile</ItemText>
            </MenuItem>
            {widgets.some(v => v.startsWith('Contact')) && (
                <MenuItem to="/contact" onClick={() => onItemSelected()}>
                    <Feather.HelpCircle /> <ItemText>Contact Us</ItemText>
                </MenuItem>
            )}
            <MenuSpacer />
            <MenuSpacer />
            {hasMultipleContexts && (
                <ButtonMenuItem onClick={() => exitContext()}>
                    <Feather.CornerDownLeft /> <ItemText>Switch Projects</ItemText>
                </ButtonMenuItem>
            )}
            <MenuSpacer />
            <MenuItem to="/log-out" onClick={() => onItemSelected()}>
                <Feather.LogOut /> <ItemText>Sign Out</ItemText>
            </MenuItem>
        </>
    );
}

export default DynamicMenu;
