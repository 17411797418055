import tw from 'twin.macro';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Helmet } from 'react-helmet';
import Card from '@components/Card';
import { TaskDtoStatusEnum } from '@services/api';
import DocumentsList from './DocumentsList';
import SkeletonTodoPage from './SkeletonTodoPage';
import { tasksSelector, todoItemSelector } from '../selectors';

const TodoCard = tw(Card)`flex flex-col lg:flex-row`;
const Half = tw.div`w-full flex flex-col mb-12 last:mb-0 lg:mb-0 lg:mr-4 last:lg:mr-0`;
const Name = tw.span`text-lg text-brand-black`;
const StatusText = tw.span`text-xs text-brand-muted flex-grow`;
const SecondaryData = tw.div`flex flex-row`;
const Description = tw.span`text-brand-black mt-3 text-sm leading-relaxed`;
const Date = tw.span`text-brand-black`;
const BoldDate = tw.strong`text-brand-black`;

interface MatchParams {
    taskId: string;
}

type Props = RouteComponentProps<MatchParams>;

function TodoPage({ match }: Props) {
    const allTasks = useSelector(tasksSelector);
    const todoItem = useSelector(todoItemSelector(match.params.taskId));

    const getStatusDisplayText = (status: TaskDtoStatusEnum) => {
        switch (status) {
            case TaskDtoStatusEnum.NotStarted:
                return 'Not Started';
            case TaskDtoStatusEnum.InProgress:
                return 'In Progress';
            case TaskDtoStatusEnum.Delayed:
                return 'Delayed';
            case TaskDtoStatusEnum.Complete:
                return 'Completed';
            case TaskDtoStatusEnum.Archived:
                return 'Archived';
            default:
                return 'Unknown';
        }
    };

    if (!todoItem) {
        if (allTasks) {
            return <Redirect to="/tasks" />;
        }

        return <SkeletonTodoPage />;
    }

    const documents = [...todoItem.documents];
    documents.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            <Helmet>
                <title>View Task | Compass by Swell Energy</title>
            </Helmet>
            <TodoCard size={documents.length === 0 ? 'lg' : 'full'}>
                <Half>
                    <Name>{todoItem.name}</Name>
                    <SecondaryData>
                        <StatusText>
                            <Date>Due </Date>
                            <BoldDate>
                                <Moment format="MMM DD, YYYY">{todoItem.endTime}</Moment>
                            </BoldDate>
                            <Date> - </Date>
                            {getStatusDisplayText(todoItem.status)}
                        </StatusText>
                    </SecondaryData>
                    <Description>{todoItem.description}</Description>
                </Half>
                {documents.length > 0 && (
                    <Half>
                        <DocumentsList documents={documents} />
                    </Half>
                )}
            </TodoCard>
        </>
    );
}

export default TodoPage;
