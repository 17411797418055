import { Helmet } from 'react-helmet';
import tw from 'twin.macro';
import ChangePasswordCard from './ChangePasswordCard';
import MyPreferencesCard from './MyPreferencesCard';

const Page = tw.div`flex flex-wrap`;

function MyAccount() {
    return (
        <Page>
            <Helmet>
                <title>My Account | Compass by Swell Energy</title>
            </Helmet>
            <ChangePasswordCard />
            <MyPreferencesCard />
        </Page>
    );
}

export default MyAccount;
