import axios, { AxiosRequestConfig } from 'axios';

const uploadToS3 = async (url: string, file: File, onProgress?: (pct: number) => void) => {
    const options: AxiosRequestConfig = {
        method: 'PUT',
        url,
        headers: {
            'content-type': file.type,
        },
        data: file,
        onUploadProgress: e => onProgress?.(Math.round((e.loaded * 100) / e.total)),
    };

    await axios(options);
};

const s3UploadService = {
    uploadToS3,
};

export default s3UploadService;
