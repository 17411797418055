export default [
    {
        id: 'MinLength',
        test: (input: string) => Math.min(input.length, 8),
        help: 'Password must be at least 8 characters long.',
        weight: 8,
    },
    {
        id: 'Uppercase',
        test: (input: string) => (input.match(/[A-Z]/) ? 1 : 0),
        help: 'Include at least one uppercase letter.',
        weight: 1,
    },
    {
        id: 'Lowercase',
        test: (input: string) => (input.match(/[a-z]/) ? 1 : 0),
        help: 'Include at least one lowercase letter.',
        weight: 1,
    },
    {
        id: 'Number',
        test: (input: string) => (input.match(/\d/) ? 1 : 0),
        help: 'Include at least one number.',
        weight: 1,
    },
    {
        id: 'SpecialChar',
        test: (input: string) => (input.match(/[^A-Za-z0-9]/) ? 1 : 0),
        help: 'Include at least one special character.',
        weight: 1,
    },
];
