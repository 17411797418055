import { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import Card from '@components/Card';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import Name from './Name';
import Address from './Address';
import GMap from './GMap';
import ContactInfo from './ContactInfo';
import { demographicsLoadFailedSelector } from '../selectors';
import { loadDemographicsAsync } from '../actions';

const DCard = tw(Card)`flex flex-col md:flex-row gap-4 relative`;
const Spacer = tw.div`h-8`;

interface CardContentProps {
    grow?: boolean;
}
const CardContent = styled.div((props: CardContentProps) => [
    tw`w-full md:w-1/2 relative`,
    props.grow ? tw`flex-grow` : '',
]);

function DemographicsCard() {
    const dispatch = useDispatch();
    const [editingContactInfo, setEditingContactInfo] = useState(false);
    const demographicsLoadFailed = useSelector(demographicsLoadFailedSelector);

    useEffect(() => {
        dispatch(loadDemographicsAsync.request());
    }, [dispatch]);

    return (
        <DCard size="full">
            <CardContent>
                <Name />
                <Address />
                <Spacer />
                <ContactInfo isInEditMode={editingContactInfo} onExitEditMode={() => setEditingContactInfo(false)} />
            </CardContent>
            <CardContent grow>
                <GMap />
            </CardContent>
            {demographicsLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadDemographicsAsync.request())} />}
        </DCard>
    );
}

export default DemographicsCard;
