import { createAction, createAsyncAction } from 'typesafe-actions';
import { CheckTokenRequest, CheckTokenResponse, LoginRequest, LoginResponse, SelectContextRequest } from './models';

export const loginAsync = createAsyncAction('auth/login', 'auth/login/success', 'auth/login/failure')<
    LoginRequest,
    LoginResponse,
    undefined
>();

export const checkTokenAsync = createAsyncAction(
    'auth/checkToken',
    'auth/checkToken/success',
    'auth/checkToken/failure',
)<CheckTokenRequest, CheckTokenResponse, undefined>();

export const refreshAsync = createAsyncAction('auth/refresh', 'auth/refresh/success', 'auth/refresh/failure')<
    undefined,
    undefined,
    undefined
>();

export const logout = createAction('logout')<undefined>();
export const selectContext = createAsyncAction(
    'auth/selectContext',
    'auth/selectContext/success',
    'auth/selectContext/failure',
)<SelectContextRequest, undefined, undefined>();
export const resetContext = createAction('auth/resetContext')();

export const didUpdatePassword = createAction('auth/didUpdatePassword')();
