import { useState } from 'react';
import { useFormik } from 'formik';
import * as Sentry from '@sentry/react';
import tw, { styled } from 'twin.macro';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import ButtonStyleBase from '@components/button';
import Services from '@services/index';
import { loadFinancialsAsync } from '../actions';

interface FormData {
    amount1?: number;
    amount2?: number;
}

const initialValues: FormData = {};

const Button = styled.button(() => [...ButtonStyleBase]);

const HelpText = tw.p`text-sm p-2 mb-4 leading-relaxed border border-brand-muted border-solid rounded-sm bg-brand-light text-brand-black`;
const AmountWrapper = tw.div`flex flex-row justify-center w-full mb-2`;
const AmountInput = tw.input`rounded w-16 p-2 mx-2 border border-brand-light outline-none text-center focus:border-brand-primary`;
const ErrorText = tw.p`text-xs m-2 p-2 leading-relaxed text-input-error`;

const DefaultErrorMessage =
    'We had a problem verifying your bank account. Please try again or contact us if you need assistance.';

function CompleteManualBankVerification() {
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            amount1: yup.number().required().min(0.01).max(0.99),
            amount2: yup.number().required().min(0.01).max(0.99),
        }),
        validateOnChange: true,
        validateOnMount: true,
        onSubmit: async (values, helpers) => {
            setErrorMessage('');
            if (!values.amount1 || !values.amount2) return;

            try {
                const result = await Services.financeApi.financeControllerPostManualVerifyAmounts({
                    amounts: [values.amount1, values.amount2],
                });

                if (result.data.success) {
                    dispatch(loadFinancialsAsync.request({ forceReset: true }));
                } else {
                    setErrorMessage(result.data.error || DefaultErrorMessage);
                }
            } catch (e) {
                Sentry.captureException(e);
                setErrorMessage(DefaultErrorMessage);
            }

            helpers.setSubmitting(false);
        },
    });

    return (
        <>
            <HelpText>
                We made two micro-deposits into your account{/* on <strong>7/29/2021</strong> */}. Once they appear in
                your account statement or online, please enter the amounts.
            </HelpText>
            <AmountWrapper>
                <AmountInput
                    name="amount1"
                    placeholder="0.00"
                    value={formik.values.amount1}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    onFocus={e => e.currentTarget.select()}
                />
                <AmountInput
                    name="amount2"
                    placeholder="0.00"
                    value={formik.values.amount2}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                />
            </AmountWrapper>
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
            <Button
                type="button"
                disabled={!formik.isValid || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
            >
                Verify Account
            </Button>
        </>
    );
}

export default CompleteManualBankVerification;
