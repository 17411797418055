import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadGSStagesAsync, loadInfoAsync } from './actions';

const info = createReducer<Api.UtilityInfoDto | null>(null)
    .handleAction(loadInfoAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => null);

const infoLoadFailed = createReducer(false)
    .handleAction(loadInfoAsync.request, () => false)
    .handleAction(loadInfoAsync.failure, () => true);

const gsStages = createReducer<Api.StageDto[]>([])
    .handleAction(loadGSStagesAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => []);

const gsStagesLoadFailed = createReducer(false)
    .handleAction(loadGSStagesAsync.request, () => false)
    .handleAction(loadGSStagesAsync.failure, () => true);

const utilityReducer = combineReducers({
    info,
    infoLoadFailed,
    gsStages,
    gsStagesLoadFailed,
});

export default utilityReducer;
export type UtilityState = ReturnType<typeof utilityReducer>;
