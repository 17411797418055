import { resetContext } from '@features/Auth/actions';
import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { loadESAStatusAsync, loadFinancialsAsync, unlinkAccountAsync } from './actions';

const financials = createReducer<Api.FinancingInfoDto | null>(null)
    .handleAction([loadFinancialsAsync.success, unlinkAccountAsync.success], (_state, action) => action.payload)
    .handleAction(unlinkAccountAsync.request, () => null)
    .handleAction(loadFinancialsAsync.request, (state, action) => (action.payload?.forceReset ? null : state))
    .handleAction(resetContext, () => null);

const financialsLoadFailed = createReducer(false)
    .handleAction(loadFinancialsAsync.request, () => false)
    .handleAction([loadFinancialsAsync.failure, unlinkAccountAsync.failure], () => true);

const esaStatus = createReducer<Api.ESAStatusDto | null>(null).handleAction(
    loadESAStatusAsync.success,
    (_state, action) => action.payload,
);

const esaStatusLoadFailed = createReducer(false)
    .handleAction(loadESAStatusAsync.request, () => false)
    .handleAction(loadESAStatusAsync.failure, () => true);

const financialsReducer = combineReducers({
    financials,
    financialsLoadFailed,
    esaStatus,
    esaStatusLoadFailed,
});

export default financialsReducer;
export type FinancialsState = ReturnType<typeof financialsReducer>;
