import tw, { styled } from 'twin.macro';
import ButtonStyleBase from '@components/button';

const ModalOverlay = tw.div`bg-brand-black bg-opacity-70 z-40 fixed top-0 left-0 bottom-0 right-0 flex`;
const ModalContent = tw.div`m-auto bg-brand-white rounded shadow p-4 w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3`;
const Title = tw.h1`font-heading text-lg text-brand-black`;
const MessageBlock = tw.p`mb-2 text-brand-black`;
const Buttons = tw.div`flex flex-row justify-center space-x-4 mx-auto mt-8`;
const Button = styled.button(() => [...ButtonStyleBase]);

interface Props {
    onConfirm: () => void;
    onCancel: () => void;
}

function UnlinkConfirmModal({ onConfirm, onCancel }: Props) {
    return (
        <ModalOverlay>
            <ModalContent>
                <Title>Pay With Different Account</Title>
                <MessageBlock>
                    To make a payment with a different account, we need to unlink the account on file. If you want to
                    use this account for payments in the future, you&apos;ll need to link it again.
                </MessageBlock>
                <MessageBlock>Do you want to unlink your account?</MessageBlock>
                <Buttons>
                    <Button onClick={() => onConfirm()}>Unlink Account</Button>
                    <Button onClick={() => onCancel()}>Cancel</Button>
                </Buttons>
            </ModalContent>
        </ModalOverlay>
    );
}

export default UnlinkConfirmModal;
