import tw, { styled } from 'twin.macro';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import LinkCard from '@components/LinkCard';
import CardValue from '@components/CardValue';
import { esaStatusSelector } from '../selectors';

const Stats = tw.div`flex flex-col justify-around items-start flex-grow`;
const Container = tw.div`flex flex-col py-2 w-full`;
const Name = styled.span((props: { error?: boolean }) => [
    tw`font-heading text-sm`,
    props.error ? tw`text-brand-dark` : tw`text-brand-muted`,
]);
const RecentPayment = tw.div`flex flex-row justify-between mb-2`;

function ESARecentPaymentsCard() {
    const esaStatus = useSelector(esaStatusSelector);
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });

    return (
        <LinkCard size="md" title="Payment History">
            <Stats>
                {esaStatus && (
                    <>
                        <CardValue
                            label="Contract Length"
                            value={`${esaStatus.termLengthYears} Years`}
                            loaderWidth="12.0rem"
                        />
                        <CardValue
                            label="Payments Made"
                            value={`${esaStatus.paymentsMade} of ${esaStatus.paymentsTotal}`}
                            loaderWidth="12.0rem"
                        />
                        {esaStatus.recentPayments && esaStatus.recentPayments.length > 0 && (
                            <Container>
                                <Name>Recent Payments</Name>
                                {esaStatus.recentPayments.map(pmt => (
                                    <RecentPayment>
                                        <span>{moment.utc(pmt.date).format('l')}</span>
                                        <span>{formatter.format(pmt.amount)}</span>
                                    </RecentPayment>
                                ))}
                            </Container>
                        )}
                    </>
                )}
                {!esaStatus && <SkeletonLoader height="15rem" width="100%" />}
            </Stats>
        </LinkCard>
    );
}

export default ESARecentPaymentsCard;
