import { TaskDocumentDto } from '@services/api';
import DocumentsRequired from './DocumentsRequired';
import DocumentsCompleted from './DocumentsCompleted';

interface Props {
    documents: TaskDocumentDto[];
}

function DocumentList({ documents }: Props) {
    const hasMissingDocuments = documents.some(d => !d.isUploaded);
    const hasCompletedDocuments = documents.some(d => d.isUploaded);

    return (
        <div>
            {hasMissingDocuments && <DocumentsRequired documents={documents} />}
            {hasCompletedDocuments && <DocumentsCompleted documents={documents} />}
        </div>
    );
}

export default DocumentList;
