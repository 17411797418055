import * as Sentry from '@sentry/react';
import tw from 'twin.macro';
import LinkCard from '@components/LinkCard';
import { AppSchematicDtoStepEnum, ProposalDto } from '@services/api';
import CardValue from '@components/CardValue';
import api from '@services/index';
import { useCallback } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { stepSelector } from '@features/Portal/selectors';

const Stats = tw.div`flex flex-col items-start flex-grow`;

interface Props {
    proposal: ProposalDto;
}

function ProposalCard({ proposal }: Props) {
    const step = useSelector(stepSelector);
    const handleClick = useCallback(async () => {
        try {
            const proposalUrl = await api.proposalsApi.proposalsControllerGetProposalUrl(proposal.id);
            window.open(proposalUrl.data.url);
        } catch (err) {
            Sentry.captureException(err);
        }
    }, [proposal]);

    const isOpportunity = step === AppSchematicDtoStepEnum.Presales;
    const dateLabel = isOpportunity ? 'Valid Until' : 'Created';
    const dateDisplay = moment
        .utc(isOpportunity ? proposal.expiry : proposal.createDate)
        .local()
        .format('MMM D, YYYY');

    return (
        <LinkCard size="md" title={proposal.name} onClick={handleClick} navigateText="View proposal">
            <Stats>
                <CardValue label="Payment Type" value={proposal.paymentType} />
                <CardValue label={dateLabel} value={dateDisplay} />
            </Stats>
        </LinkCard>
    );
}

export default ProposalCard;
