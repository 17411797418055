import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

const financialsStateSelector = (state: RootState) => state.financials;

export const financialsSelector = createSelector(financialsStateSelector, v => v.financials);
export const financialsLoadFailedSelector = createSelector(financialsStateSelector, v => v.financialsLoadFailed);

export const esaStatusSelector = createSelector(financialsStateSelector, v => v.esaStatus);
export const esaStatusLoadFailedSelector = createSelector(financialsStateSelector, v => v.esaStatusLoadFailed);
