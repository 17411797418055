import tw, { styled } from 'twin.macro';
import { ArrowRight } from 'react-feather';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { DocumentDto } from '@services/api';
import Api from '@services/index';
import { useCallback } from 'react';

const CardBaseStyle = tw`flex-row p-4 border cursor-pointer select-none items-center justify-between bg-brand-white border-brand-light outline-none
focus:outline-none hover:bg-brand-light hover:border-brand-muted
active:bg-brand-light active:border-brand-light `;

const DocumentCard = styled(Link)(() => [CardBaseStyle, tw`hidden md:flex`]);

const DocumentButton = styled.button(() => [CardBaseStyle, tw`flex md:hidden`]);

const DocumentMetadata = tw.div`flex flex-col items-start`;
const Title = tw.h1`text-lg my-1 text-brand-black`;
const Modified = tw.div`text-sm text-brand-muted my-1`;

interface Props {
    document: DocumentDto;
}

function DocumentListItem({ document }: Props) {
    const { id } = document;
    const handleMobileDownload = useCallback(async () => {
        const downloadLink = await Api.documentsApi.documentsControllerGetDocumentLink(id);
        window.location.assign(downloadLink.data.previewUrl);
    }, [id]);
    const content = (
        <>
            <DocumentMetadata>
                <Title>{document.name}</Title>
                <Modified>
                    <span>Last modified </span>
                    <Moment fromNow withTitle titleFormat="LLLL">
                        {document.modifiedAt}
                    </Moment>
                </Modified>
            </DocumentMetadata>
            <ArrowRight />
        </>
    );

    return (
        <>
            <DocumentCard to={`/documents/${document.id}`}>{content}</DocumentCard>
            <DocumentButton onClick={handleMobileDownload}>{content}</DocumentButton>
        </>
    );
}

export default DocumentListItem;
