import tw, { styled } from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const ViewerContainer = styled.div(() => [tw`w-full`, 'height: 80vh']);

function DocumentViewerSkeleton() {
    return (
        <ViewerContainer>
            <SkeletonLoader height="100%" width="100%" />
        </ViewerContainer>
    );
}

export default DocumentViewerSkeleton;
