import tw from 'twin.macro';
import { ReactComponent as LogoSource } from '@images/logo.svg';
import { ReactComponent as SwellLogoSource } from '@images/swell.svg';
import AppTheme from '@root/AppTheme';

const CompassLogo = tw(LogoSource)`text-app-logo h-10 mb-2`;
const PoweredBy = tw.div`w-full justify-center text-xs md:text-sm text-brand-muted italic flex flex-row items-center space-x-2 mb-2`;
const SwellLogo = tw(SwellLogoSource)`h-3 md:h-5 text-brand-muted`;

function Logo() {
    return (
        <>
            <CompassLogo />
            {AppTheme.showPoweredBy && (
                <PoweredBy>
                    <span>powered by</span>
                    <SwellLogo />
                </PoweredBy>
            )}
        </>
    );
}

export default Logo;
