import tw, { styled } from 'twin.macro';

interface HelperLabelProps {
    success?: boolean;
}

export default styled.span(({ success }: HelperLabelProps) => [
    tw`text-xs pl-2 pt-1`,
    success ? tw`text-brand-success` : tw`text-brand-muted`,
]);
