import { createAsyncAction } from 'typesafe-actions';
import { Api } from '@services/index';
import { UpdatePreferencesRequest } from './models';

export const loadPreferencesAsync = createAsyncAction(
    'myAccount/loadPreferences',
    'myAccount/loadPreferences/success',
    'myAccount/loadPreferences/failure',
)<undefined, Api.PreferencesDto, undefined>();

export const updatePreferencesAsync = createAsyncAction(
    'myAccount/updatePreferences',
    'myAccount/updatePreferences/success',
    'myAccount/updatePreferences/failure',
)<UpdatePreferencesRequest, Api.PreferencesDto, undefined>();
