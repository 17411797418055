import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { useDispatch } from 'react-redux';
import Card from '@components/Card';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import { loadAsync } from '../actions';

const TopBuffer = tw.div`mt-8`;
const NameRow = tw.div`flex flex-row mt-3`;
const RoleRow = tw.div`mt-2`;
const CompanyRow = tw.div`mt-3`;
const Row4 = tw.div`mt-1`;
const Row5 = tw.div`mt-1 flex flex-row`;
const Row6 = tw.div`mt-6 mb-8`;
const Spacer = tw.div`w-2`;
const ContactCardContent = tw(Card)`flex flex-col items-center`;

interface Props {
    loadFailed: boolean;
}

function SkeletonContactCard({ loadFailed }: Props) {
    const dispatch = useDispatch();

    return (
        <ContactCardContent size="md">
            <TopBuffer />
            <SkeletonLoader circle width="7rem" height="7rem" />
            <NameRow>
                <SkeletonLoader width="5rem" height="1.25rem" />
                <Spacer />
                <SkeletonLoader width="8rem" height="1.25rem" />
            </NameRow>
            <RoleRow>
                <SkeletonLoader width="5rem" height="0.875rem" />
            </RoleRow>
            <CompanyRow>
                <SkeletonLoader width="15rem" height="1.25rem" />
            </CompanyRow>
            <Row4>
                <SkeletonLoader width="13rem" height="1rem" />
            </Row4>
            <Row5>
                <SkeletonLoader width="8rem" height="1rem" />
                <Spacer />
                <SkeletonLoader width="2rem" height="1rem" />
                <Spacer />
                <SkeletonLoader width="4rem" height="1rem" />
            </Row5>
            <Row6>
                <SkeletonLoader width="12rem" height="2.25rem" />
            </Row6>
            {loadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadAsync.request())} />}
        </ContactCardContent>
    );
}

export default SkeletonContactCard;
