import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadProposalsAsync } from './actions';

const proposalsStatus = createReducer<'idle' | 'loading' | 'done' | 'error'>('idle')
    .handleAction(loadProposalsAsync.request, () => 'loading')
    .handleAction(loadProposalsAsync.success, () => 'done')
    .handleAction(loadProposalsAsync.failure, () => 'error')
    .handleAction(resetContext, () => 'idle');

const proposals = createReducer<Api.ProposalDto[]>([])
    .handleAction(loadProposalsAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => []);

const proposalsReducer = combineReducers({
    proposals,
    proposalsStatus,
});

export default proposalsReducer;
export type ProposalsState = ReturnType<typeof proposalsReducer>;
