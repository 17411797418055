import { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';

interface CardProps {
    size: 'full' | 'xl' | 'lg' | 'md';
    disablePadding?: boolean;
}

const CardContainer = styled.div((props: CardProps) => [
    tw`p-2 flex-auto`,
    props.size === 'full' ? tw`w-full` : '',
    props.size === 'xl' ? tw`w-full xl:w-2/3` : '',
    props.size === 'lg' ? tw`w-full xl:w-1/2` : '',
    props.size === 'md' ? tw`w-full sm:w-1/2 xl:w-1/3` : '',
]);

const CardBase = styled.div((props: CardProps) => [
    tw`bg-brand-white rounded shadow w-full h-full relative`,
    props.disablePadding ? '' : tw`p-4`,
]);

function Card({ children, ...rest }: PropsWithChildren<CardProps>) {
    return (
        <CardContainer {...rest}>
            <CardBase {...rest}>{children}</CardBase>
        </CardContainer>
    );
}

Card.defaultProps = {
    disablePadding: undefined,
};

export default Card;
