import * as Sentry from '@sentry/react';
import { EMPTY, from, of } from 'rxjs';
import { filter, switchMap, catchError, tap } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import getTokenData from '@util/jwt';
import { checkTokenAsync, loginAsync, resetContext, selectContext } from './actions';

export const loginEpic: Epic = (action$, _state$, { authApi, accessTokenService }) =>
    action$.pipe(
        filter(isActionOf(loginAsync.request)),
        switchMap(action =>
            from(
                authApi.authControllerLogin({
                    username: action.payload.emailAddress,
                    password: action.payload.password,
                }),
            ).pipe(
                tap(() => action.payload.formik.setSubmitting(false)),
                tap(result => {
                    accessTokenService.saveToken(result.data?.token, false);
                }),
                switchMap(result =>
                    of(loginAsync.success({ success: result.status === 200, authToken: result.data?.token })),
                ),
                catchError(err => {
                    action.payload.formik.setSubmitting(false);

                    if (err.isAxiosError && err?.response?.status === 401) {
                        return of(loginAsync.success({ success: false, authToken: '' }));
                    }
                    Sentry.captureException(err);
                    return of(loginAsync.failure());
                }),
            ),
        ),
    );

export const checkTokenEpic: Epic = (action$, _state$, { authApi, accessTokenService }) =>
    action$.pipe(
        filter(isActionOf(checkTokenAsync.request)),
        switchMap(action =>
            from(authApi.authControllerCheckToken()).pipe(
                switchMap(() => of(checkTokenAsync.success({ success: true, authToken: action.payload.token }))),
                catchError(err => {
                    Sentry.captureException(err);
                    accessTokenService.clearToken();
                    return of(checkTokenAsync.failure());
                }),
            ),
        ),
    );

export const authenticatedContextAutoSelectEpic: Epic = (action$, _state$, { accessTokenService }) =>
    action$.pipe(
        filter(isActionOf([checkTokenAsync.success, loginAsync.success])),
        switchMap(action => {
            if (action.payload.success) {
                const tokenData = getTokenData(action.payload.authToken);

                if (tokenData.scopes?.length === 1 && tokenData.scopes[0].contexts?.length === 1) {
                    const contactScope = tokenData.scopes[0];

                    const parentId = contactScope.id;
                    const contextId = contactScope.contexts[0].id;

                    accessTokenService.saveContext(parentId, contextId, false);

                    return of(
                        selectContext.request({
                            parentId,
                            contextId,
                        }),
                    );
                }

                const savedContext = accessTokenService.getContext();

                if (savedContext.parentId && savedContext.contextId) {
                    return of(
                        selectContext.request({
                            parentId: savedContext.parentId,
                            contextId: savedContext.contextId,
                        }),
                    );
                }
            }

            return EMPTY;
        }),
    );

export const selectContextEpic: Epic = (action$, _state$, { accessTokenService }) =>
    action$.pipe(
        filter(isActionOf(selectContext.request)),
        switchMap(action => {
            accessTokenService.saveContext(action.payload.parentId, action.payload.contextId, false);
            return of(selectContext.success());
        }),
    );

export const resetContextEpic: Epic = (action$, _state$, { accessTokenService }) =>
    action$.pipe(
        filter(isActionOf(resetContext)),
        tap(() => {
            accessTokenService.saveContext('', '', false);
        }),
        switchMap(() => EMPTY),
    );
