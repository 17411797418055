import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

const authStateSelector = (state: RootState) => state.auth;

export const isLoggedInSelector = createSelector(authStateSelector, v => v.isLoggedIn);
export const errorSelector = createSelector(authStateSelector, v => v.loginError);
export const contextSelectionRequiredSelector = createSelector(authStateSelector, v => v.contextSelectionRequired);
export const passwordUpdateRequiredSelector = createSelector(authStateSelector, v => v.passwordUpdateRequired);
export const hasMultipleContextsSelector = createSelector(authStateSelector, v => v.hasMultipleContexts);
