import { useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import tw from 'twin.macro';
import { Link } from 'react-router-dom';
import FormikInputBox, { InputBox } from '@components/FormikInputBox';
import PasswordStrengthIndicator from '@components/PasswordStrengthIndicator';
import HelperLabel from '@components/HelperLabel';
import Services from '@services/index';
import { Button, ErrorMessage, InstructionMessage, LoginLink, Row, SuccessMessage } from './StyledComponents';

const FormContainer = tw.form`flex flex-col items-center justify-center`;
const Spacer = tw.div`h-2`;
const InfoInputBox = tw(InputBox)`mb-0`;

const validationSchema = Yup.object({
    password: Yup.string().min(8).required().matches(/[a-z]/).matches(/[A-Z]/).matches(/\d/).matches(/\W/),
    passwordConfirm: Yup.string().min(8).required(),
});

const initialValues = {
    password: '',
    passwordConfirm: '',
};

type FormData = typeof initialValues;

const DefaultErrorMessage = 'There was an error submitting your request. Please try again later.';

interface Props {
    nonce: string;
}

function SetPasswordForm({ nonce }: Props) {
    const [status, setStatus] = useState<'ready' | 'failed' | 'success'>('ready');
    const [errorMessage, setErrorMessage] = useState('');

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
            formikHelpers.setSubmitting(true);
            setErrorMessage('');
            setStatus('ready');

            if (values.password !== values.passwordConfirm) {
                setErrorMessage('Passwords do not match.');
                setStatus('failed');
                return;
            }

            try {
                const result = await Services.authApi.authControllerSetPasswordFromNonce({
                    nonce,
                    password: values.password,
                });
                const success = result.status >= 200 && result.status < 300;

                if (!success) {
                    setErrorMessage(DefaultErrorMessage);
                    setStatus('failed');
                } else {
                    setStatus('success');
                }
            } catch (err) {
                setErrorMessage(DefaultErrorMessage);
                setStatus('failed');
            }

            formikHelpers.setSubmitting(false);
        },
    });

    const buttonDisabled =
        formik.isSubmitting || !formik.isValid || formik.values.password !== formik.values.passwordConfirm;

    return (
        <>
            {status === 'failed' && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {status === 'success' && (
                <>
                    <SuccessMessage>Your password has been set.</SuccessMessage>
                    <LoginLink>
                        <Link to="/">Return to Login</Link>
                    </LoginLink>
                </>
            )}
            {status === 'ready' && (
                <FormContainer onSubmit={formik.handleSubmit}>
                    <InstructionMessage>Please enter a new password.</InstructionMessage>
                    <Row>
                        <FormikInputBox
                            inputBox={InfoInputBox}
                            name="password"
                            type="password"
                            placeholder="Password"
                            hasError={!!formik.touched.password && !!formik.errors.password}
                            formik={formik}
                        />
                        <PasswordStrengthIndicator currentValue={formik.values.password} />
                    </Row>
                    <Row>
                        <FormikInputBox
                            inputBox={InfoInputBox}
                            name="passwordConfirm"
                            type="password"
                            placeholder="Confirm Password"
                            hasError={
                                !!formik.touched.passwordConfirm &&
                                formik.values.password !== formik.values.passwordConfirm
                            }
                            formik={formik}
                        />
                        <Spacer />
                        <HelperLabel
                            success={
                                !!formik.values.password && formik.values.password === formik.values.passwordConfirm
                            }
                        >
                            Both passwords must match.
                        </HelperLabel>
                    </Row>

                    <Button type="submit" disabled={buttonDisabled}>
                        Submit
                    </Button>
                </FormContainer>
            )}
        </>
    );
}

export default SetPasswordForm;
