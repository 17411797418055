import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Wrapper = tw.li`flex flex-col border-l-8 px-2 py-4`;
const SecondaryData = tw.div`flex flex-row mt-1`;
const Description = tw.span`mt-4 flex flex-col`;
const DescriptionLine = tw.div`flex flex-row mb-1`;

function SkeletonTimelineEntry() {
    return (
        <Wrapper>
            <SkeletonLoader height="1.5rem" width="10rem" />
            <SecondaryData>
                <SkeletonLoader height="1.0rem" width="14rem" />
            </SecondaryData>
            <Description>
                <DescriptionLine>
                    <SkeletonLoader height="1.25rem" width="42%" />
                    <div tw="w-2" />
                    <SkeletonLoader height="1.25rem" tw="flex-grow" />
                </DescriptionLine>
                <DescriptionLine>
                    <SkeletonLoader height="1.25rem" width="70%" />
                    <div tw="w-2" />
                    <SkeletonLoader height="1.25rem" tw="flex-grow" />
                </DescriptionLine>
                <DescriptionLine>
                    <SkeletonLoader height="1.25rem" width="66%" />
                </DescriptionLine>
            </Description>
        </Wrapper>
    );
}

export default SkeletonTimelineEntry;
