import AppTheme from '@root/AppTheme';
import { TaskDto } from '@services/api';
import moment from 'moment';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';

interface Props {
    event: TaskDto;
}

const TaskWrapper = tw(Link)`border rounded border-brand-muted shadow text-left block relative hover:bg-brand-light`;
const EventWrapper = tw.div`border rounded border-brand-muted shadow text-left block relative`;
const StatusBar = tw.div`bg-brand-muted h-8 flex items-center justify-start px-2`;
const Status = tw.span`text-brand-white text-xs`;
const TaskContent = tw.div`p-4 flex flex-col`;
const Description = tw.p`mt-2`;
interface DueDateProps {
    isPastDue: boolean;
}
const DueDate = styled.span(({ isPastDue }: DueDateProps) => [
    tw`mt-8 text-xs text-brand-muted`,
    isPastDue ? tw`text-input-error` : '',
]);
const UserAvatarContainer = tw.div`w-10 h-10 rounded-full z-10 absolute -right-3 -top-4 bg-brand-white bg-cover bg-center overflow-hidden border-2 border-brand-white`;
const UserAvatar = tw.img`w-full h-full`;

function IncompleteTask({ event }: Props) {
    const status = event.isHomeownerAssigned ? 'Please complete this task by the due date.' : 'We are working on this.';
    const isPastDue = moment(event.endTime).isBefore(moment.now());
    const content = (
        <>
            {event.isHomeownerAssigned && (
                <UserAvatarContainer>
                    <UserAvatar alt="" src={AppTheme.avatar} />
                </UserAvatarContainer>
            )}
            <StatusBar>
                <Status>{status}</Status>
            </StatusBar>
            <TaskContent>
                <h3 className="vertical-timeline-element-title">{event.name}</h3>
                <Description>{event.description}</Description>
                {event.isHomeownerAssigned && (
                    <DueDate isPastDue={isPastDue}>
                        Due by <Moment date={event.endTime} format="M/DD/YYYY" />
                    </DueDate>
                )}
            </TaskContent>
        </>
    );

    if (!event.isHomeownerAssigned) {
        return <EventWrapper>{content}</EventWrapper>;
    }

    return <TaskWrapper to={`/tasks/${event.id}`}>{content}</TaskWrapper>;
}

export default IncompleteTask;
