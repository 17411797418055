import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import tw from 'twin.macro';

import LogOut from '@features/Auth/components/LogOut';
import Login from '@features/Auth/components/Login';
import {
    contextSelectionRequiredSelector,
    isLoggedInSelector,
    passwordUpdateRequiredSelector,
} from '@features/Auth/selectors';
import Contacts from '@features/Contacts/components/Contacts';
import DocumentView from '@features/Documents/components/DocumentView';
import Documents from '@features/Documents/components/Documents';
import FinanceAgreementPage from '@features/Financials/components/FinanceAgreementPage';
import FinancialsPage from '@features/Financials/components/FinancialsPage';
import MyAccount from '@features/MyAccount/components/MyAccount';
import Profile from '@features/Profile/components/Profile';
import Proposals from '@features/Proposals/components/Proposals';
import ReferAFriend from '@features/ReferAFriend/components/ReferAFriend';
import TasksPage from '@features/Tasks/components/TasksPage';
import TodoPage from '@features/Tasks/components/TodoPage';
import EnergyExchangePage from '@features/Utility/components/EnergyExchangePage';
import scriptsList from '@root/util/scriptsList';
import { loadAppSchematicAsync } from '../actions';
import { schematicLoadFailedSelector } from '../selectors';
import FatalError from './FatalError';
import NavigationFrame from './NavigationFrame';
import ScrollToTop from './ScrollToTop';

const PortalRoot = tw.div`h-full w-full`;

function Portal() {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(isLoggedInSelector);
    const contextSelectionRequired = useSelector(contextSelectionRequiredSelector);
    const passwordUpdateRequired = useSelector(passwordUpdateRequiredSelector);
    const schematicLoadFailed = useSelector(schematicLoadFailedSelector);

    useEffect(() => {
        const isAppReady = isLoggedIn && !contextSelectionRequired;

        if (isAppReady) {
            dispatch(loadAppSchematicAsync.request());
        }
    }, [dispatch, isLoggedIn, contextSelectionRequired]);

    const loadScripts = useCallback(() => {
        scriptsList.forEach((el, index) => {
            const script = document.createElement('script');
            if (el.startsWith('http')) script.src = el;
            else script.innerHTML = el;
            script.async = true;
            script.id = `script-${index}`;
            document.head.appendChild(script);
        });
    }, []);

    useEffect(() => {
        if (isLoggedIn) loadScripts();
    }, [isLoggedIn, loadScripts]);

    return (
        <PortalRoot>
            {(!isLoggedIn || contextSelectionRequired || passwordUpdateRequired) && <Login />}
            {schematicLoadFailed && <FatalError />}
            {isLoggedIn && !contextSelectionRequired && !passwordUpdateRequired && !schematicLoadFailed && (
                <Router>
                    <ScrollToTop />
                    <NavigationFrame>
                        <Switch>
                            <Route path="/proposals" component={Proposals} />
                            <Route path="/documents/:documentId" component={DocumentView} />
                            <Route path="/documents" component={Documents} />
                            <Route path="/tasks/:taskId" component={TodoPage} />
                            <Route path="/finance" component={FinancialsPage} />
                            <Route path="/finance-agreement" component={FinanceAgreementPage} />
                            <Route path="/tasks" component={TasksPage} />
                            <Route path="/energy-exchange" component={EnergyExchangePage} />
                            <Route path="/contact" component={Contacts} />
                            <Route path="/refer-a-friend" component={ReferAFriend} />
                            <Route path="/log-out" component={LogOut} />
                            <Route path="/my-profile" component={MyAccount} />
                            <Route path="/" component={Profile} />
                        </Switch>
                    </NavigationFrame>
                </Router>
            )}
        </PortalRoot>
    );
}

export default Portal;
