import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';
import { loadTasksAsync } from '../actions';
import CompletedTasks from './CompletedTasks';
import IncompleteTasks from './IncompleteTasks';

const Page = tw.div`flex flex-wrap`;

function TasksPage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadTasksAsync.request());
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>Tasks | Compass by Swell Energy</title>
            </Helmet>
            <IncompleteTasks />
            <CompletedTasks />
        </Page>
    );
}

export default TasksPage;
