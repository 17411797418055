import { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import tw, { styled } from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Lock } from 'react-feather';
import { FinancingInfoDtoAchStatusEnum } from '@services/api';
import Services from '@services/index';
import PlaidLink from './PlaidLink';
import CompleteManualBankVerification from './CompleteManualBankVerification';
import StartManualBankVerification from './StartManualBankVerification';
import VerificationOptionButton from './VerificationOptionButton';
import { loadFinancialsAsync } from '../actions';
import { financialsSelector } from '../selectors';

const HelpText = styled.p((props: { strong?: boolean; muted?: boolean }) => [
    tw`text-sm pb-4 leading-relaxed`,
    props.strong ? tw`font-bold text-base` : '',
    props.muted ? tw`text-brand-muted text-xs` : '',
]);

const LinkContainer = tw.div`flex flex-col w-full items-center`;
const Separator = tw.div`text-xs text-brand-muted py-4`;
const ErrorText = tw.p`text-xs m-2 p-2 leading-relaxed text-input-error`;

function LinkBankAccount() {
    const dispatch = useDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const [linkToken, setLinkToken] = useState('');
    const [showError, setShowError] = useState(false);
    const [isManuallyVerifying, setIsManuallyVerifying] = useState(false);
    const financingInfo = useSelector(financialsSelector);
    const { achStatus } = financingInfo || {};

    useEffect(() => {
        if (achStatus === FinancingInfoDtoAchStatusEnum.DepositPendingVerification) {
            setIsManuallyVerifying(true);
        }
    }, [achStatus]);

    const getLinkToken = async () => {
        setLinkToken('');
        setShowError(false);
        setIsProcessing(true);

        try {
            const tokenResponse = await Services.financeApi.financeControllerGetPlaidLinkToken();
            setLinkToken(tokenResponse.data.token);
        } catch (err) {
            Sentry.captureException(err);
            setShowError(true);
        }

        setIsProcessing(false);
    };

    const onGetPublicToken = async (token: string, accountId: string) => {
        setIsProcessing(true);
        setShowError(false);

        try {
            const postResult = await Services.financeApi.financeControllerPostPlaidLinkPublicToken({
                publicToken: token,
                accountId,
            });

            const success = postResult.status <= 299;

            if (!success) {
                setShowError(true);
            } else {
                dispatch(loadFinancialsAsync.request({ forceReset: true }));
            }
        } catch (err) {
            Sentry.captureException(err);
            setShowError(true);
        }

        setIsProcessing(false);
        setLinkToken('');
    };

    return (
        <LinkContainer>
            <HelpText>To make a payment via ACH bank draft, we need to verify your bank account.</HelpText>
            {showError && (
                <ErrorText>
                    We had a problem processing your request. Please try again or contact support if you continue to get
                    this message.
                </ErrorText>
            )}
            {!isManuallyVerifying && (
                <>
                    <VerificationOptionButton
                        disabled={isProcessing}
                        onPress={() => getLinkToken()}
                        text="Instantly verify your bank account by logging into your bank using Plaid."
                        icon={Lock}
                        iconText="Secure Login"
                    />
                    <Separator>or</Separator>
                    <StartManualBankVerification />
                </>
            )}
            {isManuallyVerifying && (
                <>
                    <CompleteManualBankVerification />
                    <Separator>or</Separator>
                    <VerificationOptionButton
                        disabled={isProcessing}
                        onPress={() => getLinkToken()}
                        text="Instantly verify your bank account by logging into your bank using Plaid."
                        icon={Lock}
                        iconText="Secure Login"
                    />
                </>
            )}
            {linkToken && (
                <PlaidLink token={linkToken} onSuccess={(token, accountId) => onGetPublicToken(token, accountId)} />
            )}
        </LinkContainer>
    );
}

export default LinkBankAccount;
