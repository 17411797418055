import { PropsWithChildren, useEffect } from 'react';
import tw, { styled } from 'twin.macro';
import { Check, ArrowDown, MoreHorizontal } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import Card from '@components/Card';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import { AppSchematicDtoStepEnum, StageDto } from '@services/api';
import { stepSelector } from '@features/Portal/selectors';
import { stagesLoadFailedSelector, stagesSelector } from '../selectors';
import { loadStagesAsync } from '../actions';

const StagesContainer = tw.div`md:flex flex-row `;
const ContactLink = tw.a`text-brand-primary font-bold hover:underline`;

const Step = styled.div((props: StageProps) => [
    tw`w-full h-full p-2 lg:p-4`,
    tw`flex md:flex-col items-center flex-row-reverse justify-between md:justify-end`,
    tw`md:first:rounded-l md:last:rounded-r`,
    tw`first:rounded-t md:first:rounded-r-none`,
    tw`text-xs md:text-sm lg:text-base`,
    props.status === 'active' ? tw`bg-brand-primary text-brand-white` : '',
    props.status === 'complete' ? tw`bg-brand-dark text-brand-white` : '',
    props.status === 'abort' ? tw`bg-brand-light text-brand-muted` : '',
    props.status === 'none' ? tw`text-brand-muted` : '',
]);
function StagesPlaceholder() {
    return (
        <>
            <Step status="none">
                <SkeletonLoader width="100%" height="3.5rem" />
            </Step>
            <Step status="none">
                <SkeletonLoader width="100%" height="3.5rem" />
            </Step>
            <Step status="none">
                <SkeletonLoader width="100%" height="3.5rem" />
            </Step>
            <Step status="none">
                <SkeletonLoader width="100%" height="3.5rem" />
            </Step>
            <Step status="none">
                <SkeletonLoader width="100%" height="3.5rem" />
            </Step>
        </>
    );
}

interface StageProps {
    status: 'complete' | 'active' | 'abort' | 'none';
}

function Stage({ status, children }: PropsWithChildren<StageProps>) {
    return (
        <Step status={status}>
            {status === 'complete' && <Check />}
            {status === 'active' && <ArrowDown />}
            {status === 'none' && <MoreHorizontal />}
            {children}
        </Step>
    );
}

function Stages() {
    const dispatch = useDispatch();
    const stages = useSelector(stagesSelector);
    const stagesLoadFailed = useSelector(stagesLoadFailedSelector);
    const onboardingStep = useSelector(stepSelector);
    const haveStages = stages && stages.length > 0;
    const isAbandoned = stages && stages.length === 1 && stages[0].doesIndicateAbort;

    useEffect(() => {
        dispatch(loadStagesAsync.request());
    }, [dispatch]);

    const getStageStatus = (stage: StageDto) => {
        if (stage.doesIndicateAbort) return 'abort';
        if (stage.isComplete) return 'complete';
        if (stage.isActive) return 'active';

        return 'none';
    };

    return (
        <Card size="full" disablePadding>
            <StagesContainer>
                {!haveStages && <StagesPlaceholder />}
                {haveStages && !isAbandoned && (
                    <>
                        {stages
                            .filter(s => s.isVisible)
                            .map(s => (
                                <Stage key={s.stage} status={getStageStatus(s)}>
                                    {s.displayName}
                                </Stage>
                            ))}
                    </>
                )}
                {haveStages && isAbandoned && (
                    <Step status="abort">
                        {onboardingStep !== AppSchematicDtoStepEnum.CapacityExchangeOnly && (
                            <span>
                                Your project has been marked as cancelled. If this is not correct, please call{' '}
                                <ContactLink href="tel:+13103400493">(310) 340-0493</ContactLink> or{' '}
                                <ContactLink href="mailto:support@swellenergy.com">email us</ContactLink>.
                            </span>
                        )}
                        {onboardingStep === AppSchematicDtoStepEnum.CapacityExchangeOnly && (
                            <span>
                                Your energy exchange has been archived and is not active. If this is not correct, please
                                call <ContactLink href="tel:+13103400493">(310) 340-0493</ContactLink> or{' '}
                                <ContactLink href="mailto:support@swellenergy.com">email us</ContactLink>.
                            </span>
                        )}
                    </Step>
                )}
                {stagesLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadStagesAsync.request())} />}
            </StagesContainer>
        </Card>
    );
}

export default Stages;
