import { createAsyncAction } from 'typesafe-actions';
import { Api } from '@services/index';

export const loadInfoAsync = createAsyncAction(
    'utility/loadInfo',
    'utility/loadInfo/success',
    'utility/loadInfo/failure',
)<undefined, Api.UtilityInfoDto, undefined>();

export const loadGSStagesAsync = createAsyncAction(
    'utility/loadGSStages',
    'utility/loadGSStages/success',
    'utility/loadGSStages/failure',
)<undefined, Api.StageDto[], undefined>();
