import tw, { styled } from 'twin.macro';
import { CheckCircle } from 'react-feather';
import { Link } from 'react-router-dom';
import { TaskDocumentDto } from '@services/api';
import Api from '@services/index';
import ButtonStyleBase from '@components/button';
import { useCallback } from 'react';

const Section = tw.div`mb-12 last:mb-0`;
const SectionTitle = tw.h2`text-lg`;
const DocumentLink = styled(Link)(() => [...ButtonStyleBase, tw`w-full hidden md:flex`]);
const DocumentButton = styled.button(() => [...ButtonStyleBase, tw`w-full flex md:hidden`]);
const CompletedCheck = tw(CheckCircle)`text-brand-success mr-2`;
const DocumentTitle = tw.span`text-sm`;
const ExistingDocumentsList = tw.div`ml-2`;

interface CompletedDocumentProps {
    document: TaskDocumentDto;
}

function CompletedDocument({ document }: CompletedDocumentProps) {
    const { id } = document;
    const handleMobileDownload = useCallback(async () => {
        const downloadLink = await Api.documentsApi.documentsControllerGetDocumentLink(id);
        window.location.assign(downloadLink.data.previewUrl);
    }, [id]);

    const content = (
        <>
            <CompletedCheck />
            <DocumentTitle>{document.name}</DocumentTitle>
        </>
    );

    return (
        <>
            <DocumentLink to={`/documents/${document.id}`}>{content}</DocumentLink>
            <DocumentButton onClick={handleMobileDownload}>{content}</DocumentButton>
        </>
    );
}

interface Props {
    documents: TaskDocumentDto[];
}

function DocumentsCompleted({ documents }: Props) {
    return (
        <Section>
            <SectionTitle>Documents Already Attached</SectionTitle>
            <ExistingDocumentsList>
                {documents
                    .filter(d => d.isUploaded)
                    .map(d => (
                        <CompletedDocument key={`cmpdoc-${d.id}`} document={d} />
                    ))}
            </ExistingDocumentsList>
        </Section>
    );
}

export default DocumentsCompleted;
