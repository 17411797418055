import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { loadPreferencesAsync, updatePreferencesAsync } from './actions';

export const loadPreferencesEpic: Epic = (action$, _state$, { demographicsApi }) =>
    action$.pipe(
        filter(isActionOf(loadPreferencesAsync.request)),
        switchMap(() =>
            from(demographicsApi.demographicsControllerGetPreferences()).pipe(
                switchMap(result => of(loadPreferencesAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadPreferencesAsync.failure());
                }),
            ),
        ),
    );

export const updatePreferencesEpic: Epic = (action$, _state$, { demographicsApi }) =>
    action$.pipe(
        filter(isActionOf(updatePreferencesAsync.request)),
        switchMap(action =>
            from(demographicsApi.demographicsControllerUpdatePreferences(action.payload)).pipe(
                switchMap(result => of(updatePreferencesAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(updatePreferencesAsync.failure());
                }),
            ),
        ),
    );
