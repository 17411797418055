import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

const documentsStateSelector = (state: RootState) => state.documents;

export const documentsSelector = createSelector(documentsStateSelector, v => v.documents);
export const documentsLoadFailedSelector = createSelector(documentsStateSelector, v => v.documentsLoadFailed);
export const documentLinkSelector = (documentId: string) =>
    createSelector(documentsStateSelector, v => v.documentLinks[documentId]);
export const documentLinkFailureSelector = (documentId: string) =>
    createSelector(documentsStateSelector, v => v.documentLinksFailures.includes(documentId));
