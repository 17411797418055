import tw, { styled } from 'twin.macro';
import { PhoneCall, Mail } from 'react-feather';
import Card from '@components/Card';
import Logo from '@images/contact-logo.png';
import ButtonStyleBase from '@components/button';
import AppTheme from '@root/AppTheme';

const ContactCardContent = tw(Card)`flex flex-col items-center text-center`;
const AvatarContainer = tw.div`flex items-center justify-center w-28 h-28 mt-8`;
const Avatar = tw.img`w-28`;
const Title = tw.span`text-lg mt-2`;
const Subtitle = tw.span`text-sm text-brand-muted`;
const ContactMethods = tw.div`flex flex-col mb-8 mt-auto`;
const MailIcon = tw(Mail)`mr-2`;
const PhoneCallIcon = tw(PhoneCall)`mr-2`;
const Hours = tw.span`text-xs`;

const ContactMethod = styled.a(() => [...ButtonStyleBase]);

function SwellContactCard() {
    const phoneRaw = '3103400493';
    const phoneFormatted = '(310) 340-0493';
    const email = 'support@swellenergy.com';

    return (
        <ContactCardContent size="md">
            <AvatarContainer>
                <Avatar src={AppTheme.contactUsLogo} />
            </AvatarContainer>
            <Title>Swell Support</Title>
            <Subtitle>Operating hours</Subtitle>
            <Hours>Mon-Fri: 8AM to 7PM Pacific</Hours>
            <Hours>Sat/Sun: 9AM to 6PM Pacific</Hours>
            <ContactMethods>
                <ContactMethod href={`mailto:${email}`}>
                    <MailIcon size="1.1rem" /> Send email
                </ContactMethod>
                <ContactMethod href={`tel:${phoneRaw}`}>
                    <PhoneCallIcon size="1.1rem" /> {phoneFormatted}
                </ContactMethod>
            </ContactMethods>
        </ContactCardContent>
    );
}

export default SwellContactCard;
