import { combineEpics } from 'redux-observable';
import * as Auth from '@features/Auth/epics';
import * as Portal from '@features/Portal/epics';
import * as Profile from '@features/Profile/epics';
import * as Contacts from '@features/Contacts/epics';
import * as Documents from '@features/Documents/epics';
import * as Financials from '@features/Financials/epics';
import * as Tasks from '@features/Tasks/epics';
import * as Utility from '@features/Utility/epics';
import * as Proposals from '@features/Proposals/epics';
import * as MyAccount from '@features/MyAccount/epics';

export default combineEpics(
    ...Object.values(Auth),
    ...Object.values(Portal),
    ...Object.values(Profile),
    ...Object.values(Contacts),
    ...Object.values(Documents),
    ...Object.values(Financials),
    ...Object.values(Tasks),
    ...Object.values(Utility),
    ...Object.values(Proposals),
    ...Object.values(MyAccount),
);
