import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LinkCard from '@components/LinkCard';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import CardValue from '@components/CardValue';
import { infoLoadFailedSelector, infoSelector } from '../selectors';
import { loadInfoAsync } from '../actions';

interface Props {
    showCTA?: boolean;
}

function UtilityCard({ showCTA }: Props) {
    const dispatch = useDispatch();
    const utilityInfo = useSelector(infoSelector);
    const utilityInfoLoadFailed = useSelector(infoLoadFailedSelector);
    const linkCardTarget = showCTA ? '/energy-exchange' : '';

    useEffect(() => {
        dispatch(loadInfoAsync.request());
    }, [dispatch]);

    return (
        <LinkCard size="md" title="My Utility" navigateText="My Energy Exchange" to={linkCardTarget}>
            <CardValue label="Utility" value={utilityInfo?.name} loaderWidth="10.0rem" />
            {utilityInfo?.programName && (
                <CardValue label="Utility Program" value={utilityInfo?.programName} loaderWidth="10.0rem" />
            )}
            {utilityInfoLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadInfoAsync.request())} />}
        </LinkCard>
    );
}

UtilityCard.defaultProps = {
    showCTA: false,
};

export default UtilityCard;
