import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadPreferencesAsync, updatePreferencesAsync } from './actions';

const preferencesStatus = createReducer<'idle' | 'loading' | 'done' | 'error'>('idle')
    .handleAction([loadPreferencesAsync.request, updatePreferencesAsync.request], () => 'loading')
    .handleAction([loadPreferencesAsync.success, updatePreferencesAsync.success], () => 'done')
    .handleAction([loadPreferencesAsync.failure, updatePreferencesAsync.failure], () => 'error')
    .handleAction(resetContext, () => 'idle');

const preferencesInitial = { allowCustomerReference: false };
const preferences = createReducer<Api.PreferencesDto>(preferencesInitial)
    .handleAction([loadPreferencesAsync.success, updatePreferencesAsync.success], (_state, action) => action.payload)
    .handleAction(resetContext, () => preferencesInitial);

const myAccountReducer = combineReducers({
    preferencesStatus,
    preferences,
});

export default myAccountReducer;
export type MyAccountReducerState = ReturnType<typeof myAccountReducer>;
