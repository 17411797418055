import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import * as Feather from 'react-feather';
import FooterLogo from '@components/FooterLogo';
import DynamicMenu from './DynamicMenu';
import Logo from './Logo';

const ContainerWrapper = styled.div(() => [
    tw`flex justify-center min-h-full bg-app-surface`,
    'padding-top: max(env(safe-area-inset-top), 0.5rem);',
]);

const Container = tw.div`flex flex-col h-full container`;
const Header = tw.div`flex justify-center items-center lg:hidden bg-app-surface`;
const LogoContainer = tw.div`p-2 mt-4 lg:mt-0 text-brand-primary`;

const ToggleButton = styled.button(() => [
    tw`mx-2 lg:my-2 p-4`,
    tw`rounded-full hocus:outline-none shadow-md`,
    tw`bg-brand-white ring-2 ring-brand-light`,
    tw`hover:ring-brand-primary`,
    tw`active:text-brand-white active:bg-brand-dark active:ring-brand-light`,
    tw`fixed top-0 right-4 z-30`,
    tw`lg:hidden`,
    'top: max(env(safe-area-inset-top), 0.5rem);',
]);

const Content = styled.div(() => [tw`flex flex-grow`]);

interface MenuItemsProps {
    isVisible: boolean;
}

const NavMenu = styled.div((props: MenuItemsProps) => [
    tw`bg-brand-white lg:bg-app-surface`,
    props.isVisible ? tw`fixed top-0 left-0 z-20 lg:static` : tw`hidden lg:block`,
    tw`w-full h-full lg:w-80`,
]);

const NavLogoContainer = styled.div(() => [
    tw`flex justify-center`,
    tw`h-24 p-2 lg:my-1`,
    tw`text-app-logo`,
    'margin-top: max(env(safe-area-inset-top), 1.5rem);',
]);

const MenuItems = tw.div`lg:py-4 lg:px-2 lg:bg-brand-white lg:rounded-md lg:shadow-md flex flex-col items-stretch`;

const Footer = tw.div`flex text-brand-muted fill-current mx-auto mt-4 mb-4 text-xs flex-col space-y-2 text-center`;
const FooterLink = styled.a(() => [tw`underline hover:text-brand-primary`, `text-decoration-style: dotted;`]);
const Page = tw.div`h-full w-full px-4 pb-4 bg-app-surface lg:pt-24`;

interface Props {
    children: React.ReactNode;
}

function NavigationFrame({ children }: Props) {
    const [isMenuToggled, setMenuToggled] = useState(false);

    return (
        <ContainerWrapper>
            <Container>
                <Header>
                    <LogoContainer>
                        <Logo />
                    </LogoContainer>
                    <ToggleButton hidden={isMenuToggled} onClick={() => setMenuToggled(true)}>
                        <Feather.Menu />
                    </ToggleButton>
                </Header>
                <Content>
                    <NavMenu isVisible={isMenuToggled}>
                        <ToggleButton onClick={() => setMenuToggled(false)}>
                            <Feather.X />
                        </ToggleButton>
                        <NavLogoContainer>
                            <Logo />
                        </NavLogoContainer>
                        <MenuItems>
                            <DynamicMenu onItemSelected={() => setMenuToggled(false)} />
                        </MenuItems>
                    </NavMenu>
                    <Page>{children}</Page>
                </Content>
                <Footer>
                    <FooterLogo />
                    <span>&copy; 2022 Swell Energy | CSLB Lic # 1021183</span>
                    <span>
                        <FooterLink href="https://swellenergy.com/privacy-policy" target="_blank" rel="noreferrer">
                            Privacy &amp; Legal
                        </FooterLink>
                    </span>
                </Footer>
            </Container>
        </ContainerWrapper>
    );
}

export default NavigationFrame;
