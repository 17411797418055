import SkeletonLoader from 'tiny-skeleton-loader-react';
import tw, { styled } from 'twin.macro';

const Container = tw.div`flex flex-col py-2 w-full`;
const Name = styled.span((props: { error?: boolean }) => [
    tw`font-heading text-sm`,
    props.error ? tw`text-brand-dark` : tw`text-brand-muted`,
]);
const Value = styled.span((props: { error?: boolean }) => [props.error ? tw`text-brand-dark` : tw`text-brand-black`]);

interface Props {
    label: string;
    value?: string;
    loaderWidth?: string;
    error?: boolean;
}

function CardValue({ label, value, loaderWidth, error }: Props) {
    return (
        <Container>
            <Name error={error}>{label}</Name>
            {!value && loaderWidth && <SkeletonLoader width={loaderWidth} height="1.5rem" />}
            {value && <Value error={error}>{value}</Value>}
        </Container>
    );
}

CardValue.defaultProps = {
    value: undefined,
    loaderWidth: undefined,
    error: undefined,
};

export default CardValue;
