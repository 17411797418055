import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import moment from 'moment';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import LinkCard from '@components/LinkCard';
import CardValue from '@components/CardValue';
import { ESAStatusDto } from '@services/api';
import { esaStatusSelector } from '../selectors';

const Stats = tw.div`flex flex-col justify-around items-start flex-grow`;

const getNavigateText = (isPastDue?: boolean) => {
    return isPastDue ? 'Go to payment portal' : 'View account';
};

const getStatusMessage = (
    isPastDue: boolean,
    daysDelinquent: number,
    isACH: boolean,
    inGracePeriod: boolean,
): string => {
    if (isPastDue && !inGracePeriod) {
        if (daysDelinquent > 10) {
            return 'We have not received your payment yet and we may have options available for you.  Please call us at (844) 588-9522 to see if they may work for you.';
        }
        if (isACH) {
            return 'We were unable to draft your payment and your account is past due.  Please make your payment within 10 days of the due date to avoid late fees or contact us at (844) 588-9522 to update your account information.';
        }
        return 'We have not received your payment and your account is past due.  Please make your payment within 10 days of the due date to avoid late fees or call us at (844) 588-9522 to submit your payment and set up auto-pay.';
    }
    if (isACH) {
        return 'Your payment will be drafted by your next due date. Please note it may take up to 24 hours to reflect that your payment has been received.';
    }
    return 'Sign into our payment portal to make your payment.  Please note it may take up to 24 hours to reflect that your payment has been received.';
};

interface Props {
    cardTitle: string;
}

function ESAStatusCard({ cardTitle }: Props) {
    const esaStatus = useSelector(esaStatusSelector);
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const getValidAmount = (val?: number) => (typeof val === 'number' ? formatter.format(val) : undefined);

    const getPastDueAmount = (status: ESAStatusDto) => {
        if (status.isAccountCurrent || status.isInDelinquencyOnboardingGracePeriod) return 0;
        return status.pastDueAmount || 0;
    };

    const getCurrentBalance = (status: ESAStatusDto) => {
        return (
            (status.nextPaymentAmount || 0) + (status.isInDelinquencyOnboardingGracePeriod ? status.pastDueAmount : 0)
        );
    };
    let nextPaymentDate: string | undefined;

    if (esaStatus?.nextPaymentDate) {
        nextPaymentDate = moment.utc(esaStatus.nextPaymentDate).format('MMM D, YYYY');
    }

    const getValuesToShow = (status: ESAStatusDto) => {
        if (getPastDueAmount(status) === 0 && getCurrentBalance(status) === 0) {
            return (
                <>
                    <CardValue label="Amount Due" value={getValidAmount(0)} loaderWidth="8.0rem" />
                    <CardValue label="Next Payment Due" value="No Payment Due" loaderWidth="12.0rem" />
                </>
            );
        }

        if (getPastDueAmount(status) > 0) {
            return (
                <>
                    <CardValue
                        label="Past Due Amount"
                        value={getValidAmount(getPastDueAmount(status))}
                        loaderWidth="8.0rem"
                        error
                    />
                    {getCurrentBalance(status) > 0 && (
                        <>
                            <CardValue
                                label="Current Charges"
                                value={getValidAmount(getCurrentBalance(status))}
                                loaderWidth="8.0rem"
                            />
                            <CardValue
                                label="Amount Due"
                                value={getValidAmount(getCurrentBalance(status) + getPastDueAmount(status))}
                                loaderWidth="8.0rem"
                            />
                        </>
                    )}
                    {nextPaymentDate && (
                        <CardValue label="Next Payment Due" value={nextPaymentDate} loaderWidth="12.0rem" />
                    )}
                </>
            );
        }

        return (
            <>
                <CardValue label="Amount Due" value={getValidAmount(getCurrentBalance(status))} loaderWidth="8.0rem" />
                {nextPaymentDate && (
                    <CardValue label="Next Payment Due" value={nextPaymentDate} loaderWidth="12.0rem" />
                )}
            </>
        );
    };

    return (
        <LinkCard
            size="md"
            href="http://billing.accountservicing.com/"
            title={cardTitle}
            navigateText={getNavigateText(!esaStatus?.isAccountCurrent)}
        >
            <Stats>
                {esaStatus && (
                    <>
                        {getValuesToShow(esaStatus)}
                        <p>
                            {getStatusMessage(
                                !esaStatus.isAccountCurrent,
                                esaStatus.daysDelinquent,
                                esaStatus.isACHEnrolled,
                                esaStatus.isInDelinquencyOnboardingGracePeriod,
                            )}
                        </p>
                    </>
                )}
                {!esaStatus && <SkeletonLoader height="15rem" width="100%" />}
            </Stats>
        </LinkCard>
    );
}

export default ESAStatusCard;
