import SwellAbstractBg from '@images/abstract_bg.svg';
import LGAbstractLG from '@images/lg/abstract_bg.svg';
import SwellLogo from '@images/swell.svg';
import LGLogo from '@images/lg/lg-logo.svg';
import SwellDefaultAvatar from '@images/default-swell.png';
import LGDefaultAvatar from '@images/lg/default-lg.png';
import ContactLogo from '@images/contact-logo.png';

const SwellTheme = {
    themeKey: 'swell',
    showPoweredBy: false,
    abstractBG: SwellAbstractBg,
    brandColor: '#2b96f4',
    footerLogo: SwellLogo,
    avatar: SwellDefaultAvatar,
    showThinQCardOnComplete: false,
    contactUsLogo: ContactLogo,
};

type ThemeConfig = typeof SwellTheme;

const LGTheme: ThemeConfig = {
    themeKey: 'lg',
    showPoweredBy: true,
    abstractBG: LGAbstractLG,
    brandColor: '#a50034',
    footerLogo: LGLogo,
    avatar: LGDefaultAvatar,
    showThinQCardOnComplete: true,
    contactUsLogo: SwellLogo,
};

function getAppTheme() {
    switch (process.env.REACT_APP_THEME) {
        case 'LG':
            return LGTheme;
        default:
            return SwellTheme;
    }
}

const AppTheme = getAppTheme();
export default AppTheme;
