import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import LinkCard from '@components/LinkCard';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import { AppSchematicDtoWidgetsEnum } from '@services/api';
import { widgetsSelector } from '@features/Portal/selectors';
import DocumentListItem from './DocumentListItem';
import DocumentsSkeleton from './DocumentsSkeleton';
import OnDemandDocuments from './OnDemandDocuments';
import { documentsLoadFailedSelector, documentsSelector } from '../selectors';
import { loadAsync } from '../actions';

const Page = tw.div`flex flex-wrap`;
const Container = tw.div`flex flex-col`;
const DocumentsEmpty = tw.div`flex flex-col h-full justify-center items-center py-8`;
const EmptyTitle = tw.span`text-lg text-brand-muted leading-loose`;
const EmptyText = tw.span`text-sm text-brand-muted leading-loose`;
const ContactNav = tw(
    Link,
)`my-2 px-4 py-2 flex flex-row items-center border-b text-brand-muted hover:text-brand-black bg-brand-white hover:bg-brand-light focus:outline-none`;

function Documents() {
    const dispatch = useDispatch();
    const documents = useSelector(documentsSelector);
    const documentsLoadFailed = useSelector(documentsLoadFailedSelector);
    const appWidgets = useSelector(widgetsSelector);

    useEffect(() => {
        dispatch(loadAsync.request());
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>Documents | Compass by Swell Energy</title>
            </Helmet>
            <LinkCard size="lg" title="My Documents">
                {!documents && (
                    <Container>
                        <DocumentsSkeleton />
                        <DocumentsSkeleton />
                        <DocumentsSkeleton />
                        <DocumentsSkeleton />
                        <DocumentsSkeleton />
                        {documentsLoadFailed && <LoadFailedOverlay onRetry={() => dispatch(loadAsync.request())} />}
                    </Container>
                )}

                {documents && documents.length > 0 && (
                    <Container>
                        {documents.map(d => (
                            <DocumentListItem key={`doc-${d.id}`} document={d} />
                        ))}
                    </Container>
                )}

                {documents && documents.length === 0 && (
                    <DocumentsEmpty>
                        <EmptyTitle>There are no documents available for you to view yet.</EmptyTitle>
                        <EmptyText>Have a question for us?</EmptyText>
                        <ContactNav to="/contact">Get in touch</ContactNav>
                    </DocumentsEmpty>
                )}
            </LinkCard>

            {appWidgets && appWidgets.includes(AppSchematicDtoWidgetsEnum.RequestPaidInFullReceipt) && (
                <OnDemandDocuments />
            )}
        </Page>
    );
}

export default Documents;
