import { useEffect, useState } from 'react';
import tw, { styled } from 'twin.macro';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ReactComponent as LogoSource } from '@images/logo.svg';
import { errorSelector, isLoggedInSelector, passwordUpdateRequiredSelector } from '@features/Auth/selectors';
import { checkTokenAsync } from '@features/Auth/actions';
import { accessTokenService } from '@services/index';
import AppTheme from '@root/AppTheme';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword';
import SetPassword from './SetPassword';
import LoginWithToken from './LoginWithToken';
import SelectContext from './SelectContext';
import LogOut from './LogOut';
import PasswordUpdateRequired from './PasswordUpdateRequired';

const Wrapper = styled.div(() => [
    tw`min-h-full w-full bg-cover bg-fixed`,
    tw`flex flex-row px-2`,
    `background-image: url(${AppTheme.abstractBG});`,
]);
const LogoWrapper = tw.div`flex justify-center items-center flex-grow m-auto`;
const Logo = tw(LogoSource)`text-brand-white h-14 mb-2`;

function Login() {
    const dispatch = useDispatch();
    const [showSplash, setShowSplash] = useState(true);
    const loginError = useSelector(errorSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);
    const passwordUpdateRequired = useSelector(passwordUpdateRequiredSelector);

    useEffect(() => {
        const checkExistingToken = async () => {
            const validToken = accessTokenService.isTokenValid();

            if (!validToken) {
                setShowSplash(false);
                return;
            }

            const token = await accessTokenService.getToken();
            const isPasswordChangeRequired = await accessTokenService.isPasswordChangeRequired();
            dispatch(checkTokenAsync.request({ token, isPasswordChangeRequired }));
        };

        // If a token is in the URL, let's ignore the current session state of the app
        // Reduces headaches for Swell employees who log in as homeowner to multiple homeowners
        // when they forget to log out.
        if (window.location.href.includes('/login-with-token')) {
            setShowSplash(false);
        } else {
            checkExistingToken();
        }
    }, [dispatch]);

    useEffect(() => {
        if (loginError || isLoggedIn) {
            setShowSplash(false);
        }
    }, [loginError, isLoggedIn]);

    return (
        <>
            <Helmet>
                <title>Login | Compass by Swell Energy</title>
            </Helmet>
            {showSplash && (
                <Wrapper>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </Wrapper>
            )}

            {!showSplash && (
                <Wrapper>
                    <Router>
                        {!isLoggedIn && (
                            <Switch>
                                <Route path="/forgot-password" component={ForgotPassword} />
                                <Route path="/set-password/:nonce" component={SetPassword} />
                                <Route path="/login-with-token" component={LoginWithToken} />
                                <Route path="/log-out" component={LogOut} />
                                <Route path="/" component={LoginForm} />
                            </Switch>
                        )}
                        {isLoggedIn && (
                            <Switch>
                                <Route path="/log-out" component={LogOut} />
                                {passwordUpdateRequired && <Route component={PasswordUpdateRequired} />}
                                {!passwordUpdateRequired && (
                                    <Route>
                                        <SelectContext />
                                    </Route>
                                )}
                            </Switch>
                        )}
                    </Router>
                </Wrapper>
            )}
        </>
    );
}

export default Login;
