import { useCallback, useEffect } from 'react';
import tw, { styled, theme } from 'twin.macro';
import LinkCard from '@components/LinkCard';
import { useSelector, useDispatch } from 'react-redux';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import { preferencesSelector, preferencesStatusSelector } from '../selectors';
import { loadPreferencesAsync, updatePreferencesAsync } from '../actions';

const Container = tw.div`flex flex-col`;
const CheckBoxContainer = tw.label`flex flex-row items-center flex-wrap md:flex-nowrap`;
const CheckBox = styled.input(() => [tw`ml-4 w-5 h-5`, `accent-color: ${theme`colors.brand-primary`}; `]);
const Hint = tw.span`mt-4 text-sm text-brand-muted`;
const CheckBoxLabel = tw.span`text-brand-black w-full max-w-[328px]`;
const CheckBoxSaveText = tw.span`text-brand-black px-2`;

function MyPreferencesCard() {
    const dispatch = useDispatch();
    const preferences = useSelector(preferencesSelector);
    const preferencesStatus = useSelector(preferencesStatusSelector);

    useEffect(() => {
        if (dispatch && preferencesStatus === 'idle') {
            dispatch(loadPreferencesAsync.request());
        }
    }, [dispatch, preferencesStatus]);

    const handleChange = useCallback(
        (isEnabled: boolean) => {
            dispatch(updatePreferencesAsync.request({ allowCustomerReference: isEnabled }));
        },
        [dispatch],
    );

    const isLoading = preferencesStatus === 'idle' || preferencesStatus === 'loading';

    const isError = preferencesStatus === 'error';

    return (
        <LinkCard size="md" title="My Preferences">
            {!isError && (
                <Container>
                    <CheckBoxContainer>
                        <CheckBoxLabel>Can we use you as a customer reference?</CheckBoxLabel>
                        <CheckBox
                            checked={preferences.allowCustomerReference}
                            type="checkbox"
                            onChange={v => handleChange(v.currentTarget.checked)}
                        />
                        <CheckBoxSaveText>{isLoading && 'saving...'}</CheckBoxSaveText>
                    </CheckBoxContainer>

                    <Hint>
                        As a customer reference, we may connect you with other potential customers to share your
                        experience with Swell.
                    </Hint>
                </Container>
            )}
            {isError && <LoadFailedOverlay onRetry={() => dispatch(loadPreferencesAsync.request())} />}
        </LinkCard>
    );
}

export default MyPreferencesCard;
