import { useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import * as Sentry from '@sentry/react';
import { StripeCardElementOptions } from '@stripe/stripe-js';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import ButtonStyleBase from '@components/button';

const PayElementsContainer = tw.div`w-full flex flex-col items-end p-2`;
const CardContainer = tw.div`border border-brand-primary p-3 my-2 rounded w-full`;
const PayButton = styled.button(() => [...ButtonStyleBase, tw`w-full justify-center font-bold`]);

const CARD_OPTIONS: StripeCardElementOptions = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: theme`colors.brand-primary`,
            color: '#000',
            fontWeight: 500,
            fontFamily:
                'Gotham Book,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
                color: '#888',
            },
            '::placeholder': {
                color: '#888',
            },
        },
        invalid: {
            iconColor: theme`colors.input-error`,
            color: theme`colors.input-error`,
        },
    },
};

interface Props {
    total: number;
    disableSubmit: boolean;
    onTokenReceived: (token: string) => void;
}

function CCPaymentForm({ total, disableSubmit, onTokenReceived }: Props) {
    const [canSubmit, setCanSubmit] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const formatter = new Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD' });
    const stripe = useStripe();
    const elements = useElements();

    const submitPayment = async () => {
        try {
            if (!elements || !stripe) {
                return;
            }

            const card = elements.getElement(CardElement);

            if (!card) {
                return;
            }

            setIsProcessing(true);
            const result = await stripe.createToken(card);

            if (result.token) {
                onTokenReceived(result.token.id);
            } else {
                throw result.error;
            }
        } catch (err) {
            Sentry.captureException(err);
        }

        setIsProcessing(false);
    };

    return (
        <PayElementsContainer>
            <CardContainer>
                <CardElement options={CARD_OPTIONS} onChange={evt => setCanSubmit(evt.complete)} />
            </CardContainer>
            <PayButton
                type="button"
                disabled={!canSubmit || isProcessing || disableSubmit}
                onClick={() => submitPayment()}
            >
                Pay {formatter.format(total)} Now
            </PayButton>
        </PayElementsContainer>
    );
}

export default CCPaymentForm;
