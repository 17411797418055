import { useMemo } from 'react';
import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import LinkCard from '@components/LinkCard';
import { esaStatusLoadFailedSelector, esaStatusSelector } from '@features/Financials/selectors';
import ESASkeletonCard from './ESASkeletonCard';

const Container = tw.div`flex flex-col space-y-6 mb-4 text-left`;
const AnchorLink = tw.a`text-brand-primary hover:underline`;

function RefinanceInfoCard() {
    return (
        <LinkCard size="md">
            <Container>
                <p>Thinking about refinancing or selling?</p>
                <p>
                    Don&apos;t forget to call us at <AnchorLink href="tel:8445889522">(844) 588-9522</AnchorLink> to
                    ensure your energy system is not impacted.
                </p>
            </Container>
        </LinkCard>
    );
}

function EnrollInACHCard() {
    return (
        <LinkCard size="md" href="https://billing.accountservicing.com/" navigateText="View account">
            <Container>
                <p>Save $10 on your monthly bill by enrolling in auto pay today!</p>
            </Container>
        </LinkCard>
    );
}

function ESARotatorCard() {
    const coinFlip = useMemo(() => Math.random() > 0.5, []);
    const esaStatus = useSelector(esaStatusSelector);
    const esaStatusFailed = useSelector(esaStatusLoadFailedSelector);
    const shouldShowCard = !!esaStatus || !!esaStatusFailed;

    return (
        <>
            {shouldShowCard && coinFlip && esaStatus && !esaStatus?.isACHEnrolled ? (
                <EnrollInACHCard />
            ) : (
                <RefinanceInfoCard />
            )}
            {!shouldShowCard && <ESASkeletonCard />}
        </>
    );
}

export default ESARotatorCard;
