import { createGlobalStyle } from 'styled-components';

const StylesBase = createGlobalStyle`
  html, body, #root {
    height: 100%;
    width: 100%;
  }
`;

export default StylesBase;
