import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { Api } from '@services/index';
import { resetContext } from '@features/Auth/actions';
import { loadTasksAsync } from './actions';

const tasks = createReducer<Api.TaskDto[] | null>(null)
    .handleAction(loadTasksAsync.success, (_state, action) => action.payload)
    .handleAction(resetContext, () => null);

const tasksLoadFailed = createReducer(false)
    .handleAction([loadTasksAsync.request, resetContext], () => false)
    .handleAction(loadTasksAsync.failure, () => true);

const tasksReducer = combineReducers({
    tasks,
    tasksLoadFailed,
});

export default tasksReducer;
export type TasksState = ReturnType<typeof tasksReducer>;
