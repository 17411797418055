import { useState } from 'react';
import tw, { styled } from 'twin.macro';
import LinkCard from '@components/LinkCard';
import ButtonStyleBase from '@components/button';
import { ReactComponent as SwellLogo } from '@images/swell.svg';
import AccountServicingImg from '../assets/acctservicing.png';

const Container = tw.div`flex flex-col space-y-6 mb-4 text-left`;
const ModalOverlay = tw.div`bg-brand-black bg-opacity-70 z-40 fixed top-0 left-0 bottom-0 right-0 flex`;
const ModalContent = tw.div`bg-brand-white rounded shadow relative w-full max-h-screen m-4 p-8 md:m-auto md:w-4/5 md:p-4 lg:w-3/4 overflow-y-auto`;
const Button = styled.button(() => [
    ...ButtonStyleBase,
    tw`absolute m-0 right-0 transform -translate-x-8 md:-translate-x-4`,
]);
const SwellLogoColor = styled(SwellLogo)(() => [tw`fill-current text-brand-primary h-12`]);
const Title = tw.h4`text-brand-primary text-2xl my-4 md:mb-8`;
const Subheading = tw.h5`text-brand-primary text-lg uppercase`;
const StepsContainer = tw.div`flex flex-col-reverse md:flex-row items-center`;
const Step = tw.div`w-full flex flex-col items-start space-y-4 text-sm`;
const StepLink = tw.a`text-brand-primary hover:underline`;
const List = tw.ul`list-disc ml-8`;

function ESAOnboardingCard() {
    const [modalOpen, setModalOpen] = useState(false);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    return (
        <>
            <LinkCard size="md" title="My Account" onClick={handleOpenModal} navigateText="Learn More">
                <Container>
                    <p>
                        Welcome to Swell Energy! We are working hard to enable your system. A payment notice will be
                        sent shortly after completion.
                    </p>
                    <p>
                        Once you receive your payment notice, follow these instructions to register in the billing
                        portal.
                    </p>
                </Container>
            </LinkCard>
            {modalOpen && (
                <ModalOverlay onClick={handleCloseModal}>
                    <ModalContent onClick={evt => evt.stopPropagation()}>
                        <Button type="button" onClick={handleCloseModal}>
                            Close
                        </Button>
                        <SwellLogoColor />
                        <Title>How to pay for your Swell Energy system</Title>
                        <StepsContainer>
                            <Step>
                                <Subheading>Registration Information</Subheading>
                                <p>Setting up your account is easy!</p>
                                <p>
                                    To register your account, make sure you have your first statement ready and click on{' '}
                                    <StepLink href="https://billing.accountservicing.com" target="_blank">
                                        https://billing.accountservicing.com
                                    </StepLink>
                                    .
                                </p>
                                <List>
                                    <li>
                                        Enter your <strong>Agreement Number</strong>
                                    </li>
                                    <li>
                                        Enter your <strong>Invoice Number</strong>
                                    </li>
                                    <li>
                                        Enter the <strong>5-digit Billing Zip Code</strong>
                                    </li>
                                    <li>
                                        Click <strong>Register</strong>
                                    </li>
                                </List>
                                <p>
                                    <strong>Reminder:</strong> You can set up autopay on your account and receive a $10
                                    statement credit!
                                </p>
                            </Step>
                            <Step>
                                <img
                                    src={AccountServicingImg}
                                    alt="Screenshot of Account Servicing Login Page with arrows pointing to the input fields for information"
                                />
                            </Step>
                        </StepsContainer>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
}

export default ESAOnboardingCard;
