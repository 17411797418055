import tw from 'twin.macro';
import CardValue from '@components/CardValue';
import Card from '@components/Card';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Stats = tw.div`flex flex-col items-start flex-grow`;

function ProposalSkeletonCard() {
    return (
        <Card size="md">
            <SkeletonLoader height="2.2rem" width="12rem" />
            <Stats>
                <CardValue label="Payment Type" loaderWidth="6rem" />
                <CardValue label="Created" loaderWidth="8rem" />
            </Stats>
        </Card>
    );
}

export default ProposalSkeletonCard;
