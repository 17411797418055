import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Sentry from '@sentry/react';
import tw, { styled } from 'twin.macro';
import * as yup from 'yup';
import FormikInputBox from '@components/FormikInputBox';
import { useDispatch } from 'react-redux';
import { CreateTokenBankAccountData, loadStripe, Stripe } from '@stripe/stripe-js';

import SkeletonLoader from 'tiny-skeleton-loader-react';
import { ChevronsDown } from 'react-feather';
import ButtonStyleBase from '@components/button';
import Services from '@services/index';
import VerificationOptionButton from './VerificationOptionButton';
import { loadFinancialsAsync } from '../actions';

interface FormData {
    fullName: string;
    routing: string;
    account: string;
    type: 'individual' | 'company' | '';
}

const initialValues: FormData = {
    fullName: '',
    routing: '',
    account: '',
    type: 'individual',
};

const Button = styled.button(() => [...ButtonStyleBase]);

const Title = tw.p`text-sm font-bold text-brand-black`;
const FormWrapper = tw.div`flex flex-col items-center w-full mb-2 space-y-4 mt-4`;

const HelpText = tw.p`text-xs pb-4 leading-relaxed text-brand-muted text-center`;
const ErrorText = tw.p`text-xs m-2 p-2 leading-relaxed text-input-error`;

const DefaultErrorMessage =
    'We had a problem manually verifying your bank account. Please try again or contact us if you need assistance.';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function StartManualBankVerification() {
    const [stripe, setStripe] = useState<Stripe | null>();
    const [showForm, setShowForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const loadStripeAsync = async () => {
            const stripeInstance = await stripePromise;
            setStripe(stripeInstance);
        };

        loadStripeAsync();
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            fullName: yup.string().required("Account holder's full name is required"),
            routing: yup
                .string()
                .required('Please provide the full 9-digit routing number')
                .matches(/\d{9}/, 'Please provide the full 9-digit routing number'),
            account: yup.string().required('Account number if required'),
            type: yup.string().required('Please select the type of account'),
        }),
        validateOnMount: true,
        onSubmit: async (values, helpers) => {
            try {
                if (!stripe) return;

                setErrorMessage('');

                const tokenData: CreateTokenBankAccountData = {
                    country: 'US',
                    currency: 'usd',
                    routing_number: values.routing,
                    account_number: values.account,
                    account_holder_name: values.fullName,
                    account_holder_type: values.type,
                };

                const token = await stripe.createToken('bank_account', tokenData);

                if (token.error) {
                    throw token.error;
                }

                await Services.financeApi.financeControllerPostManualVerifyToken({
                    token: token.token.id,
                });

                dispatch(loadFinancialsAsync.request({ forceReset: true }));
            } catch (ex) {
                Sentry.captureException(ex);
                setErrorMessage(DefaultErrorMessage);
            }

            helpers.setSubmitting(false);
        },
    });

    return (
        <>
            {!showForm && (
                <VerificationOptionButton
                    onPress={() => setShowForm(true)}
                    text="Manually verify your bank account by validating two micro deposits we make into your account. Micro deposits may take up to 3 business days to appear."
                    icon={ChevronsDown}
                    iconText="Micro Deposits"
                />
            )}
            {showForm && (
                <>
                    <Title>Verify Manually</Title>
                    {!stripe && <SkeletonLoader height="8rem" />}
                    {stripe && (
                        <FormWrapper>
                            <FormikInputBox
                                type="text"
                                name="fullName"
                                placeholder="Full Name on Account"
                                hasError={Boolean(formik.touched.fullName) && Boolean(formik.errors.fullName)}
                                formik={formik}
                            />
                            <FormikInputBox
                                type="text"
                                name="routing"
                                placeholder="Routing Number"
                                hasError={Boolean(formik.touched.routing) && Boolean(formik.errors.routing)}
                                formik={formik}
                            />
                            <FormikInputBox
                                type="text"
                                name="account"
                                placeholder="Account Number"
                                hasError={Boolean(formik.touched.account) && Boolean(formik.errors.account)}
                                formik={formik}
                            />
                        </FormWrapper>
                    )}
                    <HelpText>
                        Your bank account information is sent directly to our payment processor. We do not receive or
                        store this information directly.
                    </HelpText>
                    {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
                    <Button
                        type="button"
                        disabled={!formik.isValid || formik.isSubmitting}
                        onClick={() => formik.handleSubmit()}
                    >
                        <ChevronsDown />
                        Make Deposits
                    </Button>
                </>
            )}
        </>
    );
}

export default StartManualBankVerification;
