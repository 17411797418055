import { useRef } from 'react';
import { FilePlus, XCircle } from 'react-feather';
import tw, { styled } from 'twin.macro';
import ButtonStyleBase from '@components/button';
import { TaskDocumentDto } from '@services/api';
import { PendingUpload } from './view-models';

interface MissingDocumentContainerProps {
    status:
        | 'ready'
        | 'waiting'
        | 'merging'
        | 'preparing'
        | 'uploading'
        | 'finalizing'
        | 'complete'
        | 'error'
        | undefined;
}

const MissingDocumentContainer = styled.form((props: MissingDocumentContainerProps) => [
    tw`flex flex-col ml-2 mb-8 last:mb-0 space-y-2`,
    tw`border-l-4 pl-2`,
    !props.status ? tw`border-brand-light` : '',

    props.status === 'ready' || props.status === 'waiting' ? tw`border-brand-muted` : '',

    props.status === 'merging' ||
    props.status === 'preparing' ||
    props.status === 'uploading' ||
    props.status === 'finalizing'
        ? tw`border-brand-primary`
        : '',

    props.status === 'complete' ? tw`border-brand-success` : '',

    props.status === 'error' ? tw`border-input-error` : '',
]);
const BrowseIcon = tw(FilePlus)`text-brand-muted mr-2`;
const RemoveFileButton = tw.button`text-brand-muted hover:text-brand-black hocus:outline-none disabled:opacity-0 disabled:cursor-pointer mr-1`;
const PendingFile = tw.span`text-sm text-brand-black`;
const UploadLabel = styled.label(() => [...ButtonStyleBase, tw`w-full`]);
const UploadStatus = tw.span`text-xs italic text-brand-muted`;
const DocName = tw.span`text-sm text-brand-black`;
const FilePickerInput = tw.input`hidden`;
const SelectedFile = tw.div`flex flex-row items-center`;
const PagesContainer = tw.div`flex flex-col space-y-8`;
const PreviewImage = tw.img`max-w-xs max-h-64`;

interface MissingDocumentProps {
    document: TaskDocumentDto;
    pendingUpload?: PendingUpload;
    onFileSelected: (documentId: string, selectedFile?: File) => void;
    onFileRemoved: (documentId: string, index: number) => void;
}

function MissingDocument({ document, pendingUpload, onFileSelected, onFileRemoved }: MissingDocumentProps) {
    const formRef = useRef<HTMLFormElement>(null);
    const onSelectFile = (file: File | null | undefined) => {
        onFileSelected?.(document.id, file ?? undefined);
        formRef.current?.reset();
    };

    const onRemoveFile = (index: number) => {
        onFileRemoved?.(document.id, index);
        formRef.current?.reset();
    };

    const getStatusText = () => {
        switch (pendingUpload?.state) {
            case 'ready':
                return 'Ready to Upload';
            case 'waiting':
                return 'Waiting to Upload';
            case 'merging':
                return 'Merging pages';
            case 'preparing':
                return 'Preparing...';
            case 'uploading':
                return `Uploading (${pendingUpload?.progress}%)...`;
            case 'finalizing':
                return 'Finalizing upload...';
            case 'complete':
                return 'Complete';
            case 'error':
                return 'Upload Failed';
            default:
                return '';
        }
    };

    const hasFile = !!pendingUpload?.files?.length && pendingUpload?.files?.length > 0;

    return (
        <MissingDocumentContainer ref={formRef} status={pendingUpload?.state}>
            <DocName>{document.name}</DocName>
            <UploadStatus>{getStatusText()}</UploadStatus>
            {!hasFile && (
                <UploadLabel htmlFor={`file-${document.id}`}>
                    <BrowseIcon />
                    <span>Select a file...</span>
                </UploadLabel>
            )}
            {hasFile && (
                <PagesContainer>
                    {pendingUpload.files.map((selectedFile, idx) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={idx}>
                            {pendingUpload.previews[idx] && (
                                <PreviewImage
                                    src={pendingUpload.previews[idx]}
                                    alt={`Document preview of ${selectedFile.name}`}
                                />
                            )}
                            <SelectedFile>
                                <RemoveFileButton
                                    type="reset"
                                    onClick={() => onRemoveFile(idx)}
                                    disabled={pendingUpload?.state !== 'ready'}
                                >
                                    <XCircle />
                                </RemoveFileButton>
                                <PendingFile>{selectedFile.name}</PendingFile>
                            </SelectedFile>
                        </div>
                    ))}
                    {pendingUpload.supportsMerging && pendingUpload.state === 'ready' && (
                        <UploadLabel htmlFor={`additional-page-${document.id}`}>
                            <BrowseIcon />
                            <span>Add another page...</span>
                        </UploadLabel>
                    )}
                </PagesContainer>
            )}
            <FilePickerInput
                id={`file-${document.id}`}
                type="file"
                onChange={e => onSelectFile(e.target.files?.item(0))}
            />
            <FilePickerInput
                id={`additional-page-${document.id}`}
                type="file"
                accept="image/*"
                onChange={e => onSelectFile(e.target.files?.item(0))}
            />
        </MissingDocumentContainer>
    );
}

MissingDocument.defaultProps = {
    pendingUpload: undefined,
};

export default MissingDocument;
