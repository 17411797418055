import { useDispatch } from 'react-redux';
import tw, { styled } from 'twin.macro';
import ButtonStyleBase from '@components/button';
import { ReactComponent as Logo } from '@images/logo.svg';
import { loadAppSchematicAsync } from '../actions';

const Container = tw.div`h-full w-full flex bg-brand-light`;
const ErrorCard = tw.div`bg-brand-white m-auto border rounded shadow p-4 w-4/5 md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5`;
const CompassLogo = tw(Logo)`h-10 text-app-logo mx-auto my-4`;
const Title = tw.h1`font-heading text-xl mb-4 text-center`;
const Message = tw.p`leading-loose text-center`;
const Button = styled.button(() => [...ButtonStyleBase, tw`mx-auto my-4`]);

function FatalError() {
    const dispatch = useDispatch();
    const loadSchematic = () => {
        dispatch(loadAppSchematicAsync.request());
    };

    return (
        <Container>
            <ErrorCard>
                <CompassLogo />
                <Title>We ran into a problem</Title>
                <Message>
                    There was a problem on our side while trying to process your request. Our engineers have been
                    notified automatically. Please try again later.
                </Message>
                <Button type="button" title="Retry" onClick={() => loadSchematic()}>
                    Retry
                </Button>
            </ErrorCard>
        </Container>
    );
}

export default FatalError;
