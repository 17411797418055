import { useEffect } from 'react';
import tw from 'twin.macro';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import LinkCard from '@components/LinkCard';
import LoadFailedOverlay from '@components/LoadFailedOverlay';
import BillingInformation from './BillingInformation';
import MakePayment from './MakePayment';
import { financialsLoadFailedSelector } from '../selectors';
import { loadFinancialsAsync } from '../actions';

const Page = tw.div`flex flex-wrap`;

function FinancialsPage() {
    const dispatch = useDispatch();
    const financialsLoadFailed = useSelector(financialsLoadFailedSelector);

    useEffect(() => {
        dispatch(loadFinancialsAsync.request({}));
    }, [dispatch]);

    return (
        <Page>
            <Helmet>
                <title>Project Financials | Compass by Swell Energy</title>
            </Helmet>
            <LinkCard size="lg" title="My Project">
                <BillingInformation />
                {financialsLoadFailed && (
                    <LoadFailedOverlay onRetry={() => dispatch(loadFinancialsAsync.request({}))} />
                )}
            </LinkCard>

            <LinkCard size="md" title="Make a Payment">
                <MakePayment />
                {financialsLoadFailed && (
                    <LoadFailedOverlay onRetry={() => dispatch(loadFinancialsAsync.request({}))} />
                )}
            </LinkCard>
        </Page>
    );
}

export default FinancialsPage;
