import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { usePlaidLink, PlaidLinkOptions } from 'react-plaid-link';

interface Props {
    token: string;
    onSuccess: (plaidToken: string, selectedAccountId: string) => void;
}

function PlaidLink({ token, onSuccess }: Props) {
    const config: PlaidLinkOptions = {
        token,
        onSuccess: (tk, metadata) => {
            onSuccess(tk, metadata.accounts[0].id);
        },
    };

    const { open, ready, error } = usePlaidLink(config);

    useEffect(() => {
        if (!ready) {
            return;
        }

        open();
    }, [ready, open]);

    useEffect(() => {
        if (error) {
            Sentry.captureException(error);
        }
    }, [error]);

    return null;
}

export default PlaidLink;
