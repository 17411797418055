import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import tw, { styled } from 'twin.macro';
import { Mail } from 'react-feather';
import * as Yup from 'yup';
import ButtonStyleBase from '@components/button';
import LinkCard from '@components/LinkCard';
import { AppSchematicDtoWidgetsEnum } from '@services/api';
import { demographicsLoadFailedSelector, demographicsSelector } from '@features/Profile/selectors';
import { widgetsSelector } from '@features/Portal/selectors';
import { loadDemographicsAsync } from '@features/Profile/actions';
import api from '@services/index';

interface InputBoxProps {
    disabled?: boolean;
    hasError: boolean;
    isFocused?: boolean;
}

const InputBox = styled.input((props: InputBoxProps) => [
    tw`mt-1 mb-4 pl-4 py-2 max-w-full w-72 border border-brand-muted outline-none bg-brand-white bg-opacity-50 rounded flex flex-row`,
    props.disabled ? tw`disabled:bg-brand-muted disabled:border-brand-muted` : '',
    props.isFocused ? tw`border-input-focus ring ring-input-focus ring-opacity-25` : '',
    props.hasError ? tw`border-input-error ring-input-error ring-opacity-25` : '',
]);

const Para = tw.p`mb-4`;
const Title = tw.span`font-heading text-sm text-brand-muted`;
const DocumentRequest = tw.div`my-4`;
const MailIcon = tw(Mail)`mr-2`;
const RequestButton = styled.button(() => [...ButtonStyleBase, tw`mx-auto`]);
const ErrorText = tw.p` m-2 p-2 leading-relaxed text-input-error`;
const SuccessText = tw.p` m-2 p-2 leading-relaxed text-brand-success`;

function OnDemandDocuments() {
    const dispatch = useDispatch();
    const [hasDefaultedEmail, setHasDefaultedEmail] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const profile = useSelector(demographicsSelector);
    const profileLoadFailed = useSelector(demographicsLoadFailedSelector);
    const appWidgets = useSelector(widgetsSelector);
    const canRequestReceipt = appWidgets.includes(AppSchematicDtoWidgetsEnum.RequestPaidInFullReceipt);
    const [requestStatus, setRequestStatus] = useState<'idle' | 'sending' | 'sent' | 'failed'>('idle');

    const validator = Yup.string().required().email();
    const isEmailValid = validator.isValidSync(emailAddress);

    useEffect(() => {
        if (!profile && !profileLoadFailed) {
            dispatch(loadDemographicsAsync.request());
        }
    }, [dispatch, profile, profileLoadFailed]);

    useEffect(() => {
        if (profile && !emailAddress && !hasDefaultedEmail) {
            setEmailAddress(profile.email);
            setHasDefaultedEmail(true);
        }
    }, [emailAddress, profile, hasDefaultedEmail]);

    const sendReceipt = async () => {
        if (!isEmailValid) {
            return;
        }

        setRequestStatus('sending');

        try {
            const result = await api.financeApi.financeControllerPostSendPaidInFullStatement({ emailAddress });
            setRequestStatus(result.status < 300 ? 'sent' : 'failed');
        } catch {
            setRequestStatus('failed');
        }
    };

    return (
        <LinkCard size="md" title="Documents on Demand">
            <Para>Request documents and statements delivered directly to your inbox, instantly.</Para>
            <Title>Send to:</Title>
            <InputBox
                type="email"
                inputMode="email"
                autoComplete="email"
                hasError={!isEmailValid}
                placeholder="Recipient email address"
                value={emailAddress}
                onChange={e => setEmailAddress(e.target.value)}
            />

            <hr />

            {canRequestReceipt && (
                <DocumentRequest>
                    <Title>Payment Overview</Title>
                    <Para>Recap of your project&apos;s cost and any payments made to Swell to date.</Para>
                    {requestStatus === 'failed' && (
                        <ErrorText>We ran into a problem preparing your document.</ErrorText>
                    )}
                    {requestStatus === 'sent' && <SuccessText>Your requested document is on the way!</SuccessText>}
                    <RequestButton
                        disabled={requestStatus === 'sending' || !isEmailValid}
                        onClick={() => sendReceipt()}
                    >
                        <MailIcon size="1.1rem" /> Request
                    </RequestButton>
                </DocumentRequest>
            )}
        </LinkCard>
    );
}

export default OnDemandDocuments;
