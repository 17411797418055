import tw from 'twin.macro';
import SkeletonLoader from 'tiny-skeleton-loader-react';

const Entry = tw.div`flex flex-col p-4 my-2 rounded shadow border border-brand-light text-left`;
const Description = tw.span`mt-8 flex flex-col w-full`;
const DescriptionLine = tw.div`flex flex-row mb-1`;

function SkeletonTodoEntry() {
    return (
        <Entry>
            <SkeletonLoader height="1.5rem" width="14rem" />
            <Description>
                <DescriptionLine>
                    <SkeletonLoader height="1.25rem" width="100%" />
                    <div tw="w-2" />
                    <SkeletonLoader height="1.25rem" tw="flex-grow" />
                </DescriptionLine>
                <DescriptionLine>
                    <SkeletonLoader height="1.25rem" width="30%" />
                    <div tw="w-2" />
                    <SkeletonLoader height="1.25rem" width="50%" />
                </DescriptionLine>
            </Description>
        </Entry>
    );
}

export default SkeletonTodoEntry;
