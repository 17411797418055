import { useEffect, useState } from 'react';
import tw from 'twin.macro';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import { FormikHelpers, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import FormikInputBox, { InputBox } from '@components/FormikInputBox';
import PasswordStrengthIndicator from '@components/PasswordStrengthIndicator';
import HelperLabel from '@components/HelperLabel';
import Services, { accessTokenService } from '@services/index';
import { Button, ErrorMessage, InstructionMessage, LoginLink, Row } from './StyledComponents';
import LoginFooter from './LoginFooter';
import Logo from './Logo';
import { didUpdatePassword } from '../actions';

const Wrapper = tw.div`flex-grow flex items-center justify-center select-none`;
const FormContainer = tw.form`bg-brand-white p-8 flex flex-col items-center justify-center`;
const InfoInputBox = tw(InputBox)`mb-0`;
const ReturnToLoginLink = tw(Link)`text-brand-muted mx-auto hover:(underline text-brand-primary)`;
const ChangePasswordButton = tw(Button)`w-72 max-w-full`;

const DefaultErrorMessage = 'There was an error changing your password. Please check your old password and try again.';

const initialValues = {
    oldPassword: '',
    newPassword: '',
    verifiedNewPassword: '',
};

const validationSchema = Yup.object({
    oldPassword: Yup.string().min(1).required(),
    newPassword: Yup.string().min(8).required().matches(/[a-z]/).matches(/[A-Z]/).matches(/\d/).matches(/\W/),
    verifiedNewPassword: Yup.string().min(8).required(),
});

type FormData = typeof initialValues;

function PasswordUpdateRequired() {
    const dispatch = useDispatch();
    const [status, setStatus] = useState<'ready' | 'failed' | 'success'>('ready');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        accessTokenService.savePasswordChangeRequired(true, false);
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values: FormData, formikHelpers: FormikHelpers<FormData>) => {
            try {
                formikHelpers.setSubmitting(true);
                setErrorMessage('');
                setStatus('ready');

                if (values.newPassword !== values.verifiedNewPassword) {
                    setErrorMessage('Passwords do not match.');
                    setStatus('failed');
                    return;
                }

                const result = await Services.authApi.authControllerChangePassword({
                    ...values,
                });

                const success = result.status >= 200 && result.status < 300;

                if (!success) {
                    setErrorMessage(DefaultErrorMessage);
                    setStatus('failed');
                } else {
                    accessTokenService.savePasswordChangeRequired(false, false);
                    dispatch(didUpdatePassword());
                }
            } catch {
                setErrorMessage(DefaultErrorMessage);
                setStatus('failed');
            }

            formikHelpers.setSubmitting(false);
        },
    });

    const buttonDisabled =
        formik.isSubmitting || !formik.isValid || formik.values.newPassword !== formik.values.verifiedNewPassword;

    return (
        <Wrapper>
            <Helmet>
                <title>Change Password | Compass by Swell Energy</title>
            </Helmet>
            <FormContainer onSubmit={formik.handleSubmit}>
                <Logo />
                <InstructionMessage>
                    As part of our ongoing efforts to protect your account, we need to change your password to meet
                    enhanced security requirements.
                </InstructionMessage>
                {status === 'failed' && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Row>
                    <FormikInputBox
                        inputBox={InfoInputBox}
                        name="oldPassword"
                        type="password"
                        placeholder="Current Password"
                        hasError={!!formik.touched.oldPassword && !!formik.errors.oldPassword}
                        formik={formik}
                    />
                </Row>
                <Row>
                    <FormikInputBox
                        inputBox={InfoInputBox}
                        name="newPassword"
                        type="password"
                        placeholder="Password"
                        hasError={!!formik.touched.newPassword && !!formik.errors.newPassword}
                        formik={formik}
                    />
                    <PasswordStrengthIndicator currentValue={formik.values.newPassword} />
                </Row>
                <Row>
                    <FormikInputBox
                        inputBox={InfoInputBox}
                        name="verifiedNewPassword"
                        type="password"
                        placeholder="Confirm Password"
                        hasError={
                            !!formik.touched.verifiedNewPassword &&
                            formik.values.newPassword !== formik.values.verifiedNewPassword
                        }
                        formik={formik}
                    />
                    <HelperLabel
                        success={
                            !!formik.values.newPassword &&
                            formik.values.newPassword === formik.values.verifiedNewPassword
                        }
                    >
                        Both passwords must match.
                    </HelperLabel>
                </Row>
                <ChangePasswordButton type="submit" disabled={buttonDisabled}>
                    Submit
                </ChangePasswordButton>
                <LoginLink>
                    <ReturnToLoginLink to="/log-out">Return to Login</ReturnToLoginLink>
                </LoginLink>
                <LoginFooter />
            </FormContainer>
        </Wrapper>
    );
}

export default PasswordUpdateRequired;
