import jwtDecode, { JwtPayload } from 'jwt-decode';

const purgeKey = async (key: string) => {
    window.sessionStorage.removeItem(key);
    window.localStorage.removeItem(key);
};

const cascadeGet = async (key: string) => {
    const local = window.localStorage.getItem(key);
    const session = window.sessionStorage.getItem(key);

    return session || local || '';
};

const clearToken = async () => {
    await purgeKey('access_token');
    await purgeKey('parent_id');
    await purgeKey('context_id');
};

const getToken = () => cascadeGet('access_token');

const isPasswordChangeRequired = async () => {
    return (await cascadeGet('password_chg_required')) === '1';
};

const savePasswordChangeRequired = async (value: boolean, persist: boolean) => {
    const storeValue = value ? '1' : '0';
    window.sessionStorage.setItem('password_chg_required', storeValue);

    if (persist) {
        window.localStorage.setItem('password_chg_required', storeValue);
    }
};

const restore = async () => {
    window.sessionStorage.setItem('access_token', await cascadeGet('access_token'));
    window.sessionStorage.setItem('parent_id', await cascadeGet('parent_id'));
    window.sessionStorage.setItem('context_id', await cascadeGet('context_id'));
    window.sessionStorage.setItem('password_chg_required', await cascadeGet('password_chg_required'));
};

const saveToken = async (token: string, persist: boolean) => {
    window.sessionStorage.setItem('access_token', token);

    if (persist) {
        window.localStorage.setItem('access_token', token);
    }
};

const getContext = () => {
    return {
        parentId: window.sessionStorage.getItem('parent_id'),
        contextId: window.sessionStorage.getItem('context_id'),
    };
};

const saveContext = async (parentId: string, contextId: string, persist: boolean) => {
    window.sessionStorage.setItem('parent_id', parentId);
    window.sessionStorage.setItem('context_id', contextId);

    if (persist) {
        window.localStorage.setItem('parent_id', parentId);
        window.localStorage.setItem('context_id', contextId);
    }
};

const isTokenValid = (): boolean => {
    const storedToken = window.sessionStorage.getItem('access_token');

    if (!storedToken) {
        return false;
    }

    const token = jwtDecode<JwtPayload>(storedToken);

    if (!token.exp) {
        return false;
    }

    const expiry = new Date(token.exp * 1000);
    const valid = expiry > new Date();
    return valid;
};

export {
    clearToken,
    restore,
    getToken,
    saveToken,
    getContext,
    saveContext,
    isTokenValid,
    isPasswordChangeRequired,
    savePasswordChangeRequired,
};
