import tw from 'twin.macro';
import { useSelector } from 'react-redux';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import SkeletonLoader from 'tiny-skeleton-loader-react';
import { demographicsSelector } from '../selectors';

const SkeletonContainer = tw.div`w-full h-72`;
const MapContainer = tw.div`w-full h-full flex items-center`;

function GMap() {
    const demographics = useSelector(demographicsSelector);
    const fullAddress = demographics
        ? `${demographics.address1}, ${demographics.city}, ${demographics.state} ${demographics.zip}`
        : '';

    return (
        <MapContainer>
            {!demographics && (
                <SkeletonContainer>
                    <SkeletonLoader width="100%" height="100%" />
                </SkeletonContainer>
            )}
            {demographics && (
                <StaticGoogleMap
                    size="640x350"
                    apiKey={process.env.REACT_APP_GMAPS_API_KEY}
                    center={fullAddress}
                    maptype="hybrid"
                    zoom={19}
                    width={640}
                    height={350}
                >
                    <Marker location={fullAddress} />
                </StaticGoogleMap>
            )}
        </MapContainer>
    );
}

export default GMap;
