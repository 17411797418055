import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { loadDemographicsAsync, loadStagesAsync } from './actions';

export const loadDemographicsEpic: Epic = (action$, _state$, { demographicsApi }) =>
    action$.pipe(
        filter(isActionOf(loadDemographicsAsync.request)),
        switchMap(() =>
            from(demographicsApi.demographicsControllerGetDemographics()).pipe(
                switchMap(result => of(loadDemographicsAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadDemographicsAsync.failure());
                }),
            ),
        ),
    );

export const loadStagesEpic: Epic = (action$, _state$, { onboardingApi }) =>
    action$.pipe(
        filter(isActionOf(loadStagesAsync.request)),
        switchMap(() =>
            from(onboardingApi.onboardingControllerGetStages()).pipe(
                switchMap(result => of(loadStagesAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadStagesAsync.failure());
                }),
            ),
        ),
    );
