import { combineReducers } from 'redux';
import auth from '@features/Auth/reducer';
import portal from '@features/Portal/reducer';
import profile from '@features/Profile/reducer';
import contacts from '@features/Contacts/reducer';
import documents from '@features/Documents/reducer';
import financials from '@features/Financials/reducer';
import tasks from '@features/Tasks/reducer';
import utility from '@features/Utility/reducer';
import proposals from '@features/Proposals/reducer';
import myAccount from '@features/MyAccount/reducer';

const rootReducer = combineReducers({
    auth,
    portal,
    profile,
    contacts,
    documents,
    financials,
    tasks,
    utility,
    proposals,
    myAccount,
});

export default rootReducer;
