import { useState } from 'react';
import tw from 'twin.macro';
import { ArrowUpCircle, ArrowDownCircle } from 'react-feather';
import Card from '@components/Card';

const Container = tw.div`flex flex-wrap`;
const Title = tw.span`font-heading text-2xl`;
const Subtitle = tw.span`text-lg`;
const TitleContainer = tw.div`flex justify-between`;
const TermsTitle = tw.span`font-heading text-xl text-brand-muted`;
const Terms = tw.p`text-brand-muted text-sm my-4 leading-relaxed`;
const TermsButton = tw.button`hocus:outline-none`;

function ReferAFriend() {
    const [termsVisible, setTermsVisible] = useState(false);

    return (
        <Container>
            <Card size="full" tw="flex flex-col">
                <Title>Refer a Friend for $250</Title>
                <Subtitle>
                    Earn extra cash for every referral who purchases and installs a solar and/or storage system from
                    Swell Energy.
                </Subtitle>
            </Card>
            <Card size="full">
                <TitleContainer>
                    <TermsTitle>Program Terms</TermsTitle>
                    <TermsButton type="button" onClick={() => setTermsVisible(!termsVisible)}>
                        {!termsVisible && <ArrowDownCircle size="1.75rem" tw="text-brand-muted" />}
                        {termsVisible && <ArrowUpCircle size="1.75rem" tw="text-brand-muted" />}
                    </TermsButton>
                </TitleContainer>
                {termsVisible && (
                    <>
                        <Terms>
                            Swell Energy will pay a referral fee for any lead that purchases a solar electric and/or
                            energy storage system from Swell Energy or subscribes to an energy services agreement
                            subject to these terms and conditions.
                        </Terms>
                        <Terms>
                            Referrer must submit lead (including at a minimum lead name, email and telephone number)
                            either online, by phone, or by contacting a local Swell Energy consultant.
                        </Terms>
                        <Terms>
                            Referrer must include at least his or her name, email and telephone number when submitting
                            the lead information to qualify.
                        </Terms>
                        <Terms>
                            Only referrals that result in a completed contract processed by Swell Energy that are also
                            completed and installed by Swell Energy will be eligible for a referral fee.
                        </Terms>
                        <Terms>
                            Referrer does not have to be a previous or existing Swell Energy customer to participate in
                            this referral program.
                        </Terms>
                        <Terms>
                            The referral fee will not be paid if the lead submitted already exists in Swell Energy’s
                            contact database. If Swell Energy receives the lead from multiple referrers, the referral
                            fee will be paid only to the referrer who first submits the lead (indicated by the date of
                            entry into the Swell Energy contact database).
                        </Terms>
                        <Terms>
                            The referral fee will be paid in the form of a check or gift card within 60 days from the
                            date that the referred project has a) been completed and b) payment in full has been
                            received and processed by Swell Energy. Referrers who are eligible for payments from Swell
                            Energy of more than $600 in a calendar year must complete and submit an IRS Form W-9 to
                            Swell Energy before a 3rd referral fee can be paid in any given calendar year.
                        </Terms>
                        <Terms>
                            Swell Energy reserves the right to modify or discontinue this referral program at any time
                            without prior notice.
                        </Terms>
                        <Terms>This referral program is not retroactive. All rights reserved.</Terms>
                    </>
                )}
            </Card>
        </Container>
    );
}

export default ReferAFriend;
