import * as Sentry from '@sentry/react';
import { from, of } from 'rxjs';
import { filter, switchMap, catchError } from 'rxjs/operators';
import { Epic, isActionOf } from 'typesafe-actions';
import { fetchUrlAsync, loadAsync } from './actions';

export const loadEpic: Epic = (action$, _state$, { documentsApi }) =>
    action$.pipe(
        filter(isActionOf(loadAsync.request)),
        switchMap(() =>
            from(documentsApi.documentsControllerGetDocuments()).pipe(
                switchMap(result => of(loadAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(loadAsync.failure());
                }),
            ),
        ),
    );

export const fetchUrl: Epic = (action$, _state$, { documentsApi }) =>
    action$.pipe(
        filter(isActionOf(fetchUrlAsync.request)),
        switchMap(action =>
            from(documentsApi.documentsControllerGetDocumentLink(action.payload.documentId)).pipe(
                switchMap(result => of(fetchUrlAsync.success(result.data))),
                catchError(err => {
                    Sentry.captureException(err);
                    return of(fetchUrlAsync.failure({ ...action.payload }));
                }),
            ),
        ),
    );
