import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { TaskDto } from '@services/api';
import moment from 'moment';
import Moment from 'react-moment';
import tw, { theme } from 'twin.macro';
import AppTheme from '@root/AppTheme';

interface Props {
    event: TaskDto;
}

const UserAvatarContainer = tw.div`w-10 h-10 rounded-full z-10 absolute -right-3 -top-12 bg-brand-white bg-cover bg-center overflow-hidden border-2 border-brand-white`;
const UserAvatar = tw.img`w-full h-full`;
const DueDate = tw.span`inline-block xl:hidden mt-8 text-xs text-brand-muted`;

function CompletedTask({ event }: Props) {
    const icon = (
        <span>
            {moment(event.completedAt).format('MMM D')}
            <br />
            {moment(event.completedAt).format('YYYY')}
        </span>
    );

    return (
        <VerticalTimelineElement
            className="--task-complete"
            contentStyle={{
                border: '1px',
                borderTop: '2rem',
                borderStyle: 'solid',
                borderColor: theme`colors.brand-dark`,
            }}
            contentArrowStyle={{ borderRight: `7px solid  ${theme`colors.brand-dark`}` }}
            icon={icon}
        >
            {event.isHomeownerAssigned && (
                <UserAvatarContainer>
                    <UserAvatar alt="" src={AppTheme.avatar} />
                </UserAvatarContainer>
            )}
            <h3 className="vertical-timeline-element-title">{event.name}</h3>
            <p>{event.description}</p>
            {event.completedAt && (
                <DueDate>
                    Completed on <Moment date={event.completedAt} format="M/D/YYYY" />
                </DueDate>
            )}
        </VerticalTimelineElement>
    );
}

export default CompletedTask;
